 import React, {Component} from "react";
import {Container, Button, Row, Col} from "reactstrap";
import {Link} from 'react-router-dom'
import {registerSuccessContainer} from './registerSuccessContainer'

class RegisterSuccess extends Component {
  _handleRedirect=()=>{
    document.location.href = "/";
  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix text-center">
                <h2 className="pt-3 mb-4">{this.props.message}</h2>
                  <Button onClick={this._handleRedirect} color="primary">Login</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default registerSuccessContainer(RegisterSuccess)

// <h4 className="pt-3">Your email has been successfully verified!</h4> <p className="text-muted
// float-left">Please login from link below. </p>