import React from 'react';
import {Card, CardBody, CardHeader, Row, Col, Button} from 'reactstrap';
import Offer from '../../../assets/images/packages/offer.png'
export default class PromotionDetail extends React.Component {
  state={
    modal:false
  }

  toggle=()=>{
    this.setState({
      modal:!this.state.modal
    })
  }


  render() {
    let promotion = this.props.location.state.data;
    return (
      <Card>
      <CardHeader>
        <div className="d-flex heading">
          <h5 className="">Promotion Detail</h5>
        </div>
      </CardHeader>
      <CardBody className="package">
        <Row>
          <Col sm="4" xs="12">
            <div className="package__img">
              <img src={promotion.image.thumb.url?promotion.image.thumb.url:Offer}className="img-fluid" alt="pp"/>
            </div>
          </Col>
          <Col sm="8" xs="12">
            <div className="package__info">
              <h4>{promotion.promotion_name}</h4>
              <div className="package__description">
              <div dangerouslySetInnerHTML={{__html:promotion.description}} />
              </div>
              <p>
                <div>
                  {promotion.promotion_type==='date_based' &&
                  <span>
                  <p>
                    Start Date and Time:<br/>
                    <strong><span>{promotion.start_on}</span></strong>
                  </p>
                  <p>
                    End Date and Time:<br/>
                    <strong><span>{promotion.end_on}</span></strong>
                  </p></span>}

                  {promotion.promotion_type==="amount_based" &&
                  <span>
                  <p>
                    Maximum Amount:<br/>
                    <strong><span>{promotion.maximum_amount}</span></strong>
                  </p>
                  <p>
                    Maximum Amount:<br/>
                    <strong><span>{promotion.minimum_amount}</span></strong>
                  </p>
                  </span>}

                  <p>
                  Discount:<br/>
                  <strong>{promotion.discount} %</strong>
                  </p>
                </div>
              </p>
              <Button color="default" onClick={()=>{window.history.back()}} >Back</Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
    );
  }
}
