// CREATE_ORDER_SUCCESS
// CREATE_ORDER_FAIL
// SHOW_ORDER_SUCCESS
// SHOW_ORDER_FAIL
// UPDATE_ORDER_SUCCESS
// UPDATE_ORDER_FAIL


export const CREATE_ORDER_SUCCESS  =  "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL     =  "CREATE_ORDER_FAIL"
export const SHOW_ORDER_SUCCESS    =  "SHOW_ORDER_SUCCESS"
export const SHOW_ORDER_FAIL       =  "SHOW_ORDER_FAIL"
export const UPDATE_ORDER_SUCCESS  =  "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL     =  "UPDATE_ORDER_FAIL"
export const LIST_ORDER_SUCCESS    =  "LIST_ORDER_SUCCESS"
export const LIST_ORDER_FAIL       =  "LIST_ORDER_FAIL"
export const ITEMS_ORDER_SUCCESS    =  "ITEMS_ORDER_SUCCESS"
export const ITEMS_ORDER_FAIL       =  "ITEMS_ORDER_FAIL"

