import React from 'react';
import {Card, CardBody, CardHeader, Row, Col} from 'reactstrap';
import SelectAccountModal from '../common/modal'
export default class Package extends React.Component {
  state={
    modal:false
  }

  toggle=()=>{
    this.setState({
      modal:!this.state.modal
    })
  }


  render() {
    let product = this.props.location.state.data;
    let {options} = this.props.location.state
    return (
      <Card>
        <SelectAccountModal
          modal={this.state.modal}
          toggle={this.toggle}
          options={options}
          productName={product.product_name}
          {...this.props}
        />
        <CardHeader>
          <div className="d-flex heading">
            <h5 className="">
              Product Details
              </h5>
          </div>
        </CardHeader>

        <CardBody className="package">
          <div className="package__info">
            <h4>{product.product_name}</h4>
            <p className="package__description">
              <div dangerouslySetInnerHTML={{__html:product.product_description}} />
            </p>
            <p>
              <h5> Price: <strong>N${product.product_price/100.0}</strong></h5>
            </p>
            <hr/>
            <div className="d-flex justify-content-end">
              <div onClick={this.toggle} className="btn btn-primary btn-lg">Buy this Voucher</div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
