import React from 'react';
import {Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Link} from 'react-router-dom';
import {showBillContainer} from './showAccountContainer';
import Tooltips from '../../common/tooltips';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {formatAmount} from '../../common/formater';


class Accounts extends React.Component {
  formatAction=(col, row)=>{
    return (
      <div>
         <Link to={`/account/show/${row.id}`} className="action-icon">
              <Tooltips icon={'icon-eye'} message={'View Detail'} id={`view${row.id}`}></Tooltips>
            </Link>
            <Link to={'#'} onClick={()=>{this.props.deleteConfirm(row.id)}} className="action-icon" >
              <Tooltips icon={'icon-trash'} message={'Delete'} id={`delete${row.id}`}></Tooltips>
            </Link>
      </div>
    )
  }

  formatAmount=(col, row)=>{
    return (
      <strong>N$ {formatAmount(row.available_funds)}</strong>
    )
  }

  formatCreditAmount=(col, row) =>{
    return (
    <strong>N$ {formatAmount(row.available_credit)}</strong>
    )
  }

  render() {
    return (
      <div>
        <div>
        <Modal isOpen={this.props.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Confirm Delete</ModalHeader>
          <ModalBody>
           Are you sure you want to delete this accounts.?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.props.delete}>Delete</Button>
            <Button color="default" onClick={this.props.close}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="">
                Accounts
            </h5>
            <Link to="/account/new" className="ml-auto btn btn-primary">Create Account</Link>
          </div>
        </CardHeader>
        <CardBody>
          <BootstrapTable pagination data={this.props.data}  version='4' bordered={ false }>
              <TableHeaderColumn isKey dataField='account_details'>Account Name</TableHeaderColumn>
              <TableHeaderColumn dataField='account_email'>Email Address</TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatAmount}>Balance</TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatCreditAmount}>Credit</TableHeaderColumn>
              <TableHeaderColumn dataFormat={ this.formatAction}>Actions</TableHeaderColumn>
          </BootstrapTable>
        </CardBody>
      </Card>
      </div>
    );
  }
}

export default showBillContainer(Accounts)
