import React from 'react';
import {Card, CardBody, CardHeader, Button, Col, Row} from 'reactstrap';

import {showOrderContainer} from './showOrderContainer'

class Order extends React.Component {
state ={
  totalAmount:0.00
}

data=()=>{
  this.totalAmount = 0.00;
 let items = Object.keys(this.props.items.data.order.order_items).map(key=>{
      let data = this.props.items.data.order.order_items[key]
       function handleSchedule(data){
         data.sort();
        return  data.map(key=>{
          return <span key={key}>{key}</span>
        })
       }
       this.totalAmount=this.totalAmount+((data.rate/100)*data.voucher_quantity)
      return(
        <div key={key} className="order__items">
          <div className="order__item">
            <Row className="order__item-info">
              <Col xs="12" sm="6" md="2">
                  <strong>{data.delivery_person}</strong>
              </Col>
              <Col xs="12" sm="6" md="2">
                {data.delivery_name==="Email Delivery"?window.localStorage.getItem('emailUsed'):data.delivery_name}
              </Col>
              <Col xs="12" sm="6" md="2">
                {data.voucher_type}
              </Col>
              <Col xs="12" sm="6" md="1">
                {data.voucher_quantity}
              </Col>
              <Col xs="12" sm="6" md="5">
                {data.delivery_type==='Direct Recharge'?
                <div className="order__schedule-date">
                  <div className="order__schedule-dates">
                   {handleSchedule(data.scheduled_dates)}
                  </div>
                </div>:null}
              </Col>
            </Row>
          </div>
        </div>
      )
    })
    return items

  }
  render() {
    let data= this.data()
    return (
      <Card className="orderdetail">
        <CardHeader>
          <div className="d-flex heading">
            <h5 className="">Order Items</h5>
            <div className="ml-auto">
              {this.props.items.data.order.order.order_status!=='draft' && <h5 className="d-inline-block"><strong className="mt-2 mr-2 ">Total Amount:{' '}<span className="text-success">N${this.props.items.data.order.order.order_status==='draft'?this.totalAmount.toFixed(2):(this.props.items.data.order.order.total_amount/100).toFixed(2)}</span></strong></h5>}
              {this.props.items.data.order.order.order_status==="draft"?<Button color="primary" onClick={()=>{this.props.editOrder()}} className="ml-auto">Edit Order</Button>:null}
            </div>

          </div>
        </CardHeader>
        <CardBody>
          <div className="orderdetail__heading">
            <Row>
              <Col xs="2">Name</Col>
              <Col xs="2">Mobile/Email </Col>
              <Col xs="2">Voucher Type</Col>
              <Col xs="1">QTY</Col>
              <Col xs="5">Scheduled date/s:</Col>
            </Row>
          </div>
          {data}
        </CardBody>
      </Card>
    );
  }
}

export default showOrderContainer(Order)