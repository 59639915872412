/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import { SHOW_ORDER_SUCCESS, SHOW_ORDER_FAIL} from '../../action/order';

export function showOrder(id) {
  return dispatch => {
    if(!id){ return dispatch({type:'No action'}) }
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.show_order}/${id}/orders`
    let option={
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
    }
    return fetch(url, option)
    .then(response => response.json().then(createOrder=>({createOrder, response})))
    .then(({createOrder, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      } 
      createOrder.status = response.status
       return Promise.reject(createOrder)
         } else {
         dispatch(receiveShowOrder(createOrder.data))
    }})
    .catch(err => dispatch(showOrderError(err)))
  }
}



function receiveShowOrder(data) {
  return {
    type: SHOW_ORDER_SUCCESS,
    showData:data
  }
}

function showOrderError(err) {
  return {
    type: SHOW_ORDER_FAIL,
    showErrorData:err
  }
}



