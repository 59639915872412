/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
import { UPDATE_MOBILENO_SUCCESS, UPDATE_MOBILENO_FAIL} from '../../action/mobile_no';

export function updateMobileNo(aid, nid, data) {
  return dispatch => {
    if(!aid || !nid ) {return dispatch({type:"no type"})}
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${aid}/numbers/${nid}`
    let option={
      method: 'PUT',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)         
    }
    return fetch(url, option)
    .then(response => response.json().then(createMobileno=>({createMobileno, response})))
    .then(({createMobileno, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      createMobileno.status = response.status
       return Promise.reject(createMobileno)
         } else if(response.status===206) {
            dispatch(toastrActions.add({
              type:'warning',
              message:'Only Name has been updated. Mobile number cannot be updated while there are pending order(s).',
              options:{showCloseButton:true},
              timeOut:5000
            }))
          }     
         else {
          dispatch(toastrActions.add({
            type:'success',
            title:`Update successful.`,
            message:'Recharge number has been updated successfully.',
            options:{showCloseButton:true,removeOnHover:true},
            timeOut:4000
          }))
          dispatch(receiveUpdateMobileno(createMobileno.data))

    }})
    .catch(err => {
      dispatch(updateMobilenoError(err))
      dispatch(toastrActions.add({
        type:'error',
        title:`Update Error`,
        message:err.errors.message,
        options:{showCloseButton:true,removeOnHover:true},
        timeOut:4000
      }))
    })
  }
}



function receiveUpdateMobileno(data) {
  return {
    type: UPDATE_MOBILENO_SUCCESS,
    updateData:data
  }
}

function updateMobilenoError(err) {
  return {
    type: UPDATE_MOBILENO_FAIL,
    updateError:err
  }
}



