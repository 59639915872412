import {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE} from '../../api/auth/api.login';
import {LOGOUT_SUCCESS} from '../../api/auth/api.logout';

// The auth reducer. The starting state sets authentication based on a token
// being in local storage. In a app, we would also want a util to check if the
// token is expired.

export function auth(state = {
  isFetching: false,
  isAuthenticated: localStorage.getItem('access_token')
    ? true
    : false
}, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      })
    case LOGIN_SUCCESS:
    return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        user: action.user
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        hasError: true,
        error: action.err
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      })
    default:
      return state
  }
}
