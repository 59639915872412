
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export function changeUserPassword(state = {
    isInit: true,
    isChange:false
}, action) {
    switch (action.type) {
            case CHANGE_PASSWORD_SUCCESS:
                    return Object.assign({}, state, {
                            isChange: true,
                    })
            default:
                    return state;

    }
}