import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {logoutUser} from '../../../../api/auth/api.logout';
import {connect} from 'react-redux';
import Avatars from './../../../../assets/images/avatars/user.png';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavbarToggler,
  NavbarBrand,
  DropdownToggle
} from 'reactstrap';

class Header extends Component {

  constructor(props) {
    super(props);


    this.toggle = this
      .toggle
      .bind(this);
    this.state = {
      dropdownOpen: false,
      title:'hello'
    };

  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sidebarToggle = () => {
    // e.preventDefault();
    document
      .body
      .classList
      .toggle('sidebar-hidden');
  }

  sidebarMinimize = () => {
    // e.preventDefault();
    document
      .body
      .classList
      .toggle('sidebar-minimized');
  }

  mobileSidebarToggle = () => {
    //e.preventDefault();
    document
      .body
      .classList
      .toggle('sidebar-mobile-show');
  }

  asideToggle = () => {
    //e.preventDefault();
    document
      .body
      .classList
      .toggle('aside-menu-hidden');
  }

  onLogout = () => {
    this.props.logoutUser();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.sync.shouldUpdate){
      this.setState({
        update:true
      })
      this.forceUpdate();
    }
  }

  render() {
    let profileImage = window.localStorage.getItem('avatar_url')=="null" || window.localStorage.getItem('avatar_url')=="undefined"?Avatars:window.localStorage.getItem('avatar_url');
    //eslint-disable-next-line
    //let profileImage=='undefined'?true:false
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>&#9776;</NavbarToggler>
        <NavbarBrand href="/">TimeSlick by Digital Traverse Technologies</NavbarBrand>
        <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>&#9776;</NavbarToggler>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle className="nav-link dropdown-toggle">
                <div className="profile-image">
                  <img src={profileImage} className="" alt="Profile"/>
                </div>
                <span className="d-md-down-none">{`${window.localStorage.getItem('user_first_name')} ${window.localStorage.getItem('user_last_name')} `}</span>
              </DropdownToggle>
              <DropdownMenu
                right
                className={this.state.dropdownOpen
                ? 'show'
                : ''}>
                <DropdownItem>
                  <Link to="/profile_setting" className="nav-link">
                    <i className="icon-user"></i>
                    Profile</Link>
                </DropdownItem>
                <DropdownItem onClick={this.onLogout}>
                  <i className="icon-logout"></i>
                  Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavItem>
        </Nav>
      </header>
    )
  }
}

function mapStateToProps(state) {
  return {
    sync:state.sync
  }
}

export default connect(mapStateToProps, {logoutUser})(Header);
