import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { container } from "./container";
import AddMobileNumber from "./addNumber";
import Header from "./header";
import Switch from "react-toggle-switch";
import EmptyMessageCard from "../IdSelector/emptyMessageCard";

class RechargeNumber extends Component {
  formatStatus = (col, row) => {
    return (
      <StatusSwitch status={row.active} numberID={row.id} {...this.props} />
    );
  };

  handleEdit = data => {
    this.props.handleEdit({
      showForm: true,
      isEditing: true,
      editingData: data
    });
  };

  formatActions = (col, row) => {
    return (
      <div>
        <span
          className="action-icon"
          onClick={() => {
            this.handleEdit(row);
          }}
        >
          <i className="icon-pencil" />
        </span>
        <span
          className="action-icon"
          onClick={() => {
            this.props.handleDelete(row);
          }}
        >
          <i className="icon-trash" />
        </span>
      </div>
    );
  };
  render() {
    return (
      <div>
        {this.props.accounts.length > 0 ? (
          <Card>
            <Header {...this.props} />
            <CardBody>
              {this.props.showForm && <AddMobileNumber {...this.props} />}
              <BootstrapTable
                pagination
                data={this.props.numbers}
                version="4"
                bordered={false}
              >
                <TableHeaderColumn isKey dataField="associated_name" dataSort>
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="mobile_number">
                  Mobile Number
                </TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.formatStatus}>
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.formatActions}>
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
            </CardBody>
          </Card>
        ) : (
          <EmptyMessageCard />
        )}
      </div>
    );
  }
}

export default container(RechargeNumber);

class StatusSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status
    };
  }

  updateStatus = status => {
    let data = { number: { active: status } };
    this.setState({ status: status });
    this.props.updateMobileNo(this.props.accountID, this.props.numberID, data);
  };
  render() {
    return (
      <span className="react-switch">
        <Switch
          onClick={() => {
            this.updateStatus(!this.state.status);
          }}
          on={this.state.status}
        />
      </span>
    );
  }
}
