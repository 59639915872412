import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

/**
 * @description import all the component from component/page
 * all the component are open route.
 */
import Dashboard from "../components/pages/dashboard";
import Login from "../components/pages/login";
import Register from "../components/pages/register";
import RegisterSuccess from "../components/pages/register_success";
import Resetpassword from "../components/pages/resetpassword";
import Forgotpassword from "../components/pages/forgotpassword";
import Styleguide from "../components/pages/styleguide";
import Page404 from "../components/pages/page404/index";
import Page500 from "../components/pages/page500/index";
import Terms from "../components/pages/terms/index";

const LandingPage = () => window.location.replace("/home/index.html");
/**
 * @description App component does not do anything fancy. It just render the open
 * route using Route component from
 */
class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <Route exact path="/" component={LandingPage} />
            <MatchWhenAuthorized path="/dashboard" component={Dashboard} />
            <Route path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route
              path="/confirmation/confirm_user/:id"
              component={RegisterSuccess}
            />
            <Route
              path="/confirmation/confirm_email/:id"
              component={RegisterSuccess}
            />
            <Route path="/forgotpassword" component={Forgotpassword} />
            <Route exact path="/styleguide" component={Styleguide} />
            <Route
              exact
              path="/forgot_password/request/:id"
              component={Resetpassword}
            />
            <Route exact path="/404" component={Page404} />
            <Route exact path="/500" component={Page500} />
            <Route exact path="/terms" component={Terms} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

/**
 *
 * @param {Route}
 * @description take route as param and either provide access to the route
 * or redirect to login base on token check in local storage.
 */
const MatchWhenAuthorized = ({ component: Component, ...rest }) => (
  /**
   * @param Route is rendered here to render dashboard component.
   */
  <Route
    {...rest}
    render={renderProps =>
      isAuthenticated() ? (
        <Component {...renderProps} />
      ) : (
        /**
         * @description Redirect location can be specified here.
         */
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: renderProps.location
            }
          }}
        />
      )
    }
  />
);
MatchWhenAuthorized.propTypes = {
  component: PropTypes.any
};

/**
 * @argument null
 * @description check local-storage for token. if token is present the app log into else
 * it redirect into login location.
 */
function isAuthenticated() {
  return localStorage.getItem("access_token") ? true : false;
}

export default App;
