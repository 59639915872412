import React, { Component } from 'react';
import {CardBody, CardBlock} from 'reactstrap'

class EmptyItemsCard extends Component {
  render() {
    return (
      <div>
             <CardBody className="text-center">
              <CardBlock className="card-body">
                <blockquote className="card-bodyquote">
                  <p>There is no data to display.</p>
                </blockquote>
              </CardBlock>
            </CardBody>
      </div>
    );
  }
}

export default EmptyItemsCard;