/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import moment from "moment";
import Select from "react-select";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { actions as toastrActions } from "react-redux-toastr";
import { container } from "./container";
import ModalMail from "./mail";
import EmptyMessageCard from "../IdSelector/emptyMessageCard";
import SearchModules from "./modules/searchModules";
import { connect } from "react-redux";

import { Get, Post } from "../../../constant/thunks";
import { generateResources } from "../../../constant/resources";

const add = toastrActions.add;

export function formatAmount(data) {
  if (data < 0) {
    let newData = Math.abs(data);
    return `(${(newData / 100.0).toFixed(2)})`;
  }
  return (data / 100.0).toFixed(2);
}

export function formatDescription(data) {
  if (data < 0) {
    return "Amount Due";
  }
  return "Your Balance";
}

class Statement extends React.Component {
  constructor() {
    super();
    this.state = {
      result: [],
      startDate: moment().format("YYYY/MM/DD"),
      endDate: moment().format("YYYY/MM/DD")
    };
  }

  // handleChange = event => {
  //   this.setState({
  //     invoiceNumber: event.target.value
  //   });
  // };

  updateStartDate = date => {
    this.setState({ startDate: date.format("YYYY/MM/DD") });
  };
  updateEndDate = date => {
    this.setState({ startEnd: date.format("YYYY/MM/DD") });
  };

  updateResult = result => {
    this.setState({ result });
  };

  searchQuery = async () => {
    const url = `accounts/${this.props.accountId}/manual_statement`;
    const query = `?from=${this.state.startDate}
    }&to=${this.state.endDate}`;
    const result = await this.props.Get(
      generateResources("StatementQuery", url, undefined, query)
    );

    this.updateResult(result.data.data);
    return result;
  };

  handleSendEmail = async () => {
    const url = `accounts/${this.props.accountId}/manual_statement/send_email`;
    const body = {
      statement: {
        from_date: this.state.startDate,
        to_date: this.state.endDate
      }
    };
    const resources = generateResources("StatementEmail", url, body);
    const result = await this.props.Post(resources);
    if (result.status === 200) {
      this.props.add({
        type: "success",
        message: "Email has been sent to associated account.",
        title: "Email Sent",
        options: { showCloseButton: true, removeOnHover: true },
        timeOut: 4000
      });
    } else {
      this.props.add({
        type: "error",
        title: `Error ${result.status}`,
        message: "Server are having issue. Try again",
        options: { showCloseButton: true, removeOnHover: true },
        timeOut: 4000
      });
    }
    return result;
  };

  // mail = e => {
  //   if (e.id) {
  //     this.props.getApi({
  //       url: `statements/${e.id}/send_email`,
  //       method: "post",
  //       body: {
  //         statement: {
  //           email: this.state.email,
  //           body: this.state.body,
  //           subject: this.state.subject
  //         }
  //       },
  //       successActionName: "POST_CREDIT_EMAIL_SUCCESS",
  //       errorActionName: "POST_CREDIT_EMAIL_SUCCESS",
  //       showErrorMessage: true,
  //       errorMessage: {
  //         type: "error",
  //         title: "Could not send email."
  //       },
  //       showSuccessMessage: true,
  //       successMessage: {
  //         type: "success",
  //         title: "Statement has been send."
  //       }
  //     });
  //   }
  // };

  // handleCreateCreditNote = event => {
  //   event.preventDefault();
  //   this.props.history.push(`/creditnote/${this.state.invoiceNumber}`);
  // };

  // viewFormat = (a, b) => (
  //   <span>
  //     <a
  //       href={b.pdf_url}
  //       target="_blank"
  //       className="action-icon"
  //       title="Download pdf"
  //     >
  //       <i className="fa fa-file-pdf-o" />{" "}
  //     </a>
  //     <a
  //       onClick={() => {
  //         this.mail(b);
  //       }}
  //       className="action-icon"
  //       title="Mail"
  //       role="button"
  //     >
  //       <i className="fa fa-envelope-o" />{" "}
  //     </a>
  //   </span>
  // );

  entity = () => {
    const list = Object.keys(this.props.id).map(key => {
      const data = this.props.id[key];
      return { value: data.id, label: data.account_details };
    });
    return list;
  };

  render() {
    const list = this.entity();
    const { result, startDate, endDate, modal } = this.state;
    const selected = {
      value: this.props.accountId,
      label: this.props.accountName
    };
    return (
      <div>
        {this.props.id.length < 1 ? (
          <EmptyMessageCard />
        ) : (
          <Card className="creditnote-items">
            <CardHeader>
              <div className="d-flex heading">
                <h5 className="">Search Statements for</h5>
                <Select
                  className="heading__entity"
                  name="entity"
                  value={selected}
                  onChange={this.props.onchange}
                  options={list}
                  clearable={false}
                  searchable={false}
                />
                <SearchModules
                  accountId={this.props.accountId}
                  updateResult={this.updateResult}
                  updateStartDate={this.updateStartDate}
                  updateEndDate={this.updateEndDate}
                  searchQuery={this.searchQuery}
                />
                <button
                  className="btn btn-outline-primary  ml-2"
                  disabled={result.hasOwnProperty("transaction") ? false : true}
                  onClick={this.handleSendEmail}
                >
                  Send Email
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <div className="c-statement">
                <ModalMail
                  modal={modal}
                  manual
                  accountNumber={this.props.accountNumber}
                  from={this.props.getStartDate}
                  to={this.props.getEndDate}
                  toggle={this.props.toggleManualStatementEmail}
                />
                {result.hasOwnProperty("transaction") ? (
                  <div className="c-statement__list">
                    <Table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Description</th>
                          <th>Amount (N$)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-success">
                          <td>{startDate}</td>
                          <td>{formatDescription(result.opening_balance)}</td>
                          <td>{formatAmount(result.opening_balance)}</td>
                        </tr>

                        {result.transaction.map(item => (
                          <tr key={item.id}>
                            <td>{item.transaction_on}</td>
                            <td>{item.description}</td>
                            <td>
                              {item.debit && "("}
                              {(item.amount / 100).toFixed(2)}
                              {item.debit && ")"}
                            </td>
                          </tr>
                        ))}

                        <tr className="bg-success">
                          <td>{endDate}</td>
                          <td>{formatDescription(result.closing_balance)}</td>
                          <td>{formatAmount(result.closing_balance)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="react-bs-table-no-data text-center">
                      There is no data to display. Please search from above.
                    </span>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default container(
  connect(
    null,
    { Get, Post, add }
  )(Statement)
);
