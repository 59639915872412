/**
 *@description refer src/api/api.js. same as that 
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE'




export function createAccount(data) {
  return dispatch => {
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}`
    return fetch(url, { 
      method: 'POST',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('access_token')
              },
      body:JSON.stringify(data) })
       .then(response => response.json().then(bill=>({bill, response})))
       .then(({bill, response})=>{
         if(!response.ok){
          if(response.status===401){
            window.localStorage.clear();
            window.location.reload();
          }
           return Promise.reject(bill)
         } else {
           dispatch(receiveCreateBilling(bill));   
         }})
       .catch(err => {
         dispatch(toastrActions.add({
          id:'fkjdskfsd',
          title:'Creation of billing address unsuccessful',
          type:'error',
          message:err.errors.message,
          options:{showCloseButton:true, removeOnHover:true}
        }))
        
        })
      }
    }  

function receiveCreateBilling(data) {
  return {
    type: CREATE_ACCOUNT_SUCCESS,
    data
  }
}




