import {TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE} from '../../api/auth/api.auth';

export function apiToken(state = {
  isFetching: false,
  hasApiToken: localStorage.getItem('apiToken') !== 'nothing'
    ? true
    : false
}, action) {
  switch (action.type) {
    case TOKEN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        hasToken: false
      })
    case TOKEN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        hasToken: true
      })
    case TOKEN_FAILURE:
      return Object.assign({}, state, {
        hasError: true,
        errorMessage: action.message
      })

    default:
      return state
  }
}
