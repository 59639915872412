export function generateResources(
  actionName,
  url,
  body = undefined,
  query = undefined,
  syncActionsName = undefined
) {
  return {
    url: query ? `${url}${query}` : url,
    body,
    asyncActions: {
      success: `${actionName}_SUCCESS`,
      error: `${actionName}_ERROR`
    },
    syncActionsName
  };
}

export function combineGeneratior(resources, fn) {
  const temp = {};
  resources.map(item => {
    temp[`${item}Reducer`] = fn(item);
  });
  return temp;
}
