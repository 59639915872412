/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_FAIL } from '../../action/bill';
export function deleteBill(id) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}`
    let option={
      method: 'DELETE',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
    }
    return fetch(url, option)
    .then(response => response.json().then(Deletebill=>({Deletebill, response})))
    .then(({Deletebill, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      Deletebill.status = response.status
       return Promise.reject(Deletebill)
         } else {
           //eslint-disable-next-line
          if (id == window.localStorage.getItem('aid')){ 
            window.localStorage.removeItem('aid')
            window.localStorage.removeItem('accountName')
            
           }
         dispatch(receiveDeleteBill(Deletebill))
    }})
    .catch(err => dispatch(billDeleteError(err)))

   }

  }

function receiveDeleteBill(data) {
  return {
    type:DELETE_ACCOUNT_SUCCESS,
    deleteData:data
  }
}

function billDeleteError(err) {
  return {
    type: DELETE_ACCOUNT_FAIL,
    deleteError:err
  }
}
