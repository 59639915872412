import React from "react";
import { reOrderContainer } from "./newOrderContainer";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Form,
  Col,
  Label,
  Row,
  FormGroup
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import ScheduleModal from "./popupScheduleModal";
import Discount from "./discount";
import Mobile from "../addMobileNumber/index";
import { formatAmount } from "../../common/formater.js";

class ReOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      index: "",
      edit: false,
      delivery_type: "Direct Recharge",
      delivery_name: "",
      delivery_person: "",
      voucher_type: "",
      voucher_quantity: "1",
      expire_data: "",
      date: moment(),
      email: "",
      modal: "",
      lastDate: ""
    };
  }

  toggle = () => {
    this.setState({
      modal: false
    });
  };

  togglePopover = flag => {
    this.setState({
      popoverOpen: flag
    });
  };

  selectCurrentNumber = data => {
    // let shouldUpdateProps = true;
    // this.props.numberList.map(key=>{
    //   if(key.value == data.value || key.label== data.label || data.value.substring(1, 3) !== '081' )
    //   { shouldUpdateProps=false } })
    // if(shouldUpdateProps){
    //   this.props.numberList[this.props.numberList.length] = data
    //   this.setState({
    //     delivery_name:data.value
    //   })
    // }
  };

  componentWillMount() {
    this.setState({
      email: this.props.accountEmail,
      voucher_type: this.props.product[0].value
    });
  }

  componentDidMount() {
    this.props.calculateProductPrice();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleProductChange = selectedOption => {
    this.setState({ voucher_type: selectedOption.value });
  };

  handleMobilenoChange = selectedOption => {
    this.setState({
      delivery_name: selectedOption.value,
      lastDate: selectedOption.date,
      delivery_person: selectedOption.delivery_person
    });
  };

  deleveryTypeChange = selectedOption => {
    this.setState({ delivery_type: selectedOption.value });

    if (selectedOption.value === "Email Delivery") {
      this.setState({ delivery_name: this.state.email });
    }
  };

  submit = () => {
    if (this.state.delivery_type === "Email Delivery") {
      this.props.addOrder({
        delivery_type: this.state.delivery_type,
        delivery_name: this.props.accountEmail,
        voucher_type: this.state.voucher_type,
        voucher_quantity: this.state.voucher_quantity,
        expire_date: this.state.last_data,
        last_date: moment().add(this.state.voucher_quantity * 7, "days")
      });
    } else {
      this.setState({ modal: true });
    }
  };

  onDirectSubmit = data => {
    this.setState({ modal: false });
    this.props.addOrder({
      delivery_type: this.state.delivery_type,
      delivery_name: this.state.delivery_name,
      delivery_person: this.state.delivery_person,
      voucher_type: this.state.voucher_type,
      voucher_quantity: this.state.voucher_quantity,
      expire_date: this.state.last_data,
      last_date: moment().add(this.state.voucher_quantity * 7, "days"),
      scheduled_dates: data
    });
  };

  onDirectSubmitEdit = data => {
    this.setState({ modal: false, edit: false });
    this.props.editOrder(
      {
        orderItems: {
          delivery_type: this.state.delivery_type,
          delivery_name: this.state.delivery_name,
          delivery_person: this.state.delivery_person,
          voucher_type: this.state.voucher_type,
          voucher_quantity: this.state.voucher_quantity,
          expire_date: this.state.last_data,
          last_date: moment().add(this.state.voucher_quantity * 7, "days"),
          scheduled_dates: data
        }
      },
      this.state.index
    );
  };

  submitEdit = () => {
    if (this.state.delivery_type === "Email Delivery") {
      this.props.editOrder(
        {
          orderItems: {
            delivery_type: this.state.delivery_type,
            delivery_name: this.props.accountEmail,
            voucher_type: this.state.voucher_type,
            voucher_quantity: this.state.voucher_quantity,
            expire_date: this.state.last_data,
            last_date: moment().add(this.state.voucher_quantity * 7, "days")
          }
        },
        this.state.index
      );

      this.setState({
        index: "",
        edit: false,
        delivery_type: "Direct Recharge",
        delivery_name: "",
        delivery_person: "",
        voucher_type: "",
        voucher_quantity: 1,
        expire_date: "",
        date: moment()
      });
    } else {
      this.setState({ modal: true });
    }
  };

  handleDate = date => {
    this.setState({ date });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.state.edit ? this.submitEdit() : this.submit(); // eslint-disable-line
  };

  handleEdit = (data, index) => {
    let number = this.props.numberList.filter(
      item => item.value === data.delivery_name
    );
    number = number[0];
    this.setState({
      index,
      delivery_type: data.delivery_type,
      delivery_name: data.delivery_name,
      delivery_person: data.delivery_person,
      voucher_type: data.voucher_type,
      voucher_quantity: data.voucher_quantity,
      expire_date: data.expire_date,
      lastDate: number.date != "undefined" ? number.date : false, // eslint-disable-line
      edit: true
    });
  };

  handleBack = e => {
    e.preventDefault();
    window.history.back();
    // this.props.history.goBack()
  };

  orderList = () => {
    const orderList = this.props.orderList.map((key, index) => {
      console.log(key);
      function handleSchedule(data) {
        return Object.keys(data).map(key => {
          const date = data[key];
          return <span>{date}</span>;
        });
      }
      return (
        <div className="order__item">
          <Row className="order__item-info">
            <Col xs="12" sm="6" md="2">
              <strong>{key.delivery_person}</strong>
            </Col>
            <Col xs="12" sm="6" md="2">
              {key.delivery_name}
            </Col>
            <Col xs="12" sm="6" md="2">
              {key.voucher_type}
            </Col>
            <Col xs="12" sm="6" md="1">
              {key.voucher_quantity}
            </Col>
            <Col xs="12" sm="6" md="4">
              {key.delivery_type === "Direct Recharge" &&
              !!key.scheduled_dates ? (
                <div className="order__schedule-date">
                  <div className="order__schedule-dates">
                    {handleSchedule(key.scheduled_dates)}
                  </div>
                </div>
              ) : null}
            </Col>
            <Col xs="12" sm="6" md="1" className="pr-0">
              <span className="action-icon">
                <i
                  onClick={() => {
                    this.handleEdit(key, index);
                  }}
                  role="button"
                  className="icon-pencil"
                />{" "}
              </span>
              <Link to={"#"} className="action-icon" title="Delete">
                <i
                  onClick={() => {
                    this.props.handleDeleteOrderItems(index);
                  }}
                  role="Button"
                  className="icon-trash"
                />{" "}
              </Link>
            </Col>
          </Row>
        </div>
      );
    });
    return orderList;
  };

  render() {
    const orderList = this.orderList();
    const numberList = (
      <Select
        required
        name="delivery_name"
        value={this.state.delivery_name}
        onChange={this.handleMobilenoChange}
        options={this.props.numberList}
        clearable={false}
        searchable={false}
      />
    );
    const email = (
      <Input type="text" disabled Selected value={this.state.email} />
    );
    return (
      <Card>
        <CardHeader>
          <div className="d-flex heading">
            <h5 className="">Reorder Order</h5>
            <h5 className="order__avilable-fund ml-auto">
              Available Credit:
              <strong className="text-success mr-2">
                {" "}
                N$ {((this.props.credit * 1.0) / 100).toFixed(2)}
              </strong>
              Available Funds:
              <strong className="text-success">
                {" "}
                N$ {formatAmount(this.props.amount)}
              </strong>
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <div>
            <Form>
              <form onSubmit={this.handleSubmit}>
                <Row className="order__new-form">
                  <Col xs="12" sm="6" md="2">
                    <FormGroup>
                      <Label htmlFor="deliverytype">Delivery Type</Label>
                      <Select
                        required
                        name="delivery_type"
                        value={this.state.delivery_type}
                        onChange={this.deleveryTypeChange}
                        options={[
                          {
                            value: "Direct Recharge",
                            label: "Direct Recharge"
                          },
                          { value: "Email Delivery", label: "Email Delivery" }
                        ]}
                        clearable={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6" sm="6" md="3">
                    <FormGroup>
                      <Label htmlFor="delivery_name">Name/Email</Label>
                      {this.state.delivery_type === "Direct Recharge"
                        ? numberList
                        : email}
                      {this.state.delivery_type === "Direct Recharge" ? (
                        <small
                          role="Button"
                          href={""}
                          id="Popover2"
                          onClick={this.togglePopover}
                          className="mobileformat_msg"
                        >
                          + Add new name and number
                        </small>
                      ) : (
                        <small>&nbsp;</small>
                      )}
                      <Mobile
                        toggle={this.togglePopover}
                        isOpen={this.state.popoverOpen}
                        selectCurrentNumber={this.selectCurrentNumber}
                        {...this.props}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="2">
                    <FormGroup>
                      <Label htmlFor="voucher_type">Voucher Type</Label>
                      <Select
                        required
                        name="voucher_type"
                        value={this.state.voucher_type}
                        onChange={this.handleProductChange}
                        options={this.props.product}
                        clearable={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="2">
                    <FormGroup>
                      <Label htmlFor="qty">Qty</Label>
                      <Input
                        type="number"
                        required
                        name="voucher_quantity"
                        min="1"
                        max={
                          this.state.delivery_type === "Email Delivery"
                            ? "999"
                            : "50"
                        }
                        id={this.props.id}
                        onChange={this.handleChange}
                        value={
                          this.state.edit ? this.state.voucher_quantity : null
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="3">
                    <FormGroup>
                      <div className="order__action-btn">
                        <Button type="submit" color="primary" className="mr-2">
                          {this.state.edit
                            ? "Update Item"
                            : "Add to order list"}
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </Form>
          </div>
          <div className="orderdetail__heading">
            <Row>
              <Col xs="2">Name</Col>
              <Col xs="2">Mobile/Email </Col>
              <Col xs="2">Voucher Type</Col>
              <Col xs="1">QTY</Col>
              <Col xs="5">Scheduled date/s:</Col>
              <Col xs="1" />
            </Row>
          </div>
          <div className="order__items">{orderList}</div>
          <div className="clearfix order__bottom">
            {this.props.totalPrice !== 0 ? (
              <div className="order__amount-charge clearfix">
                <small className="pull-left text-muted">
                  System has auto populate scheduled date on the basic of record
                  on our database.
                  <br /> Make necessary change using edit button on right side
                  of each order.
                </small>

                <div className="pull-right">
                  <Discount
                    totalAmount={this.props.totalPrice}
                    getDiscountId={this.props.getDiscountId}
                  />
                </div>
              </div>
            ) : null}

            <Button
              color="success"
              className="mr-2"
              onClick={this.props.saveDraft}
            >
              Save Order
            </Button>
            <div className="pull-right">
              <Button
                color="default"
                className="mr-2"
                onClick={this.handleBack}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="btn btn-primary"
                onClick={this.props.processOrder}
              >
                Process Order
              </Button>
            </div>
          </div>
        </CardBody>
        <ScheduleModal
          modal={this.state.modal}
          date={this.state.lastDate}
          toggle={this.toggle}
          voucher_quantity={this.state.voucher_quantity}
          voucher_type={this.state.voucher_type}
          submit={
            this.state.edit ? this.onDirectSubmitEdit : this.onDirectSubmit
          }
        />
      </Card>
    );
  }
}

export default reOrderContainer(ReOrder);
