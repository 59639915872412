import React, {Component} from 'react';
import {connect} from 'react-redux'
import {loginUser} from  '../../../api/auth/api.login';
import { bindActionCreators } from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'


/**
 * @description HOC Container
 */

export function loginContainer(WarpperComponent){

  class LoginContainer extends Component {
    constructor(props){
      super(props)
      this.state={
         email:'',
         password:'',
         error:false,
         visible: false,
         disable:false,
         errorMessage:{
           email:null,
         }

      }
      this.onDismiss = this.onDismiss.bind(this);

    }

    /**
     * @description execute when login button is clicked.
     */
		handleSubmit=(e)=>{
      this.setState({disable:true})
			e.preventDefault();
      let user= {user:{email:this.state.email, password:this.state.password}}
      this.props.loginUser(user);
    }

    

    validation=(data)=>{
      if(data==='email' && this.state.email.length<1){
        let error = this.state.errorMessage.email
        this.setState({[error]:'Email field cannot be empty'})
      }
    }

    onDismiss=()=>{
      this.setState({ visible: false });
    }

    /**
     * @param e event from form change event.
     */
    handleChange=(e)=>{
      //this.validation(e.target.email)
      e.preventDefault();
     this.setState({[e.target.name]:e.target.value});
    }


    /**
     * @description check weather to load login route or redirect to dashboard
     * if user us already login.
     */
    componentDidMount(){
    if(this.props.auth.isAuthenticated){
        let {history} = this.props;
        history.push({pathname:'/dashboard', state:{load:true}})
      }

    }

    /**
     * 
     * @param nextProps.auth flag set token and redirect in successful login. 
     */
    componentWillReceiveProps(nextProps) {
      if(typeof nextProps.auth!==undefined && nextProps.auth.isAuthenticated===true){
        this.setState({disable:false})
        let {history} = this.props;
        history.push('/dashboard', {LoginMessage:true})
      }

      /**
       * @description show login error in toaster flash.
       */

      if(this.state.disable===true && typeof nextProps.auth.hasError!==undefined && nextProps.auth.hasError === true){
          this.setState({disable:false})
            let error=nextProps.auth.error.errors.message
          this.props.add({id:'LoginError', preventDuplicates:true, title:'Login was unsuccessful.', message:error, type:'error', timeOut:3000, position:'top-center', options: {
            showCloseButton:true
        }})
      }
    }
  /**
   *@description render a view with container logic.
   */ 
	render() {
    return (
      <div>
						<WarpperComponent {...this.state}
                 onFormSubmit={this.handleSubmit}
                 onFormChange={this.handleChange}
                 dismiss={this.onDismiss}
                 auth={this.props.auth}
            />
				</div>
			);
	}
}

/**
 * @ React router dispatch to props matcher.
 */

function mapDispatchToProps(dispatch) {
  let add = toastrActions.add;
  return bindActionCreators({
    loginUser:loginUser,
    add:add
  }, dispatch)
}
/**
 * 
 * @param {*} state state from react router
 */
function mapStateToProps(state){
  return{
    auth:state.auth
  }
}
return connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

}
