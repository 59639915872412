import React from 'react';
import {connect} from 'react-redux';
import {indexProducts} from '../../../api/product/api.index.product';
import {fetchAccounts} from '../../../api/account_service/api.show.accounts';
import {getApi} from '../../../api/api'

import Loader from '../loading/loader';

export function indexProductsContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isProductloading: true,
        productList: null,
        billingList:'',
        isAccountloading:true,
        productListByGroup:null,
        isOfferLoading:true,
        offerAndPromotion:[],
        getProduct:false
      }
    }

    componentWillMount() {
      this.getApi()
      this.getBanner()
      this.props.fetchAccounts()
    }

    getApi=()=>{
      this.setState({getProduct:true})
      this.props.getApi({
        url:`dashboards/product_groups`,
        method:'GET',
        successActionName:"GET_PRODUCT_BY_GROUP_SUCCESS",
        errorActionName:"GET_PRODUCT_BY_GROUP_FAIL",
        showErrorMessage:false,
        showSuccessMessage:false
      })
    }  
    
    getBanner=()=>{
      this.props.getApi({
        url:'banners',
        method:'get',
        successActionName:'FETCH_BANNERS_SUCCESS',
        errorActionName:'FETCH_BANNERS_FAIL',
        showSuccessMessage:false,
        showErrorMessage:false,
      })
    }


  componentWillReceiveProps(nextProps){
   if(nextProps.products.fetchByGroupSuccess===true) {  
    let { product } = nextProps.products.data.data
      this.setState({ 
        isProductloading: false, 
        getProduct:false,
        productListByGroup:product
      });
   }

   if (nextProps.account.isSuccess) {
    let {data} = nextProps.account
    let list = data.map(key=>{
      return {value:key.id, label:key.account_details}
    })
    this.setState({ 
      billingList:list,
      isAccountloading:false
     });
   }

   if(nextProps.promotions.fetchBannerSuccess){
    let {banner} = nextProps.promotions.data.data
    this.setState({
      offerAndPromotion:banner,
      isOfferLoading:false
    }) 
  }
  }

  render() {
    return (
      <div>
      { !this.state.isProductloading && !this.state.isOfferLoading && !this.state.isAccountloading  ?
        <Component data={this.state.productListByGroup}
        offerAndPromotion={this.state.offerAndPromotion} history={this.props.history}
         package={this.package} billingList={this.state.billingList}/>
        : <div className="loader"><Loader height={80} width={80}/></div> }
      </div>
    );
  }
}

 function mapStateToProps(state){
  return {
    products:state.indexProducts,
    account:state.showAccount,
    promotions:state.promotions
  }
}

return connect(mapStateToProps,{indexProducts, getApi, fetchAccounts})(Container)
}
