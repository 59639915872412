import {RESET_SUCCESS, RESET_FAILURE} from '../../api/auth/api.resetPassword';


export function resetPassword(state = {}, action) {
	switch (action.type) {
		case RESET_SUCCESS:
		return Object.assign({}, state, {
			isRequested: false,
			isReset: true,
			hasError: false,
			data: action.res
		})

		case RESET_FAILURE:
		return Object.assign({}, state, {
			isRequested: false,
			isReset: false,
			hasError: true,
			errorMessage: action.err
		})

		default:
		return state;

	}
}
