/**
 *@description refer src/api/api.js. same as that 
 */
import api from "../../config/api.js";
export const TOKEN_REQUEST = 'TOKEN_REQUEST'
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS'
export const TOKEN_FAILURE = 'TOKEN_FAILURE'

export function apiToken() {
  return dispatch => {
    let url = `${api.apiUrl}${api.version}${api.resource.ApiToken}`
 
    dispatch(requestToken())
    return fetch(url, {
      method: 'post',
      Content:'text/plain',
      body: {"application":{"application_name":"front-end", "password":"xS3g7j8EYefUE8wk"}}}) 
     .then(response => response.json().then(user => ({ user, response })))
     .then(({ user, response }) =>  {
        if (!response.ok) {
              dispatch(tokenError(user.message))
              return Promise.reject(user)
              } else {
 
              localStorage.setItem('apiToken', user.token)
              dispatch(receiveToken())
            }
          }).catch(err => console.log("Error: ", err))
      }
    }

function requestToken(){
  return {
    type: TOKEN_REQUEST
  }
}

function receiveToken() {
  return {
    type: TOKEN_SUCCESS
  }
}

function tokenError(message) {
  return {
    type: TOKEN_FAILURE,
    message
  }
}
