/* eslint-disable react/no-multi-comp */
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import { connect } from "react-redux";
import { getApi } from "../../../api/api";
class ModalMail extends React.Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className="modal_email"
        >
          <ModalHeader toggle={this.props.toggle}>New Mail</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="email_to">To:</Label>
                <Input
                  onChange={this.props.handleChange}
                  type="text"
                  name="email"
                  className="modal_email__to"
                />
              </FormGroup>
              <FormGroup className="">
                <Label for="email_subject">Subject:</Label>
                <Input
                  onChange={this.props.handleChange}
                  type="text"
                  name="subject"
                  className="modal_email__subject"
                />
              </FormGroup>
              <FormGroup className="">
                <Input
                  onChange={this.props.handleChange}
                  type="textarea"
                  name="body"
                  className="modal_email__content"
                  placeholder="Say Something"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <span className="modal_email__filename">
              Attached Invoice:
              <span>
                <i className="fa fa-file-pdf-o mr-1" />
                invoice_0203847.pdf
              </span>
            </span>
            <Button color="default" onClick={this.props.toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.props.handleSubmit}>
              Send
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default mailContainer(ModalMail);

export function mailContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
        body: "",
        subject: "",
        creditNoId: ""
      };
    }

    componentsDidMount() {
      this.setState({
        creditNoId: this.props.id
      });
    }

    handleChange = ({ target }) => {
      this.setState({
        [target.name]: target.value
      });
    };

    handleSubmit = e => {
      e.preventDefault();
      this.props.getApi(
        {
          url: `statements/${this.props.id}/send_email`,
          method: "post",
          body: {
            statement: {
              email: this.state.email,
              body: this.state.body,
              subject: this.state.subject
            }
          },
          successActionName: "POST_CREDIT_EMAIL_SUCCESS",
          errorActionName: "POST_CREDIT_EMAIL_SUCCESS",
          showErrorMessage: true,
          errorMessage: {
            type: "error",
            title: "Could not send email."
          },
          showSuccessMessage: true,
          successMessage: {
            type: "success",
            title: "Statement has been send."
          }
        },
        this.props.toggle()
      );
    };

    render() {
      return (
        <Component
          {...this.props}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {};
  }

  return connect(
    mapStateToProps,
    { getApi }
  )(Container);
}
