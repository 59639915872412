/**
 *@description refer src/api/api.js. same as that 
 */
import api from "../../config/api.js";
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAILURE = 'RESET_FAILURE'



export function resetPassword(data){
  let tokenurl = `${api.apiUrl}${api.version}${api.resource.apiToken}`
  let url = `${api.apiUrl}${api.version}/forgot_password/password_reset`
  return dispatch => {
  return fetch(tokenurl, {
      method: 'POST',
      Content:'text/plain',
      body: JSON.stringify({"application":{"application_name":"front-end", "password":"xS3g7j8EYefUE8wk"}})})
     .then(res => res.json())
     .then((res)=> {
            let option={
            method: 'POST',
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+res.data.token},
           body:JSON.stringify(data) }
        fetch(url, option)
        .then(response => response.json().then(user => ({ user, response })))
        .then(({ user, response }) =>  {
          if (!response.ok) {
          user.status=response.status;
          return Promise.reject(user)
            } else {
           dispatch(resetPasswordConfirm(user))
          }
        }).catch(err => dispatch(resetPasswordError(err)))
      })
     .catch(err => dispatch(resetPasswordError(err)))
    
    }

  }
  




function resetPasswordConfirm(res) {
  return {
    type: RESET_SUCCESS,
    res
  }
}

function resetPasswordError(err) {
  return {
    type: RESET_FAILURE,
    err
  }
}





