import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getApi } from '../../../api/api';
import {fetchAccounts} from '../../../api/account_service/api.show.accounts';
import Loader from '../loading/loader';


export function container(Wrapper){
class Container extends Component {
  constructor(){
    super()
    this.state={
      uid:'',
      isLoading:true,
      statementList:null,
      accountLoading:true,
      accountId: window.localStorage.getItem('aid'),
      accountName:window.localStorage.getItem('accountName')
    }
  }

  componentWillMount() {
    let aid = window.localStorage.getItem('aid')
    this.props.fetchAccounts()
    // this.setState({uid:uid})
    this.props.getApi({
      url:`accounts/${aid}/statements`,
      method:'GET',
      showErrorMessage:false,
      showSuccessMessage:false,
      errorActionName:'GET_STATEMENT_NOTE_FAIL',
      successActionName:'GET_STATEMENT_SUCCESS'
    })
  }

  getStatement = (id) =>{
    if(id) {
    this.props.getApi({
      url:`accounts/${id}/statements`,
      method:'GET',
      showErrorMessage:false,
      showSuccessMessage:false,
      errorActionName:'GET_STATEMENT_NOTE_FAIL',
      successActionName:'GET_STATEMENT_SUCCESS'
    })
   } 
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.statement.statementFetchSuccess){
     let {statement} = nextProps.statement.data.data;
      this.setState({
        isLoading:false,
        statementList:statement
      })
    }

    if (nextProps.showAccount.isSuccess === true && this.props.showAccount!==nextProps.showAccount) {
      this.setState({
        accountLoading: false,
      });
  }
  }

  onchange = (selectedOption) => {
    window.localStorage.setItem('aid', selectedOption.value)
    window.localStorage.setItem('accountName', selectedOption.label)
    this.setState({
      accountId: selectedOption.value,
      accountName:selectedOption.label
    })
    this.getStatement(selectedOption.value)
  }

  render() {
    return(
      <div>
      {!this.state.isLoading && !this.state.accountLoading?  
      <Wrapper {...this.state} {...this.props} id={this.props.showAccount.data}
       onchange={this.onchange}/>:
       <div className="loader"><Loader height={80} width={80}/></div>}
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    statement:state.statement,
    showAccount: state.showAccount
   
  }
}

return connect(mapStateToProps, {getApi, fetchAccounts})(Container);

}
