import React from 'react';
import {profileUpdateContainer} from './profileUpdateContainer';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import UploadImage from '../../common/upload'
class Profile extends React.Component {
  submit = (e) => {
    e.preventDefault();
    this.props.submit();
  }
  render() {

    return (
      <div>
        <Row>
          <Col xs="12" sm="7" md="9" >
           <Card>
              <CardHeader>
              <div className="d-flex">
                <h5 className="">Profile image </h5>
              </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="profile-image">
                    <UploadImage imageUrl={this.props.avatar_url} {...this.props}/>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
              <div className="d-flex">
                <h5 className="">Basic Info </h5>
              </div>
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.submit}>
                  <Row className="profile-image">
                    <Col xs="12" md="6" >
                      <FormGroup>
                        <Label htmlFor="user_name">First Name</Label>
                        <Input
                          type="text"
                          name="first_name"
                          value={this.props.first_name}
                          onChange={this.props.change}
                          pattern="[a-zA-Z]+"
                          required={true}/>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="user_last_name">Last Name</Label>
                        <Input
                          type="text"
                          name="last_name"
                          value={this.props.last_name}
                          onChange={this.props.change}
                          pattern="[a-zA-Z]+"
                          required={true}/>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="user_mobile_no">Mobile No</Label>
                        <Input
                          type="text"
                          name="mobile_number"
                          value={this.props.mobile_number}
                          pattern="[0-9]{10}"
                          onChange={this.props.change}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr/>
                  <FormGroup className="clearfix mt-4">
                    <button onClick={this.props.requestDeactivate} className="btn btn-outline-primary pull-left font-weight-normal">Request Account Deactivation</button>
                    <div className="pull-right">
                      <Button color="default" className="mr-2" onClick={()=>window.history.back()}> Cancel</Button>
                      <button type="submit" className="btn btn-primary"  disabled={this.props.disabled}>{this.props.disabled?'Please Wait':'Update Profile'}</button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
              <div className="d-flex">
                <h5 className=""> Password change </h5>
              </div>
              </CardHeader>
              <CardBody>
                <Form id="changePassword" onSubmit={this.props.handlePasswordChange}>
                  <Row>
                    <Col xs="12" md="6" >
                      <FormGroup>
                        <Label htmlFor="old_password">Old password</Label>
                        <Input
                          type="password"
                          name="old_password"
                          onChange={this.props.change}
                          required={true}
                          />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="new_password">New password</Label>
                        <Input
                          id="new_password"
                          type="password"
                          name="new_password"
                          onChange={this.props.change}
                          required={true}
                          pattern=".{8,}"/>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password_confirmation">Confirm password</Label>
                        <Input
                          id="passsword_confirmation"
                          type="password"
                          name="password_confirmation"
                          onChange={this.props.change}
                          //onBlur={this.props.handleBlur}
                          required={true}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr/>
                  <FormGroup className="clearfix mt-4">
                    <div className="pull-right">
                      <Button color="default" className="mr-2" onClick={()=>window.history.back()}> Cancel</Button>
                      <button type="submit" className="btn btn-primary">Change password</button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="5" md="3">
            <Card className="bg-info text-white">
              <CardBody className="text-left">
                <div className="text-muted">Account Email</div>
                <div><strong>{this.props.email}</strong></div>
              </CardBody>
            </Card>
            <Card className="change-email">
              <CardBody className="">
                <Form onSubmit={this.props.handleEmailChange} className=" mb-2">
                  <FormGroup>
                    <Label for="exampleEmail">Change Email</Label>
                    <Input type="email" onChange={this.props.change} required name="newEmail" id="exampleEmail" placeholder="Entry new email address" />
                  </FormGroup>
                  <Button type="submit" outline  color="primary" className="btn-block font-weight-normal">Change this email</Button>
                </Form>
                <small className="text-muted d-block">Email address change will appear only when new email address is validated.</small>
              </CardBody>
            </Card>

          </Col>
        </Row>

      </div>
    );
  }
}

export default profileUpdateContainer(Profile)
