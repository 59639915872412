import React from 'react';
import {editDepositContainer} from './editDepositContainer'
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';

class EditDeposit extends React.Component {

  getEntity = () => {
    var entity = Object
      .keys(this.props.id)
      .map(key => {
        var data = this.props.id[key]
        return (
          <option value={data.id}>{data.account_details}</option>
        )
      })
    return entity
  }

  render() {
    let id = this.getEntity();
    return (
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="">
              Create Deposit
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="postal-address">Entity</Label>
                  <Input
                    type="select"
                    name="depositID"
                    id="entity"
                    onChange={this.props.change}
                    required>
                    <option>Select Entity</option>
                    {id}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="postal-address">Deposit Type</Label>
                  <Input
                    type="select"
                    name="depositType"
                    id="deposit_type"
                    onChange={this.props.change}>
                    <option value="Cash">Select Deposit Type</option>
                    <option value="Cash">Cash</option>
                    <option value="Electronic Fund Transfer">Electronic Fund Transfer</option>

                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="postal-address">Amount</Label>
                  <Input
                    type="text"
                    max="10000000"
                    step="1"
                    name="depositAmount"
                    placeholder="Enter your amount"
                    onChange={this.props.change}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="postal-address">Select the Deposit Date</Label>
                  <DatePicker
                    className="form-control"
                    name="deposit_date"
                    selected={this.props.date}
                    onChange={this.props.change}
                    value={this.props.date.format('YYYY/MM/DD')}/>
                </FormGroup>
              </Col>
            </Row>
            <hr/>
            <FormGroup className="clearfix mt-4">
              <div className="pull-right">
                <div className='mr-2 btn btn-default' onClick={()=>window.history.back()}>Cancel</div>
                <Button
                  type="submit"
                  color="primary"
                  disabled={this.props.buttonDisable}
                  onClick={this.props.deposit}>
                  Create Deposit
                </Button>
              </div>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default editDepositContainer(EditDeposit);