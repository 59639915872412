/* eslint-disable no-param-reassign */
import React from "react";
import { connect } from "react-redux";
import { fetchAccounts } from "../../../../api/account_service/api.show.accounts";
import { listMobileno } from "../../../../api/entity_mobileno/api.show.mobileno";
import { indexProducts } from "../../../../api/product/api.index.product";
import { createOrder } from "../../../../api/order_service/api.create.order";
import { addMobileNo } from "../../../../api/entity_mobileno/api.create.mobileno";
import _ from "lodash";
import { getApi } from "../../../../api/api";
import Loader from "../../loading/loader";
import { bindActionCreators } from "redux";
import { actions as toastrActions } from "react-redux-toastr";

export function createOrderContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        accountLoading: true,
        mloading: true,
        ploading: true,
        aid: window.localStorage.getItem("aid"),
        numberList: {},
        product: {},
        email: "",
        order: [],
        accountId: window.localStorage.getItem("aid"),
        accountName: window.localStorage.getItem("accountName"),
        accountEmail: "",
        createOrder: false,
        saveDraftRequest: false,
        proceedOrderRequest: false,
        popOverIsOpen: false,
        calculateTotalPrice: false,
        totalPrice: 0,
        discountID: null,
        discountRate: 0,
        amount: 0,
        credit: 0
      };
    }

    getSingleAccount = () => {
      this.props.getApi({
        url: `accounts/${this.state.accountId}`,
        method: "GET",
        successActionName: "GET_ACCOUNT_DETAIL_SUCCESS",
        errorActionName: "GET_ACCOUNT_DETAIL_FAIL",
        showErrorMessage: false,
        showSuccessMessage: false
      });
    };

    loadMobileNumber = () => {
      this.props.listMobileno(this.state.billingId);
    };

    componentWillMount() {
      this.props.indexProducts();
      this.props.fetchAccounts();
      this.props.listMobileno(this.state.accountId);
      this.getSingleAccount();
    }
    calculateProductPrice = () => {
      if (this.state.order.length !== 0 && this.state.product) {
        let itemPrice = 0;
        // eslint-disable-next-line
        this.state.order.map(key => {
          const price = _.findKey(this.state.product, {
            value: key.voucher_type
          });
          itemPrice += this.state.product[price].price * key.voucher_quantity;
        });
        this.setState({
          totalPrice: (itemPrice * 1.0) / 100,
          calculateTotalPrice: false
        });
      } else {
        this.setState({
          totalPrice: 0,
          calculateTotalPrice: false
        });
      }
    };

    componentWillReceiveProps(nextProps) {
      if (nextProps.billingDetail.listSingleAccountSuccess) {
        this.setState({
          amount: nextProps.billingDetail.singleAccountData.available_funds,
          credit: nextProps.billingDetail.singleAccountData.available_credit
        });
      }
      if (
        nextProps.orderReducer.createSuccess === true &&
        this.state.createOrder
      ) {
        this.setState({
          createOrder: false,
          saveDraftRequest: false,
          proceedOrderRequest: false
        });
        if (
          nextProps.orderReducer.createData.data.order.order_status === "draft"
        ) {
          this.props.notification({
            title: "Your Order has been saved",
            type: "success",
            options: { showCloseButton: true, removeOnHover: true }
          });
          window.history.back();
        } else {
          const { history } = this.props;
          history.push({
            pathname: "/order/thankyou",
            state: {
              type: nextProps.orderReducer.createData.data.order_placed,
              email: this.state.accountEmail
            }
          });
        }
      }
      if (nextProps.orderReducer.createError === true) {
        this.setState({
          saveDraftRequest: false,
          proceedOrderRequest: false
        });
      }

      if (nextProps.showAccount.isSuccess === true) {
        // eslint-disable-next-line
        Object.keys(nextProps.showAccount.data).map(key => {
          const account = nextProps.showAccount.data[key];
          // eslint-disable-next-line
          if (account.id == this.state.accountId) {
            this.setState({
              accountEmail: account.account_email,
              accountLoading: false
            });
          }
        });
      }
      if (nextProps.mobileno.listSuccess === true) {
        const numbers = [];
        // eslint-disable-next-line
        Object.keys(nextProps.mobileno.listData.numbers).map(key => {
          const number = nextProps.mobileno.listData.numbers[key];
          if (number.active === true) {
            numbers.push({
              value: number.mobile_number,
              label: `${number.associated_name} | ${number.mobile_number}`,
              date: `${number.last_recharge_date}`,
              delivery_person: number.associated_name
            });
          }
        });
        this.setState({ numberList: numbers, mloading: false });
      }

      if (nextProps.products.isSuccess === true) {
        const list = Object.keys(nextProps.products.data).map(key => {
          const data = nextProps.products.data[key];
          return {
            value: data.product_name,
            label: data.product_name,
            price: data.product_price
          };
        });
        this.setState({
          ploading: false,
          product: list
        });
      }
    }

    addOrder = data => {
      const items = this.state.order;
      items.push(data);
      this.setState({
        order: items,
        calculateTotalPrice: true
      });
    };

    getInitialNumber = () => {
      this.props.listMobileno(this.state.aid);
    };

    getDiscountId = (discountId, discountRate) => {
      this.setState({ discountID: discountId, discountRate });
    };

    getBillId = e => {
      const data = document.getElementById(e.target.value);
      window.localStorage.setItem("emailUsed", data.dataset.email);
      this.setState({
        aid: e.target.value,
        email: data.dataset.email,
        order: []
      });
      this.props.fetchAccounts();
      this.props.listMobileno(e.target.value);
    };

    saveDraft = e => {
      e.preventDefault();
      const order = {
        order: {
          order_items: this.state.order.map(item => {
            delete item.delivery_person;
            return item;
          }),
          order_status: "draft"
        }
      };
      this.props.createOrder(this.state.accountId, order);
      this.setState({
        createOrder: true,
        saveDraftRequest: true
      });
    };

    proceedOrder = e => {
      e.preventDefault();
      const order = {
        order: {
          order_items: this.state.order.map(item => {
            delete item.delivery_person;
            return item;
          }),
          order_status: "pending",
          promotion_id: this.state.discountID ? this.state.discountID : null,
          discount_amount: this.state.discountRate ? this.state.discountRate : 0
        }
      };
      this.props.createOrder(this.state.accountId, order);
      this.setState({
        createOrder: true,
        proceedOrderRequest: true
      });
    };

    removeOrder = data => {
      let order = this.state.order;
      // eslint-disable-next-line
      order = order.filter(item => {
        if (item !== data) {
          return order;
        }
      });
      this.setState({ order, calculateTotalPrice: true });
    };

    render() {
      if (this.state.calculateTotalPrice) {
        this.calculateProductPrice();
        return 0;
      }
      return (
        <div>
          {!this.state.accountLoading &&
          !this.state.mloading &&
          !this.state.ploading ? (
            <Component
              bill={this.props.showAccount.data}
              getBillId={this.getBillId}
              getInitialNumber={this.getInitialNumber}
              saveDraft={this.saveDraft}
              getDiscountId={this.getDiscountId}
              proceedOrder={this.proceedOrder}
              {...this.state}
              {...this.props}
              addOrder={this.addOrder}
              loadMobileNumber={this.loadMobileNumber}
              removeOrder={this.removeOrder}
            />
          ) : (
            <div className="loader">
              <Loader height={80} width={80} />
            </div>
          )}
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    const notification = toastrActions.add;
    return bindActionCreators(
      {
        fetchAccounts,
        listMobileno,
        addMobileNo,
        notification,
        indexProducts,
        getApi,
        createOrder
      },
      dispatch
    );
  }

  function mapStateToProps(state) {
    return {
      showAccount: state.showAccount,
      mobileno: state.mobileno,
      products: state.indexProducts,
      orderReducer: state.order,
      billingDetail: state.UpdateAccount
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Container);
}
