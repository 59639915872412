/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
import { ADD_MOBILENO_SUCCESS, ADD_MOBILENO_FAIL} from '../../action/mobile_no';

export function addMobileNo(id, data) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}/numbers`
    let option={
      method: 'POST',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)         
    }
    return fetch(url, option)
    .then(response => response.json().then(createMobileno=>({createMobileno, response})))
    .then(({createMobileno, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      createMobileno.status = response.status
       return Promise.reject(createMobileno)
         } else {
         dispatch(receiveCreateMobileno(createMobileno.data))
         dispatch(toastrActions.add({
          type:'success',
          title:"Added successfully.",
          message:'Recharge number has been added.',
          options:{showCloseButton:true, removeOnHover:true},
          timeOut:4000
         }))
    }})
    .catch(err => {
      dispatch(createMobilenoError(err))
      dispatch(toastrActions.add({
        type:'error',
        message:err.errors.message,
        options:{showCloseButton:true, removeOnHover:true},
        timeOut:4000
       }))
    })
  }
}



function receiveCreateMobileno(data) {
  return {
    type: ADD_MOBILENO_SUCCESS,
    creatData:data
  }
}

function createMobilenoError(err) {
  return {
    type: ADD_MOBILENO_FAIL,
    createError:err
  }
}



