import React from "react";
import { Form, Input, Label, FormGroup, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { loginContainer } from "./loginContainer";
import { PropTypes } from "prop-types";

class Login extends React.Component {
  render() {
    return (
      <div className="authentication_bg">
        <div className="container authentication text-center">
          <div className="d-flex justify-content-center ">
            <div className="authentication__wrapper ">
              {this.props.visible !== false ? (
                <Alert
                  className="alert-danger"
                  isOpen={this.props.visible}
                  toggle={this.props.dismiss}
                >
                  {this.props.error}
                </Alert>
              ) : null}
              <Form onSubmit={this.props.onFormSubmit}>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    onChange={this.props.onFormChange}
                    required
                  />
                  {this.props.errorMessage.email !== null ? (
                    <p>this.props.errorMessage.email</p>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={this.props.onFormChange}
                    required
                  />
                </FormGroup>
                <small>
                  By using this platform you are agreeing to be bound by the{" "}
                  <a
                    target="_blank"
                    href="https://timeslick-static-assets-production.s3.amazonaws.com/terms/terms.pdf"
                  >
                    Terms and Conditions
                  </a>
                  .
                </small>
                <FormGroup>
                  <input
                    type="submit"
                    className="btn btn-primary btn-block"
                    value={this.props.disable ? "Please Wait" : "Login"}
                    disabled={this.props.disable}
                  />
                </FormGroup>
                <Link to={"/"} className="link">
                  Home
                </Link>|{" "}
                <Link to={"/forgotpassword"} className="link">
                  Reset Password
                </Link>{" "}
                |{" "}
                <Link to={"/register"} className="link">
                  {" "}
                  Sign Up{" "}
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default loginContainer(Login);

Login.propTypes = {
  onFormChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

// https://docs.google.com/gview?
