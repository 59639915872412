import { calculateStartDate } from './calculateStartDate'
import { getLastRechargeDate } from './getLastRechargeDate'

const OBJECT_NODE_TO_DELETE = ['order_id', 'id', 'created_at', 'updated_at']
export function getOrderItemList (orderItems, mobileNumbers) {
  const orderItemLists = orderItems.map(orderItem => {
    const newOrderItem = orderItem
    OBJECT_NODE_TO_DELETE.forEach(node => {
      delete newOrderItem[node]
    })

    const scheduledDates = calculateStartDate(
      orderItem.scheduled_dates,
      orderItem.voucher_type,
      getLastRechargeDate(orderItem, mobileNumbers),
    )
    newOrderItem.scheduled_dates = scheduledDates
    return newOrderItem
  })
  return orderItemLists
}
