import config from './api.development.json'

const environment = process.env.REACT_APP_BUILD_ENV
const api = config

if (environment === 'production') {
  api.apiUrl = process.env.REACT_APP_END_POINT_PRODUCTION
} else if (environment === 'staging') {
  api.apiUrl = process.env.REACT_APP_END_POINT_STAGING
} else {
  api.apiUrl = 'https://api.timeslick.com' // process.env.REACT_APP_END_POINT_DEVELOPMENT;
}

export default api
