import React, { Component } from 'react';
import {Form, Button, FormGroup, Label, Input, Popover, PopoverHeader, PopoverBody} from 'reactstrap';
import PropTypes from 'prop-types';
class Mobile extends Component {
  constructor() {
    super();
    this.state = {
      name:'',
      mobile:''
    }
  }

  handleChange=({target})=>{
    this.setState({
      [target.name]:target.value
    })
  }

  addNumber=(e)=>{
    e.preventDefault();
    let data = {number: {associated_name:this.state.name, mobile_number:this.state.mobile}}
    this.props.addMobileNo(this.props.aid, data)
      .then(()=>{this.props.listMobileno(this.props.accountId)})
      .then(()=>{this.props.selectCurrentNumber({value:this.state.mobile,label:`${this.state.name}|${this.state.mobile}`})})
      .then(()=>{this.props.toggle()})
    }

  render() {
    return (
      <Popover placement={'Bottom'} className="popover-form" isOpen={this.props.isOpen} target="Popover2" togglePopover={()=>{this.props.toggle(true)}}>
      <PopoverHeader>Recharge number for {this.props.accountName}</PopoverHeader>
      <PopoverBody>
        <Form onSubmit={this.addNumber}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" name="name" required onChange={this.handleChange} placeholder="Enter name" />
          </FormGroup>
          <FormGroup>
            <Input type="text" name="mobile" required onChange={this.handleChange} placeholder="Enter mobile number" />
            <small>Mobile number format: 081#######</small>
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button color="default" className="mr-2" onClick={()=>{this.props.toggle(false)}}> Cancel</Button>
            <Button color="primary" type="submit"> Save</Button>
          </div>
        </Form>
      </PopoverBody>
    </Popover>
    );
  }
}

Mobile.propTypes = {
  isOpen:PropTypes.bool.isRequired,
  billingAddName: PropTypes.string.isRequired,
  aid:PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  toggle:PropTypes.func.isRequired,
  selectCurrentNumber:PropTypes.func.isRequired,
  accountID:PropTypes.string.isRequired
};

export default Mobile;
