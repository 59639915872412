const DELIVERIES_LOAD_FAIL = 'DELIVERIES_LOAD_FAIL'
const DELIVERIES_LOAD_SUCCESS = 'DELIVERIES_LOAD_SUCCESS'

export function deliveries(state = {
  loading:true
}, action) {

  switch (action.type) {
    case DELIVERIES_LOAD_SUCCESS:
      return Object.assign({}, state, {
        listSuccess: true,
        listData: action.response,
      })

    case DELIVERIES_LOAD_FAIL:
    return Object.assign({}, state, {
        listError: true,
        listErrorData: action.response
      })

    default:
      return state;
  }

}
