import {UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAIL, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_FAIL} from '../../action/bill';

const GET_ACCOUNT_DETAIL_SUCCESS = 'GET_ACCOUNT_DETAIL_SUCCESS'
const GET_ACCOUNT_DETAIL_FAIL = 'GET_ACCOUNT_DETAIL_FAIL'

export function billing(state = {
		fetch: true,
		updateSuccess: false,
		updateError: false,
		deleteSuccess: false,
		deleteError: false
}, action) {

		switch (action.type) {

				case UPDATE_ACCOUNT_SUCCESS:{
						return Object.assign({}, state, {
								fetch: false,
								updateSuccess: true,
								updateError: false,
								updateData: action.updateData,
								updateErrorData: null
						})}

				case UPDATE_ACCOUNT_FAIL:{
						return Object.assign({}, state, {
								fetch: false,
								updateSuccess: false,
								updateError: true,
								updateData: null,
								updateErrorData: action.updateError
						})}
							
				case DELETE_ACCOUNT_SUCCESS:
						return Object.assign({}, state, {
								fetch: false,
								deleteSuccess: true,
								deleteError: false,
								deleteData: action.listData,
								deleteErrorData: null
						})

				case DELETE_ACCOUNT_FAIL:
						return Object.assign({}, state, {
								fetch: false,
								deleteSuccess: false,
								deleteError: true,
								deleteData: null,
								deleteErrorData: action.deleteError
						})
					
						case GET_ACCOUNT_DETAIL_SUCCESS:
						return Object.assign({}, state, {
								listSingleAccountSuccess: true,
								singleAccountData: action.response.data.account,
						})

				case GET_ACCOUNT_DETAIL_FAIL:
						return Object.assign({}, state, {
							listSingleAccountError: true,
						})

				default:
						return state;
		}

}