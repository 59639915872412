export const getVoucherType = voucherType => {
  const hasAweh = voucherType.toLowerCase().includes('aweh')
  const isAweh = hasAweh && !voucherType.includes('30')
  const isAweh30 = hasAweh && voucherType.includes('30')
  const isTango = !isAweh && !isAweh30
  return {
    isAweh,
    isAweh30,
    isTango,
  }
}
