import { get, post, put, remove } from "./api";

export function Get(resources) {
  return async dispatch => {
    const token = localStorage.getItem("access_token");
    const result = await get(resources.url, token);
    if (result.status === 200) {
      dispatch({ type: resources.asyncActions.success, payload: result.data });
    }
    return result;
  };
}

export function Post(resources) {
  return async dispatch => {
    const token = localStorage.getItem("access_token");
    const result = await post(resources.url, resources.body, token);
    if (result.status === 201) {
      dispatch({ type: resources.asyncActions.success, payload: result.data });
    }

    return result;
  };
}

export function Put(resources) {
  return async dispatch => {
    const token = localStorage.getItem("access_token");
    const result = await put(resources.url, resources.body, token);
    console.log(result);
  };
}

export function Delete(resources) {
  return async dispatch => {
    const token = localStorage.getItem("access_token");
    const result = await remove(resources.url, token);
    console.log(result);
  };
}
