import React from "react";
import { newContainer } from "./newContainer";
import { CountryDropdown } from "react-country-region-selector";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

class AddressNew extends React.Component {
  componentDidMount() {
    if (this.props.accountCount === 0) {
      const name = document.getElementById("account_details");
      name.value = `${this.props.first_name} ${this.props.last_name}`;
      this.props.initialEntity(
        `${this.props.first_name} ${this.props.last_name}`
      );
    }

    const country = document.getElementById("countryDropDown");
    country.classList.add("form-control");
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="">Create New Account</h5>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.props.submit}>
            <FormGroup>
              <Label htmlFor="company">Account Name</Label>
              <Input
                type="text"
                name="account_details"
                id="account_details"
                placeholder="Enter your billing address name."
                onChange={this.props.change}
                required
              />
            </FormGroup>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="postal_address">Postal Address</Label>
                  <div className="input-prepend input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">P.O.Box</span>
                    </div>
                    <Input
                      required
                      type="text"
                      steps="1"
                      name="postal_address"
                      pattern="\d*"
                      placeholder="Enter your postal address number"
                      onChange={this.props.change}
                      title="Postal address should only contain numbers. e.g. 11002"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="account_email">Email Address</Label>
                  <Input
                    type="email"
                    name="account_email"
                    placeholder="Enter your email address"
                    value={this.props.account_email}
                    onChange={this.props.change}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="city">City</Label>
                  <Input
                    type="text"
                    placeholder="Enter your city"
                    onChange={this.props.change}
                    name="city"
                  />
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="country">Country</Label>
                  <CountryDropdown
                    id="countryDropDown"
                    value={this.props.country}
                    onChange={val => this.props.selectCountry(val)}
                 
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="account_mobile_number">Mobile Number</Label>
                  <Input
                    type="text"
                    name="account_mobile_number"
                    placeholder="Enter your mobile number"
                    value={this.props.account_mobile_number}
                    onChange={this.props.change}
                    required
                  />
                  <small className="text-mute">
                    Make sure mobile number only contain digits.
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <FormGroup className="clearfix mt-4">
              <div className="pull-right">
                <div
                  className="mr-2 btn btn-default"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </div>
                <Button type="submit" color="primary">
                  Create Account
                </Button>
              </div>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default newContainer(AddressNew);
