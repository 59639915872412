import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { invoiceContainer } from "./invoiceContainer";
import MailModal from "../mail/index";
import EmptyMessageCard from "../../IdSelector/emptyMessageCard";
import EmptyItemsCard from "../../IdSelector/emptyInvoice";
import Select from "react-select";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

class Invoices extends React.Component {
  constructor() {
    super();
    this.state = {
      modal: false
    };
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  listInvoice = () => {
    return this.props.invoices.showData.data.invoice.map(key => {
      return (
        <tr key={key.id}>
          <td>{key.invoice_number}</td>
          <td>{key.invoice_date}</td>
          <td>
            <strong>N${(key.total_amount / 100).toFixed(2)}</strong>
          </td>
          <td>
            <a href={key.pdf_url} target="_blank" className="action-icon">
              <span title="Download pdf">
                <i className="fa fa-file-pdf-o" />
              </span>
            </a>
            <span className="action-icon" title="Mail">
              <i
                onClick={() => {
                  this.props.sendEmail(key);
                }}
                className="fa fa-envelope-o"
              >
                <MailModal
                  modal={this.state.modal}
                  data={key}
                  toggle={this.toggleModal}
                />
              </i>
            </span>
          </td>
        </tr>
      );
    });
  };

  entity = () => {
    var list = Object.keys(this.props.id).map(key => {
      let data = this.props.id[key];
      return { value: data.id, label: data.account_details };
    });
    return list;
  };

  formatAction = (col, row) => {
    return (
      <div>
        <a href={row.pdf_url} target="_blank" className="action-icon">
          <span title="Download pdf">
            <i className="fa fa-file-pdf-o" />
          </span>
        </a>
        {/* To enable email feature unlock this code */}
        {/* <span className="action-icon" title="Mail"><i onClick={()=>{this.props.sendEmail(row)}}className="fa fa-envelope-o"><MailModal modal={this.state.modal} data={row} toggle={this.toggleModal}/></i></span> */}
        {/* To enable email feature unlock this code */}
      </div>
    );
  };

  formatAmount = (col, row) => {
    return (
      <strong>
        <span>N$ {(row.total_amount / 100).toFixed(2)}</span>
      </strong>
    );
  };

  render() {
    let list = this.entity();
    let selected = {
      value: this.props.accountId,
      label: this.props.accountName
    };
    //let invoices = this.props.invoices===null || this.props.invoices.showData.data.invoice===null?null:this.listInvoice()
    if (list.length === 0) {
      return <EmptyMessageCard />;
    } else {
      return (
        <Card>
          <CardHeader>
            <div className="d-flex heading">
              <h5 className="">Invoices for</h5>
              <Select
                className="heading__entity"
                name="entity"
                value={selected}
                onChange={this.props.change}
                options={list}
                clearable={false}
                searchable={false}
              />
            </div>
          </CardHeader>
          <CardBody>
            <BootstrapTable
              pagination
              data={
                this.props.invoices
                  ? this.props.invoices.showData.data.invoice
                  : []
              }
              version="4"
              bordered={false}
            >
              <TableHeaderColumn isKey dataField="invoice_number">
                Invoice No
              </TableHeaderColumn>
              <TableHeaderColumn dataField="invoice_date">
                Invoice Date
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatAmount}>
                Amount
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatAction} className="asa">
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </CardBody>
        </Card>
      );
    }
  }
}

export default invoiceContainer(Invoices);
