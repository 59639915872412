
export function statement(state = {
  statementFetchSuccess:false    

}, action) {
  switch (action.type) {
    case 'GET_STATEMENT_FAIL':
      return Object.assign({}, state, {
        statementFetchError:true
      })

    case 'GET_STATEMENT_SUCCESS':
      return Object.assign({}, state, {
        statementFetchSuccess: true,
        data:action.response
      })

    default:
      return state;

  }
}