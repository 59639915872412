import React, {Component} from 'react';
import {connect} from 'react-redux'

import {confirmRegister} from '../../../api/auth/api.confirmRegister';
import Loader from '../../views/loading/loader';

export function registerSuccessContainer(WarpperComponent) {

  class RegisterConfirm extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        message: ''
      }
    }

    componentDidMount() {
      let token = {
        confirmation_token: this.props.match.params.id
      }
      let url = ''
      if(this.props.match.path==="/confirmation/confirm_user/:id"){
        url = 'confirmation/confirm_user'
        this.props.confirmRegister(token, url);
      } else {
        url = 'confirmation/confirm_user/email'
        this.props.confirmRegister(token, url)
      }
      
    }

    /**
     * @description recive the api call data as a component property
     */
    componentWillReceiveProps(nextProps) {
      if (typeof nextProps.registerConfirm.isConfirm !== undefined && nextProps.registerConfirm.isConfirm === true) {
        this.setState({message: 'Your email has been verified successfully.', loading: false})
      }
      if (typeof nextProps.registerConfirm.hasError !== undefined && nextProps.registerConfirm.hasError === true) {
        this.setState({message: 'Sorry!! This is not a valid confirmation link or your token has expired', loading: false})
      }
    }

    render() {
      return (
        <div>
          {this.state.loading
            ? <div className="loader"><Loader height={80} width={80}/></div>
            : <WarpperComponent { ...this.state}/>}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {registerConfirm: state.registerConfirm}
  }
  return connect(mapStateToProps, {confirmRegister})(RegisterConfirm)
}
