import React, {Component} from 'react';
import {connect} from 'react-redux'
import {resetPassword} from '../../../api/auth/api.resetPassword'
import { bindActionCreators } from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'


export function resetPasswordContainer(WarpperComponent) {

  class RegisterConfirm extends Component {
    constructor(props) {
      super(props)
      this.state = {
        token: '',
        password: '',
        rePassword: '',
        error: false,
        disable:false
      }
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    onSubmit = (e) => {
      this.setState({disable:true})
      e.preventDefault();
        let data = {
          reset_token: this.state.token,
          password: this.state.password,
          password_confirm: this.state.rePassword
        }
        this.props.resetPassword(data);
    }

    componentDidMount() {
      this.setState({token:  this.props.match.params.id
      })
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.reset.isReset === true) {
        this.setState({disable:false})
        this.props.add({
          title:'Your password has been successfully updated.',
          type:'success',
          timeOut:4000,
          options:{showCloseButton:true, removeOnHover:true}
        })
        let {history} = this.props;
        history.push('/')
      }

      if(nextProps.reset.hasError===true){
        let data = nextProps.reset.errorMessage
        this.props.add({
          title:"Reset Password Unsuccessful",
          type:'error',
          message:[data.errors.message],
          options:{showCloseButton:true,removeOnHover:true}
        })
      }

    }

    render() {
      return (
        <div>
          <WarpperComponent {...this.state} change={this.handleChange} submit={this.onSubmit}/>
        </div>
      );
    }
  }
  


function mapDispatchToProps(dispatch) {
  let add = toastrActions.add;
  return bindActionCreators({
    resetPassword,
    add:add
  }, dispatch)
}


  function mapStateToProps(state) {
    return {reset: state.resetPassword}
  }
  return connect(mapStateToProps,mapDispatchToProps)(RegisterConfirm)
}
