/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
export const CREATE_DEPOSIT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
export const CREATE_DEPOSIT_SUCCESS = 'CREATE_DEPOSIT_SUCCESS'
export const CREATE_DEPOSIT_FAILURE = 'CREATE_DEPOSIT_FAILURE'

export function createDeposit(id, data) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}/deposits`
    let option={
      method: 'POST',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)
    }
    return fetch(url, option)
    .then(response => response.json().then(createDeposit=>({createDeposit, response})))
    .then(({createDeposit, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      createDeposit.status = response.status
       return Promise.reject(createDeposit)
         } else {
         dispatch(receiveCreateDeposit(createDeposit.data))
    }})
    .catch(err => {
      dispatch(createDepositError(err))
      dispatch(toastrActions.add({
        type:'error',
        message:err.errors.message,
        options:{showCloseButton:true, removeOnHover:true},
      })
    
    )})
  }
}


function receiveCreateDeposit(data) {
  return {
    type: CREATE_DEPOSIT_SUCCESS,
    data
  }
}

function createDepositError(err) {
  return {
    type: CREATE_DEPOSIT_FAILURE,
    err
  }
}



