import React from 'react';
import {connect} from 'react-redux';
import Loader from '../../loading/loader';
import { orderItems } from '../../../../api/order_service/api.items.order'

export function showOrderContainer(Component) {
  class Order extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        oid:'',
        aid:''
        
      }
    }

    componentWillMount() {
      this.setState({
        oid: this.props.match.params.oid,
        aid: this.props.match.params.aid
      })
    }

    componentDidMount() {
      this.props.orderItems(this.props.match.params.aid, this.props.match.params.oid)
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.order.itemsSuccess===true){
        this.setState({loading:false})
      }
    }

    editOrder=()=>{
      this.props.history.push({
        pathname:`../../../orders/edit/${this.state.oid}`,
        state: {detail:{order_items:this.props.order.itemsData.data.order.order_items, id: this.state.oid, account_id: this.state.aid}}
      })
    }

    render() {
      return (
        <div>
         {this.state.loading
         ? <div className="loader"><Loader height={80} width={80}/></div>
         :<Component
         items={this.props.order.itemsData}
         editOrder={this.editOrder} 
         />}
        </div> 
      );
    }

  }


  function mapStateToProps(state) {
    return {order:state.order}
  }

  return connect(mapStateToProps,{orderItems})(Order)

}
