import React from 'react';
import {showDepositContainer} from './showDepositContainer';
import {Card, CardBody, CardHeader, Button, Label} from 'reactstrap';
import {Link} from 'react-router-dom';
import EmptyMessageCard from '../../IdSelector/emptyMessageCard'
import EmptyItemsCard from '../../IdSelector/emptyMessageCard.1'
import Select from 'react-select';
import Tooltips from '../../common/tooltips';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import DatePicker from 'react-datepicker';
import moment from 'moment'



class Deposit extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      deposits:this.props.deposits,
      depositFrom:moment().subtract('7', 'days')  ,
      depositTo:moment(),
      isShowingSearch:false,
      showingSearchUI:false
    }
  }

  entity = () => {
    var list = Object
      .keys(this.props.id)
      .map(key => {
        let data = this.props.id[key]
        return (
          {value:data.id, label:data.account_details}
        )
      })
    return list
  }

  formatAction=(col, row)=>{
    if(row.deposit_status==="pending"){
      return (
        <span onClick={()=>{this.props.handleDeleteDeposit(row.id)}}
        className="action-icon"><Tooltips icon={'icon-trash'} message={'Delete'} id={`delete${row.id}`}></Tooltips></span>
      )
    }
  }

  formatStatus=(col, row)=>{

    if(row.deposit_status==="pending"){
      return(
        <span className='is-pending'>{row.deposit_status}</span>
      )
    } else {
      return (
        <span className="is-cleared">{row.deposit_status}</span>
      )
    }
  }

  formatPdf=(col, row, type)=>{
    if(row.deposit_status==='pending' || row.deposit_type === 'Credit Extended'){return null}
    else{return(
        <span>
          <a href={row.pdf_url} target="_blank" className="action-icon" title="Download pdf">
          <i className="fa fa-file-pdf-o">
          </i> </a>
        </span>
      )
    }
  }

  formatAmount=(col, row)=> {
    return (
      <b><span>N$ {(row.amount/100).toFixed(2)}</span></b>
    )
  }

  _onDepositFromChange=(data)=>{
    this.setState({
      depositFrom:data
    })
  }


  _onDepositToChange=(data)=>{
    this.setState({
      depositTo:data
    })
  }

  showSearchField=()=>{
    this.setState({
        showingSearchUI:!this.state.showingSearchUI,
        isShowingSearch:!this.state.isShowingSearch,
        deposits:this.props.deposits,
    })
  }

  search=()=>{
   let {depositFrom, depositTo} = this.state
   let {deposits} = this.props
   let search = []
     deposits && deposits.map(key=>{
       let date = moment(key.deposited_on);
       if(date.isBetween(depositFrom, depositTo)) {
        search.push(key)
       }
     })
     this.setState({
       deposits:search,
       isShowingSearch:true,
     })
}


  render() {
    let list = this.entity()
    let selected ={value:this.props.accountId, label:this.props.accountName}
    if(list.length===0){
      return(
        <EmptyMessageCard/>
      )
    } else {
    return (
      <Card>
        <CardHeader>
      { this.props.id.length === 0 ? null :
        <div>
          <div className="d-flex heading">
            <h5 className="">
                Deposits for</h5>
            <Select
            className="heading__entity"
            name="entity"
            value={selected}
            onChange={this.props.change}
            options={list}
            clearable={false}
            searchable={false}
            />
            <div className="ml-auto">
            <Button className={'btn btn-outline-primary mr-2'} onClick={this.showSearchField}>Filter</Button>
            <Link to="/deposits/new" className={'btn btn-primary'}>Add Deposit</Link>
            </div>
          </div>
        {this.state.showingSearchUI &&
          <div className="d-flex align-items-center bg-light p-2 mt-3 text-white deposit_search rounded  justify-content-end">
            <Label className="mr-2 mb-0">Deposits From </Label>
            <DatePicker
            className="form-control"
            name="Start"
            maxDate={moment()}
            onChange={this._onDepositFromChange}
            value={this.state.depositFrom.format('YYYY/MM/DD')}
            />

          <Label className="mr-2 mb-0 ml-2">To</Label>
          <DatePicker
            className="form-control mr-10"
            name="end Date"
            onChange={this._onDepositToChange}
            maxDate={moment()}
            value={this.state.depositTo.format('YYYY/MM/DD')}
            />
            <Button className="ml-2" onClick={this.search} color='primary'>Search</Button>
            <Button className="ml-2" onClick={this.showSearchField} color='default'>Cancel</Button>

          </div>
        }
        </div>

      }
        </CardHeader>
        <CardBody>
          <BootstrapTable pagination data={this.state.isShowingSearch?this.state.deposits:this.props.deposits}  version='4' bordered={ false }>
              <TableHeaderColumn isKey dataField='deposited_on'>Deposit Date</TableHeaderColumn>
              <TableHeaderColumn dataField='deposit_type'>Type</TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatAmount}>Amount</TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatStatus} dataSort={true}>Status</TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatPdf}>Receipts</TableHeaderColumn>
              <TableHeaderColumn dataFormat={ this.formatAction} className="asa"></TableHeaderColumn>
          </BootstrapTable>
        </CardBody>
      </Card>
    );
  }
 }
}


export default showDepositContainer(Deposit)
