import React from 'react';
import { Alert } from 'reactstrap';

class GlobalAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.setting.enable_settings_msg
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    if(this.state.visible) {
    return (
      <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
       {this.props.setting.maintenance_message}
      </Alert>
    ) } else {
      return <div></div>
    }
  }
}

export default GlobalAlert;