import React, {Component} from 'react';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'
import {updatePassword} from '../../../api/auth/passwordReset'


export function resetPasswordContainer(WarpperComponent) {

  class RegisterConfirm extends Component {
    constructor(props) {
      super(props)
      this.state = {
        password: '',
        rePassword: '',
        oldPassword: '',
        error: false,
        disable:false
      }
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    onSubmit = (e) => {
      e.preventDefault();
      this.setState({disable:true})
      let {password, rePassword } = this.state;
      if(password!=rePassword){
        this.props.add({
          title:"Password confirmation does not match.",
          type:"error",
        })
        return 0;
      } else { 
        let data = {
          user: {
          old_password: this.state.oldPassword,
          password: this.state.password,
          password_confirmation: this.state.rePassword
        }}
        this.props.updatePassword(window.localStorage.getItem('user_id'),data);
      }
    }

    componentWillReceiveProps(nextProps){
      // eslint-disable-next-line
      if(nextProps.reset.isResetSuccess==true){
        this.props.add({
          title: "Password has been change!",
          type: "success",
          options: {showCloseButton: true}
        })
        window.localStorage.setItem('need_password_change',false)
        this.props.history.push('/')
      }
      // eslint-disable-next-line
      if(nextProps.reset.hasError==true){
        this.props.add({
          title: "Password could not be changed!",
          type: "error",
          message: nextProps.reset.errorMessage.errors.message,
          options: {showCloseButton: true}
        })
      }

    }

    componentDidMount() {
      // eslint-disable-next-line
      window.localStorage.getItem('need_password_change')=="true" ? null  : this.props.history.push('/')
    }
    render() {
      return (
        <div>
          <WarpperComponent {...this.state} handleChange={this.handleChange} submit={this.onSubmit}/>
        </div>
      );
    }
  }




function mapDispatchToProps(dispatch) {
  let add = toastrActions.add;
  return bindActionCreators({
    updatePassword,
    add:add
  }, dispatch)
}


  function mapStateToProps(state) {
    return {reset: state.changePassword}
  }
  return connect(mapStateToProps,mapDispatchToProps)(RegisterConfirm)
}
