import React from 'react';
import {Form, Input, Label, FormGroup} from 'reactstrap';
import {forgetPasswordContainer} from './forgetPasswordContainer'
import {Link} from 'react-router-dom';

class Forgotpassword extends React.Component {
  render() {
    return (
      <div className="authentication_bg">
        <div className="container authentication text-center">
          <div className="d-flex justify-content-center ">
            <div className="authentication__wrapper ">
              {this.props.message !== null
                ? <p className=" alert alert-success">{this.props.message}</p>
                : null}
              <Form className="form-group" onSubmit={this.props.submit}>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    onChange={this.props.change}
                    required={true}/>
                </FormGroup>
                <button
                  type='submit'
                  className="btn btn-primary btn-block"
                  disabled={this.props.request}
                 >Reset Password</button>
              </Form>
              <Link to="/login" className="link">Login</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default forgetPasswordContainer(Forgotpassword)
