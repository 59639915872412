import React from 'react';
import {resetPasswordContainer} from './resetPasswordContainer'
import {Form, Input, Label, FormGroup} from 'reactstrap';

class Passwordreset extends React.Component {
  render() {
    return (
      <div className="container authentication">
        <h3 className="text-center authentication__title">
          SECURITY CHECK
          <small className="text-center authentication__sub-title">This is your first time login TimeSlick Services. Please change the password before continue</small>
        </h3>
        <div className="d-flex justify-content-center ">
          <div className="authentication__wrapper ">
            <Form className="form-group">
              <FormGroup>
                <Label for="password">Old password</Label>
                <Input
                  onChange={this.props.handleChange}
                  type="password"
                  name="oldPassword"
                  id="old_password"
                  placeholder="Enter your old password"
                  />
              </FormGroup>
              <FormGroup>
                <Label for="password">New password</Label>
                <Input
                  onChange={this.props.handleChange}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  />
              </FormGroup>
              <FormGroup>
                <Label for="password">
                  Confirm Password</Label>
                <Input
                  onChange={this.props.handleChange}
                  type="password"
                  name="rePassword"
                  id="rePassword"
                  placeholder="Enter your Password Again"
                  />
              </FormGroup>
              <button className="btn btn-primary btn-block" disabled={this.props.disabled} onClick={this.props.submit}>Submit password</button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default resetPasswordContainer(Passwordreset);
