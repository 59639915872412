import React, { Component } from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../api/account_service/api.show.accounts';
import { addMobileNo } from '../../../api/entity_mobileno/api.create.mobileno';
import { listMobileno } from '../../../api/entity_mobileno/api.show.mobileno';
import {updateMobileNo} from '../../../api/entity_mobileno/api.update.mobileno'
import {deleteMobileno} from '../../../api/entity_mobileno/api.delete.mobileno';
import Loader from '../loading/loader';





export function container(Warp) {
  class Container extends Component {
    state = {
      showForm:false,
      isEditing:false,
      editingData:{},
      isLoading:true,
      isNumberFetching:true,
      numbers:[],
      accounts:[],
      accountID: window.localStorage.getItem('aid'),
      accountName:window.localStorage.getItem('accountName')
      
    }

    toggleShowForm=(value)=>{
      this.setState({
        showForm:value
      })
    }

    handleEdit=(data)=>{
      this.setState({
        showForm:data.showForm,
        isEditing:data.isEditing,
        editingData:data.editingData,
      })
    }

    handleAccountChange = (selectedOption) =>{
      window.localStorage.setItem('aid', selectedOption.value)
      window.localStorage.setItem('accountName', selectedOption.label)
      this.setState({
        accountID: selectedOption.value,
        accountName:selectedOption.label
      })
      this.state.accountID && this.props.listMobileno(selectedOption.value)
    }

    handleDelete=(data)=>{
      this.setState({deleteInit:true, modal:false})
      this.props.deleteMobileno(this.state.accountID, data.id)
        .then(()=>{this.loadMobileNumber()});
    }

    loadMobileNumber=()=>{
      if(this.state.accountID) {
        this.props.listMobileno(this.state.accountID)
       } else {
         this.setState({
           isNumberFetching:false
         })
       }
    }

    componentDidMount(){
      this.props.fetchAccounts();
      this.loadMobileNumber();
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.fetchAccount.isSuccess) {
        let {data} =  nextProps.fetchAccount
        if(data) { 
              let accounts = data.map(key=>{
              return {value:key.id, label:key.account_details,number:key.account_mobile_number
              }
            })
            this.setState({
              isloading:false,
              accounts:accounts
            })
        } else {
          this.setState({
            isloading:false,
            accounts:[]
          })
        }    
      }

      if(nextProps.rechargeNumber.listSuccess){
        let {numbers} = nextProps.rechargeNumber.listData
        this.setState({
          numbers:numbers,
          isNumberFetching:false
        })
      }      
    }

    render() {
      let { isNumberFetching, isloading } = this.state;
      return (
        <div>
          { !isNumberFetching && !isloading ?    
          <Warp 
            {...this.props} 
            {...this.state}
            toggleShowForm = {this.toggleShowForm}
            handleAccountChange={this.handleAccountChange}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
          />
          :<div className="loader"><Loader height={80} width={80}/></div>}
        </div>
      );
    }
  }

  function mapStateToProps (state, ownProps) {
    return {
     fetchAccount:state.showAccount,
     rechargeNumber: state.mobileno,
    }
  }
  return connect(mapStateToProps, {fetchAccounts, addMobileNo, listMobileno, updateMobileNo, deleteMobileno })(Container)
}