import {FORGET_SUCCESS, FORGET_FAILURE} from '../../api/auth/forgotPassword';

export function forgetPassword(state = {
		isForgetSuccess: false,

}, action) {
		switch (action.type) {

				case FORGET_SUCCESS:
						return Object.assign({}, state, {
								isRequested: false,
								isForgetSuccess: true,
								hasError: false,
								data: action.res
						})

				case FORGET_FAILURE:
						return Object.assign({}, state, {
								isRequested: false,
								isForgetSuccess: false,
								hasError: true,
								errorMessage: action.err
						})

				default:
						return state;

		}
}