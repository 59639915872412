/**
 *@description refer src/api/api.js. same as that 
 */
import api from "../../config/api.js";
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'



export function registerUser(data){
  let tokenurl = `${api.apiUrl}${api.version}${api.resource.apiToken}`
  let url = `${api.apiUrl}${api.version}${api.resource.register}`
  return dispatch => {
  return fetch(tokenurl, {
      method: 'post',
      Content:'text/plain',
      body: JSON.stringify({"application":{"application_name":"front-end", "password":"xS3g7j8EYefUE8wk"}})})
     .then(res => res.json())
     .then((res)=> {
            let option={
            method: 'POST',
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+res.data.token},
            body:JSON.stringify(data) }
        fetch(url, option)
        .then(response => response.json().then(user => ({ user, response })))
        .then(({ user, response }) =>  {
          if (!response.ok) {
          user.status=response.status;
          return Promise.reject(user)
            } else {
            dispatch(receiveRegister(user.data.user))
          }
        }).catch(err => dispatch(registerError(err)))
      })
      .catch(err => dispatch(registerError(err)))
    
    }

  }
  




function receiveRegister(res) {
  return {
    type: REGISTER_SUCCESS,
    res
  }
}

function registerError(err) {
  return {
    type: REGISTER_FAILURE,
    err
  }
}





