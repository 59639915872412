import React from "react";
import PropTypes from "prop-types";
import { registerContainer } from "./registerContainer";
import { Form, Input, Label, FormGroup, FormText } from "reactstrap";
import { Link } from "react-router-dom";

class Register extends React.Component {
  render() {
    return (
      <div className="authentication_bg">
        <div className="container authentication text-center">
          <div className="d-flex justify-content-center ">
            <div className="authentication__wrapper ">
              <Form className="form-group" onSubmit={this.props.submit}>
                <FormGroup>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Name"
                    onChange={this.props.change}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Surname"
                    onChange={this.props.change}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    onChange={this.props.change}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="mobileNumber"
                    id="mobileNumber"
                    placeholder="Mobile Number (0811231234)"
                    onChange={this.props.change}
                    pattern="[0-9]{10}"
                    title="Phone Number must be of 10 characters."
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={this.props.change}
                    required
                    pattern=".{8,}"
                  />
                  <FormText color="muted">
                    Password needs to be 8 characters or longer.
                  </FormText>
                </FormGroup>
                <small>
                  By using this platform you are agreeing to be bound by the{" "}
                  <a href="/terms">Terms and Conditions</a>.
                </small>
                <Input
                  type="submit"
                  className="btn btn-primary btn-block b-0"
                  disabled={this.props.button}
                  value="Create Account"
                />
              </Form>
              <div className="text-center authentication__info">
                <span>Already have an account?</span>
                <Link to={"/login"} className="link">
                  {" "}
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  change: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default registerContainer(Register);
