import {CREATE_DEPOSIT_SUCCESS, CREATE_DEPOSIT_FAILURE} from '../../api/deposit_service/api.create.deposit';

export function createDeposits(state = {
  isRequested: false,
  isSuccess: false,
  hasError: false
}, action) {
  switch (action.type) {
    case CREATE_DEPOSIT_SUCCESS:
      return Object.assign({}, state, {
        isRequested: false,
        isSuccess: true,
        hasError: false,
        data: action.data
      })

    case CREATE_DEPOSIT_FAILURE:
      return Object.assign({}, state, {
        isRequested: false,
        isSuccess: false,
        hasError: true,
        errorMessage: action.err
      })

    default:
      return state;

  }
}