import api from '../config/api.js';
import {actions as toastrActions} from 'react-redux-toastr'
//data.url
//data.method
//data.body
//data.errorActionName
//data.successActionName
//data.showErrorMessage --> boolean-field
//data.showSuccessMessage --> boolean-field
//data.successMessage-->{}
//data.errorMessage-->{}
//

// let formData= new FormData();
// formData.append('file', data.body.file);
// formData.append('activated', data.body.active);
// formData.append('product_id', data.body.type);

export function getFileApi(data) {
  return  dispatch => {
    let url = `${api.apiUrl}${api.version}/${data.url}`
    let option = {
      method: `${data.method}`,
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem('access_token')
      },
    }
    if (data.method!=='GET' || data.method!=='get') {
      option.body = data.body
    }
    return fetch(url, option, data.body)
      .then(response => response.json().then(dataReceived=>({dataReceived, response})))
      .then(({dataReceived, response})=>{

        if (!response.ok) {
          if (response.status===401) {
            window.localStorage.clear();
            window.location.reload();
          }
          dataReceived.status = response.status
          return Promise.reject(dataReceived)
        }
        if(data.isProfilePictureUpdate){
          window.localStorage.setItem('avatar_url', dataReceived.data.user.image.url);
        }
        dispatch({type:data.successActionName, response:dataReceived});
        if (data.showSuccessMessage) {
          dispatch(toastrActions.add({
            type:data.successMessage.type,
            message:data.successMessage.message,
            title:data.successMessage.title,
            options:{showCloseButton:true},
            timeOut:4000
          }))
        }
      })
      .catch(err => {
        dispatch({type:data.errorActionName, response:err})
        if (data.showErrorMessage) {
          let message = typeof err.errors.message!==undefined?err.errors.message:data.errorMessage.message
          dispatch(toastrActions.add({
            type:data.errorMessage.type,
            title:`${data.errorMessage.title}`,
            message:message,
            options:{showCloseButton:true},
            timeOut:4000
          }))
        }
      })
  }
}
