import React from 'react';
import Logo from './../../../../assets/images/airtime_logo.png';

class StatementTemplate extends React.Component {

  render() {
    return (
      <div class="container l-invoice">
        <div class="invoice">
          <div class="row">
            <div class="col-6 ">
              <div class="invoice__logo">
                <img src={Logo} alt="TimeSlick logo" className="img-fluid "/>
              </div>
              <address>
                <div class="text-uppercase">
                  <strong>DIGITAL TRAVERSE TECHNOLOGIES CC</strong><br/>
                  P.O.BOX 5820<br/>
                  WINDHOEK<br/>
                  NAMIBIA <br/>
                </div>
                brandthdl@digitaltraverse.com
              </address>
              <br/>
            </div>
            <div class="col-6">
              <h1 class="document-type display-4 text-uppercase text-right">Statement</h1>
              <p class="text-right">#410</p>
              <br/>
              <div class="pull-right">
                <address>
                  <div class="text-muted">Bill To:</div>
                  <div class="text-uppercase">
                    <strong>SIMONDEUM INVESTMENTS (PTY) LTD</strong><br/>
                    T/A SIMONDEUM FINE MEATS<br/>
                    P.O. BOX 235<br/>
                    GROOTFONTEIN <br/>
                    NAMIBIA
                  </div>
                </address>
                <div class="invoice__info">
                  <div class="invoice__label text-muted">
                    Statement Date:
                  </div>
                  <div class="invoice__detail">
                    Jan 9, 2018
                  </div>
                </div>
                <div class="invoice__info">
                  <div class="invoice__label text-muted">
                    Period Date:
                  </div>
                  <div class="invoice__detail">
                    Jan 30, 2018
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <table class="table table-striped statement">
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Feb 10, 2018</td>
                <td>Deposit</td>
                <td>500</td>
                <td class="">500</td>
              </tr>
              <tr>
                <td> Feb 10, 2018</td>
                <td>Invoice</td>
                <td>(150)</td>
                <td class="">350</td>
              </tr>              
              <tr>
                <td> Feb 10, 2018</td>
                <td>Credit Note</td>
                <td>80</td>
                <td class="">430</td>
              </tr>

            </tbody>
            <tfoot>
              <td></td>
              <td></td>
              <td></td>
              <td><strong>430</strong></td>
            </tfoot>
          </table>
          <br/>
          <br/>
          <br/>
          <p class="invoice__notes">
            <span class="text-muted"> Notes: </span>
            <br/>
            HB DIGITAL TRAVERSE CC
            <br/>
            BANK WINDHOEK
            <br/>
            481-972
            <br/>
            8004332780
            <br/><br/>
            Please use your Invoice Number as your payment reference.
          </p>
          
          <br/>
          <br/>
          <br/>
          <br/>
          
          <p class="bottom-page">
            <div class="text-muted"> Terms:</div>
             Delivery will take place by means of electronic medium(s). Risk of loss transfers to the buyer upon delivery of
             goods (confirmed or unconfirmed) and irrespective of validity of the voucher(s) (in respect whereof the seller
             provides no guarantees, warranties or assurances of whatsoever nature). No disputes will be entertained under any
             circumstances and the purchaser shall have no claim whatsoever against the seller in respect of the
             aforementioned. The purchaser, by paying this invoice (irrespective of means) agrees to and accepts the terms
             herein embodied, and further agrees to be bound thereby. Strictly EFT Payments only.
          </p>
        </div>
      </div>
    );
  }
}

export default StatementTemplate
