import React from 'react';
import {editContainer} from './editContainer';
import { CountryDropdown, } from 'react-country-region-selector';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormText,
  Label,
  Input
} from 'reactstrap';

class UpdateAccount extends React.Component {
  componentDidMount() {
    let element = document.getElementById('countryDropDown')
    element.classList.add('form-control')
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="">
              Update Account
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.props.submit}>
            <FormGroup>
              <Label htmlFor="company">Account Name</Label>
              <Input
                type="text"
                onChange={this.props.change}
                value={this.props.account_details}
                name="account_details"
                required={true}/>
            </FormGroup>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="postal_address">Postal Address</Label>
                  <div className="input-prepend input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">P.O.Box</span>
                    </div>
                    <Input
                      required={true}
                      type="text"
                      steps="1"
                      min="0"
                      name="postal_address"
                      pattern= "\d*"
                      onChange={this.props.change}
                      value={this.props.postal_address}
                      title="Postal address should only contain numbers. e.g. 11002"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="account_email">Email Address</Label>
                  <Input
                    type="email"
                    onChange={this.props.change}
                    name="account_email"
                    value={this.props.account_email}
                    required={true}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="city">City</Label>
                  <Input
                    type="text"
                    onChange={this.props.change}
                    name="city"
                    value={this.props.city}
                    required={true}/>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="country">Country</Label>
                  <CountryDropdown
                    type="text"
                    id="countryDropDown"
                    onChange={this.props.change}
                    name="country"
                    value={this.props.country}
                    required={true}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="account_mobile_number">Mobile Number</Label>
                  <Input
                    type="text"
                    onChange={this.props.change}
                    name="account_mobile_number"
                    value={this.props.account_mobile_number}
                    required={true}/>
                    <FormText color="muted">
                    Make sure mobile number only contains digits.
                    </FormText>
                </FormGroup>
              </Col>
            </Row>
            <hr/>
            <FormGroup className="clearfix mt-4">
              <div className="pull-right">
                <div className='mr-2 btn btn-default' onClick={()=>window.history.back()} >Cancel</div>
                <Button type="submit" color="primary">Save Change</Button>
              </div>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default editContainer(UpdateAccount)