import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table
} from 'reactstrap';


class ConfirmOrder extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader>
          <div className="d-flex heading">
            <h5 className="">Confirm Order</h5>
          </div>
        </CardHeader>
        <CardBody>
          <Table hover>
            <thead>
              <tr>
                <th>Delivery Type</th>
                <th>Name/Email</th>
                <th>Voucher Type</th>
                <th>QTY</th>
                <th>Last-Expired Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Email Delivery</td>
                <td>example@gmail.com</td>
                <td>Tango N$ 10</td>
                <td>4</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Direct Recharge</td>
                <td>Neha_9879790097</td>
                <td>Tango N$ 10</td>
                <td>4</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Direct Recharge</td>
                <td>kristen_9879790097</td>
                <td>AwehGo</td>
                <td>4</td>
                <td>Jan 10 - Feb 8</td>
              </tr>
              <tr>
                <td>Email Delivery</td>
                <td>example@gmail.com</td>
                <td>Tango N$ 10</td>
                <td>4</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>
          <hr/>
          <div className="d-flex justify-content-end">
              <Button type="submit" color="warning" className="mr-2">Edit Order</Button>
              <Button type="submit" color="primary">Confirm Proceed</Button>
            </div>
        </CardBody>
      </Card>
    );
  }
}

export default ConfirmOrder