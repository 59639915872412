
export function sync(state = {
    loading: true
}, action) {
    switch (action.type) {
      case 'UPDATE_HEADER':    
         return Object.assign({}, state, {
         shouldUpdate:true
      })
      default: return state;

    }
}