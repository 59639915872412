import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select'
import {withRouter} from 'react-router-dom';

class SelectAccountModal extends Component {
  state={
    value:''
  }

  handleChange=(selectedData)=>{
    this.setState({
      value:selectedData.value
    })
    window.localStorage.setItem('aid', selectedData.value)
    window.localStorage.setItem('accountName', selectedData.label)
  }

  handleSubmit=(e)=>{
    e.preventDefault()
    this.props.history.push({pathname:'/order/new', state:{type:this.props.productName}})
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
          <ModalHeader>Select Account</ModalHeader>
          <ModalBody>
          {this.props.options.length!==0?
            <Select
              name="form-field-name"
              value={window.localStorage.getItem('aid')}
              onChange={this.handleChange}
              options={this.props.options}
              clearable={false}
              searchable={false}
              required={true}
            />:<p>There are currently no accounts.</p>}
          </ModalBody>
          <ModalFooter>
          {this.props.options.length===0 && 
            <Button onClick={()=>{this.props.history.push('/account/new')}} color="primary">Add Account</Button>
          }
          <Button color="default" onClick={this.props.toggle}>Cancel</Button>
          {this.props.options.length!==0 && 
            <Button onClick={this.handleSubmit} color="primary">Proceed</Button>
          }
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

export default withRouter(SelectAccountModal);