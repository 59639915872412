import React from 'react';
import {Form, Input, Label, FormGroup} from 'reactstrap';
import {resetPasswordContainer} from './resetPasswordContainer'

class Resetpassword extends React.Component {
  render() {
    return (
      <div className="authentication_bg">
        <div className="container authentication ">
          <h3 className="text-center authentication__title">
            Create New password
            <small className="text-center authentication__sub-title">You can add your password here.</small>
          </h3>
          <div className="d-flex justify-content-center ">
            <div className="authentication__wrapper ">
            {/* {this.props.error=!false?<p className="alert alert-danger">{this.props.error}</p>:null} */}
              <Form onSubmit={this.props.submit} className="form-group">
                <FormGroup>
                  <Label for="password">New password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    required
                    pattern=".{8,}"
                    onChange={this.props.change}/>
                </FormGroup>
                <FormGroup>
                  <Label for="password">
                    Confirm Password</Label>
                  <Input
                    type="password"
                    name="rePassword"
                    id="rePassword"
                    placeholder="Enter your password again"
                    required
                    pattern=".{8,}"
                    onChange={this.props.change}/>
                </FormGroup>
                <button type="submit" className="btn btn-primary btn-block" disabled={this.props.disabled}>Submit password</button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default resetPasswordContainer(Resetpassword)
