import React, { useState } from "react";
import { Label, FormGroup, Form } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

const SearchModules = props => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  function handleSubmit(e) {
    e.preventDefault();
    props.searchQuery();
    return;
  }

  function handleStartDateChange(date) {
    setStartDate(date);
    props.updateStartDate(date);
  }
  function handleEndDateChange(date) {
    setEndDate(date);
    props.updateEndDate(date);
  }

  return (
    <React.Fragment>
      <Form inline>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0 ml-1">
          <Label for="exampleEmail" className="mr-sm-2">
            From
          </Label>
          <DatePicker
            className="form-control "
            value={moment()}
            selected={startDate}
            maxDate={moment()}
            dateFormat="YYYY/MM/DD"
            onChange={data => handleStartDateChange(data)}
          />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0 ml-1">
          <Label for="examplePassword" className="mr-sm-2">
            To
          </Label>
          <DatePicker
            className="form-control"
            value={endDate}
            selected={endDate}
            maxDate={moment()}
            dateFormat="YYYY/MM/DD"
            onChange={data => handleEndDateChange(data)}
          />
        </FormGroup>
      </Form>
      <button onClick={handleSubmit} className="btn btn-primary ml-2">
        Search
      </button>
    </React.Fragment>
  );
};

export default SearchModules;
