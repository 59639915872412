import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'
import {forgetPassword} from '../../../api/auth/forgotPassword';

export function forgetPasswordContainer(WarpperComponent) {

  class RegisterConfirm extends Component {
    constructor(props) {
      super(props)
      this.state = {
        email: '',
        message: null,
        request:false,
      }
    }

    /**
     * @description onChange is triggered after component is mount to set state.
     */
    componentDidMount() {}
    onChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    /**
     * @description handle onsubmit action
     */
    onSubmit = (e) => {
      this.setState({request:true})
      let data = {
        email: this.state.email
      }
      e.preventDefault()
      if (this.state.email !== '') {
        this
          .props
          .forgetPassword(data)
      }
    }

    /**
     * 
     * @param {forget, isForgerSuccess} nextProps forget password api response watcher 
     */
    componentWillReceiveProps(nextProps) {
      if (nextProps.forget.isForgetSuccess === true && this.state.request===true) {
       this.setState({request: false})
        // this.props.addNotification({
        //   id:'Forget Something',
        //   title:'Reset link has been sent to your email',
        //   type:'success',
        //   message:nextProps.forget.data,
        //   timeOut:4000,
        //   options: { showCloseButton:true, removeOnHover:true }
        // })
        this.props.history.push('/');
      }
    }

    render() {
      return (
        <div>
          <WarpperComponent
            change={this.onChange}
            submit={this.onSubmit}
            {...this.state}/>
        </div>
      );
    }
  }

  /**
   * 
   * @param {addNotification} dispatch flash message. Used in component will recive props 
   */
  function mapDispatchToProps(dispatch) {
    let addNotification = toastrActions.add;
    return bindActionCreators({
      forgetPassword,
      addNotification,
    }, dispatch)
  }

  function mapStateToProps(state) {
    return {forget: state.forgetPassword}
  }
  return connect(mapStateToProps,mapDispatchToProps)(RegisterConfirm)
}
