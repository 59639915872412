import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';
import {createDeposit} from '../../../../api/deposit_service/api.create.deposit';
import moment from 'moment';
import Loader from '../../loading/loader';

export function editDepositContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        depositID: '',
        depositType: 'instant recharge',
        depositAmount: '',
        deposit_date: '2017-12-15',
        buttonDisable: false,
        data: moment()
      }
    }

    componentWillMount() {
      this
        .props
        .fetchAccounts()
    }

    componentDidMount() {
      if (typeof this.props.showAccount.isSuccess !== undefined && this.props.showAccount.isSuccess === true) {
        this.setState({loading: false});
      }
    }

    componentWillReceiveProps(nextProps) {
      if (typeof nextProps.showAccount.isSuccess !== undefined && nextProps.showAccount.isSuccess === true) 
        this.setState({loading: false});
      
      if (typeof nextProps.createDeposits.isSuccess !== undefined && nextProps.createDeposits.isSuccess === true) {
        alert('deposit success')
        this.setState({buttonDisable: false})
      }

    }

    createDeposit = (e) => {
      e.preventDefault()
      var data = {
        deposit: {
          deposit_type: this.state.depositType,
          amount: this.state.depositAmount,
          deposit_date: this.state.deposit_date
        }
      }
      this.setState({buttonDisable: true})
      this
        .props
        .createDeposit(this.state.depositID, data)

    }

    handleDate = (date) => {}

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    render() {
      return (
        <div>
          {(!this.state.loading)
            ? <Component
                id={this.props.showAccount.data}
                deposit={this.createDeposit}
                {...this.state}
                change={this.handleChange}
                {...this.state}/>
            : <div className="loader"><Loader height={80} width={80}/></div>}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {showAccount: state.showAccount, createDeposits: state.createDeposits}
  }

  return connect(mapStateToProps, {fetchAccounts, createDeposit})(Container)
}