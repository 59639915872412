/* eslint-disable */
import axios from "axios";
import api  from "../config/api";

/** Axios instant are bumping with defaut configuration. */
axios.defaults.baseURL = `${api.apiUrl}/api/v1/`; // "http://18.222.166.225/api/v1";
axios.defaults.headers.post["Content-Type"] = "application/json";

/** Interceptiong this request in development mode. */
if (process.env.NODE_ENV === "development") {
  axios.interceptors.request.use(request => {
    console.info("Starting Request", request);
    return request;
  });

  axios.interceptors.response.use(response => {
    console.info("Response:", response);
    return response;
  });
}

function handleToken(token) {
  if (token) {
    return {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token
      }
    };
  } else {
    return {
      headers: {
        accept: "application/json"
      }
    };
  }
}

async function get(url, token) {
  return await axios.get(url, handleToken(token)).catch(e => e.response);
}

async function post(url, body, token) {
  return await axios.post(url, body, handleToken(token)).catch(e => e.response);
}

async function put(url, body, token) {
  return await axios.put(url, body, handleToken(token)).catch(e => e.response);
}

async function remove(url, token) {
  return await axios.delete(url, handleToken(token)).catch(e => e.response);
}

export { get, post, put, remove };
