import React from "react";
import { createOrderContainer } from "./newOrderContainer";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Form,
  Col,
  Label,
  Row,
  FormGroup
} from "reactstrap";
import moment from "moment";
import ScheduleModal from "./popupScheduleModal";
import Select from "react-select";
import Discount from "./discount";
import Mobile from "../addMobileNumber/index";
import { formatAmount } from "../../common/formater.js";

class OrderNew extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      delivery_type: "Direct Recharge",
      delivery_name: "",
      delivery_person: "",
      voucher_type: "",
      voucher_quantity: "1",
      expire_date: "",
      date: moment(),
      modal: false,
      modalForm: null,
      lastDate: "",
      popoverOpen: false
    };
  }

  toggle = () => {
    this.setState({
      modal: false
    });
  };

  togglePopover = flag => {
    this.setState({
      popoverOpen: flag
    });
  };

  selectCurrentNumber = data => {
    // let shouldUpdateProps = true;
    // this.props.numberList.map(key=>{
    //   if( ( key.value == data.value || key.label== data.label) || data.value.substring(1, 3) !== '081' )
    //   { shouldUpdateProps=false } })
    // if(shouldUpdateProps){
    //   this.props.numberList[this.props.numberList.length] = data
    //   this.setState({
    //     delivery_name:data.value
    //   })
    // }
  };

  componentWillMount() {
    this.setState({
      voucher_type: this.props.product[0].value,
      email: this.props.accountEmail,
      isButtonToolTipsOpen: this.props.order.length
    });

    if (!!this.props.location.state) {
      let { type } = this.props.location.state;
      this.handleProductChange({ label: type, value: type });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleProductChange = selectedOption => {
    this.setState({ voucher_type: selectedOption.value });
  };

  handleMobilenoChange = selectedOption => {
    this.setState({
      delivery_name: selectedOption.value,
      lastDate: selectedOption.date,
      delivery_person: selectedOption.delivery_person
    });
  };

  onDirectSubmit = data => {
    this.setState({ modal: false });
    this.props.addOrder({
      delivery_type: this.state.delivery_type,
      delivery_name: this.state.delivery_name,
      delivery_person: this.state.delivery_person,
      voucher_type: this.state.voucher_type,
      voucher_quantity: this.state.voucher_quantity,
      expire_date: this.state.last_data,
      last_date: moment().add(this.state.voucher_quantity * 7, "days"),
      scheduled_dates: data
    });
  };

  submit = e => {
    e.preventDefault();
    if (this.state.delivery_type === "Email Delivery") {
      this.props.addOrder({
        delivery_type: this.state.delivery_type,
        delivery_name: this.props.accountEmail,
        delivery_person: this.state.delivery_person,
        voucher_type: this.state.voucher_type,
        voucher_quantity: this.state.voucher_quantity,
        expire_date: this.state.last_data,
        last_date: moment().add(this.state.voucher_quantity * 7, "days")
      });
    } else {
      this.setState({ modal: true });
    }
  };

  handleDate = date => {
    this.setState({ date: date });
  };

  orderList = () => {
    var i = -1;
    let data = this.props.order.map(key => {
      i++;
      function handleSchedule(data) {
        return data.map(key => {
          return <span>{key}</span>;
        });
      }
      return (
        <div className="order__item">
          <Row className="order__item-info">
            <Col xs="12" sm="6" md="2">
              <strong>{key.delivery_person}</strong>
            </Col>
            <Col xs="12" sm="6" md="2">
              {key.delivery_name === "Email Delivery"
                ? window.localStorage.getItem("emailUsed")
                : key.delivery_name}
            </Col>
            <Col xs="12" sm="6" md="2">
              {key.voucher_type}
            </Col>
            <Col xs="12" sm="6" md="1">
              {key.voucher_quantity}
            </Col>
            <Col xs="12" sm="6" md="4">
              {key.delivery_type === "Direct Recharge" ? (
                <div className="order__schedule-date">
                  <div className="order__schedule-dates">
                    {handleSchedule(key.scheduled_dates)}
                  </div>
                </div>
              ) : null}
            </Col>
            <Col xs="12" sm="6" md="1">
              <span className="action-icon">
                <i
                  id={i}
                  onClick={() => {
                    this.props.removeOrder(key);
                  }}
                  className="icon-trash"
                />
              </span>
            </Col>
          </Row>
        </div>
      );
    });
    return data;
  };

  render() {
    let orderList = this.orderList();
    const numberList = (
      <Select
        required={true}
        name="delivery_name"
        value={this.state.delivery_name}
        onChange={this.handleMobilenoChange}
        options={this.props.numberList}
        clearable={false}
      />
    );
    const email = (
      <Input type="text" disabled Selected value={this.state.email} />
    );
    let initialDisableSubmit = this.props.order.length === 0 ? true : false;
    return (
      <Card>
        <Form>
          <Form onSubmit={this.submit}>
            <CardHeader>
              <div className="d-flex heading">
                <h5 className="">Order for {this.props.accountName}</h5>
                <h5 className="order__avilable-fund ml-auto">
                  Available Credit:
                  <strong className="text-success mr-2">
                    {" "}
                    N$ {((this.props.credit * 1.0) / 100).toFixed(2)}
                  </strong>
                  Available Funds:
                  <strong className="text-success">
                    {" "}
                    N$ {formatAmount(this.props.amount)}
                  </strong>
                </h5>
              </div>
            </CardHeader>
            <CardBody>
              <Row className="order__new-form">
                <Col xs="12" sm="6" md="2">
                  <FormGroup>
                    <Label htmlFor="deliverytype">Delivery Type</Label>
                    <Input
                      type="select"
                      name="delivery_type"
                      required
                      onChange={this.handleChange}
                    >
                      <option value="Direct Recharge">Direct Recharge</option>
                      <option value="Email Delivery">Email Delivery</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="6" sm="6" md="3">
                  <FormGroup>
                    <Label htmlFor="delivery_name">Name/Email</Label>
                    {this.state.delivery_type === "Direct Recharge"
                      ? numberList
                      : email}
                    {this.state.delivery_type === "Direct Recharge" ? (
                      <small
                        href={""}
                        id="Popover2"
                        onClick={this.togglePopover}
                        className="mobileformat_msg"
                      >
                        + Add new name and number
                      </small>
                    ) : (
                      <small>&nbsp;</small>
                    )}
                    <Mobile
                      toggle={this.togglePopover}
                      isOpen={this.state.popoverOpen}
                      selectCurrentNumber={this.selectCurrentNumber}
                      {...this.props}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="2">
                  <FormGroup>
                    <Label htmlFor="voucher_type">Voucher Type</Label>
                    <Select
                      required={true}
                      name="voucher_type"
                      value={this.state.voucher_type}
                      onChange={this.handleProductChange}
                      options={this.props.product}
                      clearable={false}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="2">
                  <FormGroup>
                    <Label htmlFor="qty">Qty</Label>
                    <Input
                      type="number"
                      required
                      min="1"
                      max={
                        this.state.delivery_type === "Email Delivery"
                          ? "999"
                          : "50"
                      }
                      name="voucher_quantity"
                      id="quantity"
                      value={this.state.voucher_quantity}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="2">
                  <FormGroup>
                    <div className="order__action-btn">
                      <Button color="primary" className="mr-2">
                        {" "}
                        Add Items{" "}
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="orderdetail__heading">
                <Row>
                  <Col xs="2">Delivery Name</Col>
                  <Col xs="2">Mobile/Email </Col>
                  <Col xs="2">Voucher Type</Col>
                  <Col xs="1">QTY</Col>
                  <Col xs="5">Scheduled date/s:</Col>
                </Row>
              </div>
              <div className="order__items">{orderList}</div>
              <div className="clearfix order__bottom">
                {this.props.totalPrice !== 0 ? (
                  <div className="order__amount-charge clearfix">
                    <Discount
                      totalAmount={this.props.totalPrice}
                      getDiscountId={this.props.getDiscountId}
                    />
                  </div>
                ) : null}
                <Button
                  type="submit"
                  id="DisableSubmit"
                  color="success"
                  disabled={this.props.saveDraftRequest || initialDisableSubmit}
                  className="mr-2 pull-left"
                  onClick={this.props.saveDraft}
                >
                  Save as Draft
                </Button>
                <div className="pull-right">
                  <Button
                    color="default"
                    className="mr-2"
                    onClick={() => window.history.back()}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={"primary"}
                    id={this.props.order.length === 0 ? "DisableSubmit" : ""}
                    disabled={
                      this.props.proceedOrderRequest || initialDisableSubmit
                    }
                    className="btn btn-primary"
                    onClick={this.props.proceedOrder}
                  >
                    Process Order
                  </Button>
                </div>
              </div>
            </CardBody>
          </Form>
        </Form>

        <ScheduleModal
          modal={this.state.modal}
          toggle={this.toggle}
          voucher_quantity={this.state.voucher_quantity}
          voucher_type={this.state.voucher_type}
          submit={this.onDirectSubmit}
          date={this.state.lastDate}
        />
      </Card>
    );
  }
}

export default createOrderContainer(OrderNew);
