
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, FormGroup, Label } from 'reactstrap';
import {connect} from 'react-redux';
import {getApi} from '../../../../api/api'
class ModalMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       email:'',
       body:'Please find the attached invoice.',
       subject:'TimeSlick Invoice',
       aid:'',
       inoviceId:''
    }
  }

  componentDidMount(){
    this.setState({
      aid:this.props.data.account_id,
      inoviceId:this.props.data.id
  })
  }

  handleChange=(e)=>{
    this.setState({
      [e.target.name]:e.target.value
    })
   }

  sendEmail=()=>{
    this.props.getApi({
      url:`accounts/${this.state.aid}/invoices/${this.state.inoviceId}`,
      body:{invoice:{
                     account_id:this.state.aid,
                     invoice_id:this.state.inoviceId,
                     email:this.state.email,
                     body:this.state.body,
                     subject:this.state.subject
                   }
           },
     method:'post',
     errorActionName:'INVOICE_EMAIL_FAIL',
     successActionName:'INVOICE_EMAIL_SUCCESS',
     showSuccessMessage:true,
     showErrorMessage:true,
     successMessage:{
                      type:'success',
                       message:"Email has been send successfully",
                       title:'Successful',
                     }, 
     errorMessage:{
                     type:'error',
                   }       
    })   
  
    this.props.toggle()
  
  }


  render() {
    return (
      <div>
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal_email" >
          <ModalHeader toggle={this.props.toggle}>New Mail</ModalHeader>
          <ModalBody>
            <Form >
              <FormGroup>
                <Label for="email_to">To:</Label>
                <Input type="text" name="email" className="modal_email__to" value={this.state.email} onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup className="">
                <Label for="email_subject">Subject:</Label>
                <Input type="text" name="subject" className="modal_email__subject" value={this.state.subject} onChange={this.handleChange}/>
              </FormGroup>
              <FormGroup className="">
                <Input type="textarea" name="body" className="modal_email__content" placeholder="Say Something" value={this.state.body} onChange={this.handleChange} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <span className="modal_email__filename">
              Attached Invoice:
              <span>
                <i className="fa fa-file-pdf-o mr-1"></i>invoice.pdf
              </span>
            </span>
            <Button color="default" onClick={this.props.toggle}>Cancel</Button>
            <Button color="primary" onClick={()=>{this.sendEmail();}}>Send</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps, {getApi})(ModalMail)