// ADD_MOBILENO_SUCCESS
// ADD_MOBILENO_FAIL
// REMOVE_MOBILENO_SUCCESS
// REMOVE_MOBILENO_FAIL
// EDIT_MOBILENO_SUCCESS
// EDIT_MOBILENO_FAIL
// LIST_MOBILENO_SUCCESS
// LIST_MOBILENO_FAIL

export const ADD_MOBILENO_SUCCESS    =   "ADD_MOBILENO_SUCCESS"
export const ADD_MOBILENO_FAIL       =   "ADD_MOBILENO_FAIL"
export const REMOVE_MOBILENO_SUCCESS =   "REMOVE_MOBILENO_SUCCESS"
export const REMOVE_MOBILENO_FAIL    =   "REMOVE_MOBILENO_FAIL"
export const EDIT_MOBILENO_SUCCESS   =   "EDIT_MOBILENO_SUCCESS"
export const EDIT_MOBILENO_FAIL      =   "EDIT_MOBILENO_FAIL"
export const LIST_MOBILENO_SUCCESS   =   "LIST_MOBILENO_SUCCESS"
export const LIST_MOBILENO_FAIL      =   "LIST_MOBILENO_FAIL"
export const UPDATE_MOBILENO_SUCCESS   =   "UPDATE_MOBILENO_SUCCESS"
export const UPDATE_MOBILENO_FAIL      =   "UPDATE_MOBILENO_FAIL"