import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Loader from '../../loading/loader';
import {createAccount} from '../../../../api/account_service/api.create.accounts';
import {showProfile} from '../../../../api/user_profile/profile.show'
import {actions as toastrActions} from 'react-redux-toastr';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';



export function newContainer(AddressNew) {
  class RegisterContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        account_details: '',
        postal_address: 'P.O.Box ',
        city: '',
        country: 'Namibia',
        account_email: '',
        account_mobile_number: '',
        first_name:'',
        last_name:'',
        createRequest:false,
        accountCount:''
      }
    }

    selectCountry =(val)=>{
      this.setState({ country: val });
    }
    
    selectRegion =(val)=> {
      this.setState({ region: val });
    } 


  
    componentWillMount() {
      this.props.showProfile(localStorage.getItem('user_id'));
      this.props.fetchAccounts()
    }

    handleChange = (e) => {
      e.preventDefault();
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    handleSubmit = (event) => {
      this.setState({createRequest:true})
      event.preventDefault()
      let user = {
        account: {
          account_details: this.state.account_details,
          postal_address: `P.O.Box ${this.state.postal_address}`,
          city: this.state.city,
          country: this.state.country,
          account_email: this.state.account_email,
          account_mobile_number: this.state.account_mobile_number
        }
      }
      this
        .props
        .createAccount(user);
    }

    initialEntity=(data)=>{
      this.setState({account_details:data})
    }

    componentWillReceiveProps(nextProps) {

      if (typeof nextProps.show.showSuccess !== undefined && nextProps.show.showSuccess === true) {
        let user = nextProps.show.showData
        this.setState({
          loading: false,
          account_email: user.email,
          account_mobile_number: user.mobile_number,
          first_name:user.first_name,
          last_name:user.last_name
        })
      }
      
      if (typeof nextProps.showAccount.isSuccess !== undefined && nextProps.showAccount.isSuccess === true) {
        this.setState({loading: false, accountCount:nextProps.showAccount.data.length})
        
      }

      if (nextProps.createBill.isSuccess === true && this.state.createRequest===true) {
        let id = nextProps.createBill.data.data.account.id;
        console.log(nextProps.createBill.data.data.account);
        
       this.setState({createRequest:false})
        this.props.notification({
          title: 'Account has been created successfully',
          type: 'success',
          options:{showCloseButton:true, removeOnHover:true}
        }); 
        const {history} = this.props
        history.push({ pathname:`/account/show/${id}`, state:{showAddMobileNoMessage:true}})
      }
      
    }

    render() {
      return (
        <div>
          {this.state.loading
          ?<div className="loader"><Loader height={80} width={80}/></div>
          :<AddressNew change={this.handleChange} submit={this.handleSubmit}
          initialEntity={this.initialEntity}
          {...this.state}
          selectCountry={this.selectCountry}
          selectRegion={this.selectRegion}/>}
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    let notification = toastrActions.add;
    return bindActionCreators({
      createAccount,
      showProfile,
      fetchAccounts,
      notification
    }, dispatch)
  }

  function mapStateToProps(state) {
    return {
      createBill: state.createAccount,
      show: state.showprofile,
      showAccount: state.showAccount
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)

}
