
export function creditNote(state = {
  isSuccess:false
}, action) {

  switch (action.type) {
    case 'GET_CREDIT_NOTE_SUCCESS':
      return Object.assign({}, state, {
        isSuccess:true,
        data:action.response
      })

    default:
      return state;
  }

}

export function promotions(state={
  isFetching:true,
  vatFetch:false
}, action){

switch (action.type){
  case 'FETCH_PROMOTION_SUCCESS':
   return Object.assign({}, state, {
    fetchSuccess:true,
     data:action.response
   })

   case 'FETCH_BANNERS_SUCCESS':
   return Object.assign({}, state, {
    fetchBannerSuccess:true,
     data:action.response
   })

  case 'FETCH_VAT_SUCCESS':
  return Object.assign({}, state, {
    vatFetch:true,
    vatDetail:action.response
  })
   
   
default:
 return state;   
}



}