import {SHOW_PRODUCT_SUCCESS, SHOW_PRODUCT_FAILURE} from '../../api/product/api.index.product';

export function indexProducts(state = {
    isInit: true,
    isSuccess: false,

}, action) {
  switch (action.type) {
    case SHOW_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        isRequested: false,
        isSuccess: true,
        fetchByGroupSuccess:false,
        hasError: false,
        data: action.data
      })

    case SHOW_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        isRequested: false,
        isSuccess: false,
        hasError: true,
        errorMessage: action.err
      })

    case 'GET_PRODUCT_BY_GROUP_SUCCESS':
      return Object.assign({}, state, {
        fetchByGroupSuccess:true,
        data: action.response
      })  
    
    case 'GET_PRODUCT_BY_GROUP_FAIL':
      return Object.assign({}, state, {
        fetchByGroupError:true,
        data: action.response
      })   

    default:
      return state;

  }
}