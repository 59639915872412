/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
export const SHOW_DEPOSIT_REQUEST = 'SHOW_ACCOUNT_REQUEST'
export const SHOW_DEPOSIT_SUCCESS = 'SHOW_DEPOSIT_SUCCESS'
export const SHOW_DEPOSIT_FAILURE = 'SHOW_DEPOSIT_FAILURE'

export function showDeposit(id) {
  return dispatch => {
    if (!id) {return dispatch(depositShowError())}
    const token = localStorage.getItem('access_token')
    //dispatch(requestShowDeposit())
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}/deposits`
    let option={
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
    }
    return fetch(url, option)
    .then(response => response.json().then(showDeposit=>({showDeposit, response})))
    .then(({showDeposit, response})=>{

     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      showDeposit.status = response.status
       return Promise.reject(showDeposit)
         } else {
         dispatch(receiveShowDeposit(showDeposit.data))
    }})
    .catch(err => dispatch(depositShowError(err)))
  }
}



function receiveShowDeposit(data) {
  return {
    type: SHOW_DEPOSIT_SUCCESS,
    data
  }
}

function depositShowError(err) {
  return {
    type: SHOW_DEPOSIT_FAILURE,
    err
  }
}



