import {
		ADD_MOBILENO_SUCCESS,
		ADD_MOBILENO_FAIL,
		REMOVE_MOBILENO_SUCCESS,
		REMOVE_MOBILENO_FAIL,
		EDIT_MOBILENO_SUCCESS,
		EDIT_MOBILENO_FAIL,
		LIST_MOBILENO_SUCCESS,
		LIST_MOBILENO_FAIL,
		UPDATE_MOBILENO_SUCCESS,
		UPDATE_MOBILENO_FAIL
} from '../../action/mobile_no'

export function mobileno(state = {
		loader: true,
		addSuccess:false,
		addError:false,
		listSuccess: false,
		listError: false,
		removeSuccess:false,
		removeError:false

}, action) {

		switch (action.type) {

				case ADD_MOBILENO_SUCCESS:
						return Object.assign({}, state, {
								loader: false,
								addSuccess: true,
								adderror: false,
								addData: action.createData,
								addErrorData: null
						})

				case ADD_MOBILENO_FAIL:
						return Object.assign({}, state, {
							  loader: false,
								addSuccess: false,
								addError: true,
								addData: null,
								addErrorData: action.createError
						})

				case REMOVE_MOBILENO_SUCCESS:
						return Object.assign({}, state, {
						  	loader: false,
								removeSuccess: true,
								removeError: false,
								removeData: action.deleteData,
								removeErrorData: null
						})

				case REMOVE_MOBILENO_FAIL:
						return Object.assign({}, state, {
						  	loader: false,
								removeSuccess: false,
								removeData: null,
								removeError: true,
								removeErrorData: action.removeErrorData
						})

				case EDIT_MOBILENO_SUCCESS:
						return Object.assign({}, state, {
							 loader: false,
								editSuccess: true,
								editError: false,
								editData: action.editData,
								editErrorData: null
						})

				case EDIT_MOBILENO_FAIL:
						return Object.assign({}, state, {
   							loader: false,
								editSuccess: false,
								editError: false,
								editData: null,
								editErrorData: action.editError
						})

				case LIST_MOBILENO_SUCCESS:
						return Object.assign({}, state, {
  							loader: false,
								listSuccess: true,
								listError: false,
								listData: action.listData,
								listErrorData: null
						})

				case LIST_MOBILENO_FAIL:
 						return Object.assign({}, state, {
  							loader: false,
 								listSuccess: false,
								listError: true,
								listData: null,
								listErrorData: action.listError
						})
						
						case UPDATE_MOBILENO_SUCCESS:
						return Object.assign({}, state, {
  							loader: false,
								updateSuccess: true,
								updateError: false,
								updateData: action.updateData,
								updateErrorData: null
						})

				case UPDATE_MOBILENO_FAIL:
 						return Object.assign({}, state, {
  							loader: false,
 								updateSuccess: false,
								updateError: true,
								updateData: null,
								updateErrorData: action.updateError
						})

				default:
						return state;
		}

}