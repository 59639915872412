/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import { UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL } from '../../action/profile'
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'



export function updateProfile(id, data) {
    return dispatch => {
      const token = localStorage.getItem('access_token')
      let url = `${api.apiUrl}${api.version}/users/${id}`
      let option={
        method: 'PUT',
        headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+token
                },
        body:JSON.stringify(data)         
      }
      return fetch(url, option)
      .then(response => response.json().then(updateProfile=>({updateProfile, response})))
      .then(({updateProfile, response})=>{
       if (!response.ok) {
        if(response.status===401){
          window.localStorage.clear();
          window.location.reload();
        } 
        updateProfile.status = response.status
         return Promise.reject(updateProfile)
           } else {
            dispatch(toastrActions.add({
            type:'success',
            title:`Update successful.`,
            message:'Your profile has been updated successfully.',
            options:{showCloseButton:true,removeOnHover:true},
            timeOut:4000
          }))
           dispatch(receiveupdateProfile(updateProfile.data))
           dispatch({type:'UPDATE_HEADER'})
      }})
      .catch(err => dispatch(updateProfileError(err)))
    }
  }
  
  
  function receiveupdateProfile(data) {
    return {
      type: UPDATE_PROFILE_SUCCESS,
      data
    }
  }
  
  function updateProfileError(err) {
    return {
      type: UPDATE_PROFILE_FAIL,
      err
    }
  }
  