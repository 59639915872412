import React from 'react';
import {createDepositContainer} from './createDepositContainer'
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';


class AddressNew extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="">
              Create Deposit for {this.props.accountName}
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.props.deposit}>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="postal-address">Deposit Type</Label>
                  <Input
                    type="select"
                    name="depositType"
                    id="deposit_type"
                    required={true}
                    onChange={this.props.change}>
                    <option value="">Select Deposit Type</option>
                    <option value="Cash">Cash</option>
                    <option value="Electronic Funds Transfer">Electronic Funds Transfer</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="postal-address">Amount (N$)</Label>
                  <Input
                    type="number"
                    step="0.1"
                    max="10000000"
                    name="depositAmount"
                    placeholder="Enter your amount"
                    onChange={this.props.change}
                    required={true}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="postal-address">Select the Deposit Date</Label>
                  <DatePicker
                    className="form-control"
                    name="deposit_date"
                    selected={this.props.date}
                    onChange={this.props.data}
                    value={this.props.date.format('YYYY/MM/DD')}/>
                </FormGroup>
              </Col>
            </Row>
            <hr/>
            <FormGroup className="clearfix mt-4">
              <div className="pull-right">
                <div className='mr-2 btn btn-default' onClick={()=>window.history.back()}>Cancel</div>
                  <Button
                    type="submit"
                    color="primary"
                    >
                    Create Deposit</Button>
              </div>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default createDepositContainer(AddressNew);
