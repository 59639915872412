import React, { Component } from 'react';
import {CardHeader, Button} from 'reactstrap';
import Select from 'react-select';

class Header extends Component {
  toggle=()=>{
    this.props.handleEdit({showForm:true, isEditing:false, editingData:[]})
  }
  render() {
    return (
      <CardHeader>
      <div className="d-flex heading">
        <h5 className="">Recharge Numbers for</h5>
        <Select
          className="heading__entity"
          name="recharge_number"
          value={this.props.accountID}
          onChange={this.props.handleAccountChange}
          options={this.props.accounts}
          clearable={false}
          searchable={false}
        />
        <Button className="ml-auto" color='primary' onClick={this.toggle}>Add Recharge Number</Button>
      </div>
    </CardHeader>
    );
  }
}

export default Header;
