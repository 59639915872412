/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api";
import { SHOW_INVOICE_SUCCESS, SHOW_INVOICE_FAIL} from '../../action/invoice';

export function showInvoice(id, data) {
  return dispatch => { 
    if(!id) {return dispatch({type:'No-type.'})}
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}/invoices`
    let option={
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)         
    }
    return fetch(url, option)
    .then(response => response.json().then(data=>({data, response})))
    .then(({data, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      data.status = response.status
       return Promise.reject(data)
         } else {
         dispatch(receiveShowInvoice(data))
    }})
    .catch(err => dispatch(showInvoiceError(err)))
  }
}



function receiveShowInvoice(data) {
  return {
    type: SHOW_INVOICE_SUCCESS,
    showData:data
  }
}

function showInvoiceError(err) {
  return {
    type: SHOW_INVOICE_FAIL,
    showErrorData:err
  }
}



