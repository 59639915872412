import React from "react";
import { Link } from "react-router-dom";
class ThankYou extends React.Component {
  render() {
    return (
      <div className="thankyou">
        <div className="text-center">
          {this.props.location.state.type === "quote" ? (
            <i className="fa fa-times-circle" style={{ color: "red" }} />
          ) : (
            <>
              <i className="fa fa-check-circle" />
              <h2>Thank you !!!</h2>
            </>
          )}

          {this.props.location.state.type === "quote" ? (
            <p>
              You have insufficient funds for this order. A quote has been sent
              to <strong>{this.props.location.state.email}</strong>.
            </p>
          ) : (
            <p>
              Your order has been placed successfully. An invoice has been sent
              at <strong>{this.props.location.state.email}</strong>.{" "}
            </p>
          )}
          <p> Please check your mail for more information about this order.</p>
          <Link to="/orders" className="btn btn-outline-primary">
            Back to Orders
          </Link>
        </div>
      </div>
    );
  }
}
export default ThankYou;
