import moment from 'moment'

import { getVoucherType } from './getVoucherType'
import { getFormatedDate } from './getFormatedDate'

export function calculateStartDate (
  numberOfOrderItems,
  voucherType,
  lastRechargedDate,
) {
  const referenceStartDate = lastRechargedDate
    ? moment(lastRechargedDate)
    : moment()
  const voucher = getVoucherType(voucherType)
  const dates = numberOfOrderItems
    .map((_, orderCount) => {
      if (voucher.isTango) {
        return moment()
      } else if (voucher.isAweh) {
        const daysApart = 8 * orderCount
        return moment(referenceStartDate).add(daysApart, 'days')
      } else if (voucher.isAweh30) {
        const daysApart = 31 * orderCount
        return moment(referenceStartDate).add(daysApart, 'days')
      }
      // JUST IN CASE: if all else fails, set the date to today
      return moment()
    })
    .map(date => getFormatedDate(date))
  return dates
}
