import React, { Component } from 'react';
import { Row, Col, Label, Input, Button } from 'reactstrap'

class AddMobileNumber extends Component {
  state = {
    name:'',
    mobile:'',
    isEditing:''
  }

  componentDidMount() {
    if(this.props.numbers.length==0){
      this.props.accounts.map(key=>{
        if(key.value==this.props.accountID){
          this.setState({
           name:key.label,
           mobile:key.number,
          })
        }
      })
   } else {
    this.setState({
      name:this.props.editingData.associated_name,
      mobile:this.props.editingData.mobile_number,
      isEditing:this.props.isEditing
    })
  }
}


  handleChange = ({target}) => {
    this.setState({
      [target.name]:target.value
    })
  }

  clearForm = () => {
    let name = document.getElementById("addMobileNumberField-Name");
    let mobile = document.getElementById("addMobileNumberField-Mobile");
    this.setState({
      name:'',
      mobile:'',
      isEditing:''
    })
    name.value=""
    mobile.value=""
  }

  addMobileNumber=(data)=>{
    this.props.addMobileNo(this.props.accountID, data)
    .then(()=>{this.clearForm()})
    .then(()=>this.props.listMobileno(this.props.accountID))
  }

  editMobileNumber=(data)=>{
    this.props.updateMobileNo(this.props.accountID,this.props.editingData.id ,data)
      .then(()=>{this.clearForm()})
      .then(()=>{this.props.handleEdit({showForm:false, isEditing:false, editingData:[]})})
      .then(()=>this.props.listMobileno(this.props.accountID))
  }

  handleSubmit=(e)=>{
    e.preventDefault();
    let data = {number: {associated_name:this.state.name, mobile_number:this.state.mobile}}
    if(this.state.isEditing) {
      this.editMobileNumber(data)
    } else {
      this.addMobileNumber(data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isEditing && nextProps.isEditing!==this.state.isEditing) {
      this.setState({
        name:nextProps.editingData.associated_name,
        mobile:nextProps.editingData.mobile_number,
        isEditing:nextProps.isEditing
      })    
    } 

    if (!nextProps.isEditing) {
      this.setState({
        name:'',
        mobile:'',
        isEditing:nextProps.isEditing
      })    
    }
  }

  toggle=()=>{
    this.props.toggleShowForm(false)
  }
  render() {
    return (
      <div className="mobile__number-list ">
        <form onSubmit={this.handleSubmit}>
          <div className="m-3">
            <div className="">
              <Row className="show pt-3 pb-3">
                <Col xs="4">
                  <Label for="Name" hidden>Name</Label>
                  <Input type="text" id="addMobileNumberField-Name" autoFocus required name="name" onChange={this.handleChange} placeholder="Enter Name" value={this.state.name} />
                </Col>
                <Col xs="4">
                  <Label for="mobileNumber" hidden>MobileNumber</Label>
                  <Input required type="text" id="addMobileNumberField-Mobile" name="mobile" onChange={this.handleChange} placeholder="Enter Mobile Number" value={this.state.mobile} />
                  <small>Mobile number format: 081#######</small>
                </Col>
                <Col xs="4">
                  <Button className="btn-success btn mr-2" >{this.state.isEditing?'Update':'Save'}</Button>
                  <Button color="default" onClick={this.toggle}>Cancel</Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
  export default AddMobileNumber
