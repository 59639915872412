import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';
import Loader from '../../loading/loader';
import {showInvoice} from '../../../../api/invoice_service/api.show.invoice'
import {getApi} from '../../../../api/api'
export function invoiceContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        iloading: true,
        accountLoading:true,
        invoices:null,
        accountId: window.localStorage.getItem('aid'),
        accountName:window.localStorage.getItem('accountName')
      }
    }

    componentDidMount() {
  
      this.props.fetchAccounts()
      this.props.showInvoice(this.state.accountId)
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.showAccount.isSuccess === true && this.props.showAccount!==nextProps.showAccount) {
        this.setState({
          accountLoading: false,
        });
    }

    if(nextProps.invoiceList.invoiceShowSuccess===true){
      this.setState({
        invoices:nextProps.invoiceList,
        iloading: false,
      })
    }
  }


    onchange = (selectedOption) => {
      window.localStorage.setItem('aid', selectedOption.value)
      window.localStorage.setItem('accountName', selectedOption.label)
      this.setState({
        accountId: selectedOption.value,
        accountName:selectedOption.label
      })
      this.props.showInvoice(selectedOption.value)
    }

    sendEmail=(data)=>{
      data &&
      this.props.getApi({
        url:`accounts/${this.state.accountId}/invoices/${data.id}`,
        body:{invoice:{
          account_id:this.state.accountId,
          invoice_id:data.id,
        }
      },
       method:'post',
       errorActionName:'INVOICE_EMAIL_FAIL',
       successActionName:'INVOICE_EMAIL_SUCCESS',
       showSuccessMessage:true,
       showErrorMessage:true,
       successMessage:{
                        type:'success',
                         message:"Email has been send successfully",
                         title:'Successful',
                       }, 
       errorMessage:{
                       type:'error',
                     }       
      })
    }


    render() {
      return (
        <div>
          {(!this.state.accountLoading)
            ? <Component
                id={this.props.showAccount.data}
                change={this.onchange}
                sendEmail={this.sendEmail}
                {...this.state}/>
            : <div className="loader"><Loader height={80} width={80}/></div>}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {showAccount: state.showAccount, 
      invoiceList: state.invoice}
  }

  return connect(mapStateToProps, {fetchAccounts, showInvoice, getApi})(Container)
}