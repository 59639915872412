/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";

import { ITEMS_ORDER_SUCCESS, ITEMS_ORDER_FAIL } from '../../action/order'

export function orderItems(aid, oid) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}/accounts/${aid}/orders/${oid}`
    let option={
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
    }
    return fetch(url, option)
    .then(response => response.json().then(orderItems=>({orderItems, response})))
    .then(({orderItems, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      orderItems.status = response.status
       return Promise.reject(orderItems)
         } else {
         dispatch(receiveItemsInOrder(orderItems))
    }})
    .catch(err => dispatch(itemsInOrdererror(err)))
  }
}



function receiveItemsInOrder(data) {
  return {
    type: ITEMS_ORDER_SUCCESS,
    orderItems:data
  }
}

function itemsInOrdererror(err) {
  return {
    type: ITEMS_ORDER_FAIL,
    orderItemsError:err
  }
}




