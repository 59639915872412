import {
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  SHOW_ORDER_SUCCESS,
  SHOW_ORDER_FAIL,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  ITEMS_ORDER_SUCCESS,
  ITEMS_ORDER_FAIL 
} from './../../action/order';

export function order(state = {
  fetch: true,
  createSuccess: false,
  createError: false,
  showSuccess:false,
  showError:false,
  itemsSuccess:false,
  itemsError:false
}, action) {

  switch (action.type) {
    case CREATE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        createSuccess: true,
        createError: false,
        createData: action.createData,
        createErrorData: null
      })

    case CREATE_ORDER_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        createSuccess: false,
        createError: true,
        createData: null,
        createErrorData: action.createError
      })

    case SHOW_ORDER_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        showSuccess: true,
        showError: false,
        showData: action.showData,
        showErrorData: null
      })

    case SHOW_ORDER_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        showSuccess: false,
        showData: null,
        showError: true,
        showErrorData: action.showError
      })

    case UPDATE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        updateSuccess: true,
        updateError: false,
        updateData: action.updateData,
        updateErrorData: null
      })

    case UPDATE_ORDER_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        updateSuccess: false,
        updateError: true,
        updateData: null,
        updateErrorData: action.updateError
      })

    case LIST_ORDER_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        listSuccess: true,
        listerror: false,
        listData: action.listData,
        listErrorData: null
      })

    case LIST_ORDER_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        listSuccess: false,
        listerror: true,
        listData: null,
        listErrorData: action.listError
      })

      case ITEMS_ORDER_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        itemsSuccess: true,
        itemsError: false,
        itemsData: action.orderItems,
        itemsErrorData: null
      })

    case ITEMS_ORDER_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        itemsSuccess: false,
        itemsError: true,
        itemsData: null,
        itemsErrorData: action.orderItemsError
      })

    default:
      return state;
  }

}