import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import {
  BootstrapTable,
  TableHeaderColumn,
  SearchField
} from "react-bootstrap-table";
import { container } from "./container";
import ModalMail from "./mail";
import Select from "react-select";
import EmptyMessageCard from "../IdSelector/emptyMessageCard";

class CreditNote extends React.Component {
  constructor() {
    super();
    this.state = {
      invoiceNumber: "",
      modal: false
    };
    this.id = "";
  }

  handleChange = event => {
    this.setState({
      invoiceNumber: event.target.value
    });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  mail = e => {
    this.props.getApi({
      url: `credit_notes/${e.id}/send_email`,
      method: "post",
      body: {
        credit_note: {
          email: this.state.email,
          body: this.state.body,
          subject: this.state.subject
        }
      },
      successActionName: "POST_CREDIT_EMAIL_SUCCESS",
      errorActionName: "POST_CREDIT_EMAIL_SUCCESS",
      showErrorMessage: true,
      errorMessage: {
        type: "error",
        title: "Could not send email."
      },
      showSuccessMessage: true,
      successMessage: {
        type: "success",
        title: "Credit note has been send."
      }
    });
  };

  handleCreateCreditNote = event => {
    event.preventDefault();
    this.props.history.push(`/creditnote/${this.state.invoiceNumber}`);
  };

  viewFormat = (a, b) => {
    return (
      <span>
        <a
          href={b.pdf_url}
          target="_blank"
          className="action-icon"
          title="Download pdf"
        >
          <i className="fa fa-file-pdf-o" />{" "}
        </a>
        {/* To enable email feature unlock this code */}
        {/* <a onClick={()=>{this.mail(b)}} className="action-icon" title="Mail"><i className='fa fa-envelope-o' ></i> </a> */}
        {/* To enable email feature unlock this code */}
      </span>
    );
  };
  createCustomSearchField = props => {
    return (
      <SearchField
        className="my-custom-class"
        defaultValue="2000"
        placeholder="Input a number"
      />
    );
  };
  entity = () => {
    var list = Object.keys(this.props.id).map(key => {
      let data = this.props.id[key];
      return { value: data.id, label: data.account_details };
    });
    return list;
  };
  render() {
    let list = this.entity();
    let selected = {
      value: this.props.accountId,
      label: this.props.accountName
    };
    return (
      <div>
        <ModalMail modal={this.state.modal} id={this.id} toggle={this.toggle} />
        {this.props.id.length === 0 ? (
          <EmptyMessageCard />
        ) : (
          <Card className="creditnote-items">
            <CardHeader>
              <div className="d-flex heading">
                <h5 className="">Credit Notes for</h5>
                <Select
                  className="heading__entity"
                  name="entity"
                  value={selected}
                  onChange={this.props.onchange}
                  options={list}
                  clearable={false}
                  searchable={false}
                />
              </div>
            </CardHeader>
            <CardBody>
              <BootstrapTable
                pagination
                data={this.props.creditNotesList}
                hover
                search
                version="4"
                bordered={false}
              >
                <TableHeaderColumn isKey dataField="id">
                  Credit Note No
                </TableHeaderColumn>
                <TableHeaderColumn dataField="credit_note_invoice_number">
                  Invoice No
                </TableHeaderColumn>
                <TableHeaderColumn dataField="credited_user_name">
                  User Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="account_name" dataSort={true}>
                  Account
                </TableHeaderColumn>
                <TableHeaderColumn dataField="credit_note_date">
                  Created Date
                </TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.viewFormat} className="asa">
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default container(CreditNote);
