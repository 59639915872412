import React from 'react';
import {Input} from 'reactstrap';
import {connect} from "react-redux"
import {action} from '../../../api/sync'
import { getFileApi } from '../../../api/fileApi'
import fallBackProfileImage from '../../../assets/images/avatars/user.png'

class UploadImage extends React.Component {
constructor(){
  super();
  this.state={
    file:'',
    avatar_url:''
  }
  this.file = ''
}  

componentDidMount() {
  this.setState({
    file:this.props.imageUrl?this.props.imageUrl:fallBackProfileImage
  })
}

handleDelete=()=>{
  let file = document.getElementById('upload')
  file.value=""
  this.file=""
  this.setState({
    file:fallBackProfileImage
  })
  window.localStorage.setItem("avatar_url", fallBackProfileImage)
 this.upload(); 
}

handleChange=({target})=>{
     let file= target.files[0];
     this.file = file;
     var reader = new FileReader();
     reader.readAsDataURL(file);
     reader.onloadend = function (e) {
      this.setState({
          file: [reader.result]
      })
    }.bind(this);
   this.upload() 
  }

upload() {
    let file = this.file
    let promotion = new FormData();      
    promotion.append('user[image]', file,);
    this.props.getFileApi({
      url:`users/${this.props.id}/profile_update`,
      method:'PUT',
      body:promotion,
      successActionName:'UPDATE_PROFILE_SUCCESS',
      errorActionName:'UPDATE_PROFILE_FAIL',
      showSuccessMessage:true,
      isProfilePictureUpdate:true,
      successMessage:{
        title:'Updated successfully.',
        type:'success',
        message:'Profile picture has been update.'
      },
      errorMessage:{
        title:'Error Uploading profile picture.',
        type:'error'
      }
    })
    .then (()=>{
      this.props.action()
    })
 }
  render() {
    return (
      <div>
        <div className="file-upload mt-4">
          <div className="imageupload">
            <div className="imageupload__image ">
              <img src={this.state.file} className="" alt="new-profile"/>
            </div>
            <span className="imageupload__cancel" onClick={this.handleDelete}> <i className="fa fa-times"> </i></span>
          </div>
          <label for="upload" className="file-upload__label btn-default btn-sm btn mt-3">
            <i className="fa fa-cloud-upload"></i> Browse image</label>
          <Input required id="upload" onChange={this.handleChange} className="file-upload__input" type="file" name="file-upload"/> 
        </div>
      </div>
    );
  }
}


export default connect(null, {getFileApi, action})(UploadImage)