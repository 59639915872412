import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../api/account_service/api.show.accounts';
import {getApi} from '../../../api/api'
import Loader from '../loading/loader';

export function deliveryContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dloading: true,
        accountLoading:true,
        deliveriesList:null,
        accountId: window.localStorage.getItem('aid'),
        accountName:window.localStorage.getItem('accountName'),
        togglePopOver:false,
        popOverId:'',
        delivery:'',
      }
      
    }

  toggleReschedule=(delivery)=>{
    this.setState({
      togglePopOver:true,
      delivery:delivery,
      popOverId:delivery.id
    })
  }

  handleResendEmail=(data)=>{
    this.props.getApi({
      url:`deliveries/${data.id}/resend_email`,
      method:'POST',
      body: {},
      successActionName:' ',
      errorActionName:' ',
      showErrorMessage:true,
      errorMessage:{
        type:'error',
        title:'Could not resend email right now.'
      },
      back:true,
      showSuccessMessage:true,
      successMessage:{
        type:'success',
        title:'Email has been sent.'
      }
    })
  }

  toggleDatePicker=()=>{
    this.setState({
      togglePopOver:false,
      delivery:'',
      popOverId:''
  })
  }

    componentDidMount() {
      this.props.fetchAccounts()
      this.state.accountId && ( this.props.getApi({
        url:`accounts/${this.state.accountId}/deliveries`,
        method:'get',
        errorActionName:'DELIVERIES_LOAD_FAIL',
        successActionName:'DELIVERIES_LOAD_SUCCESS'
      }))
      if(window.localStorage.getItem('aid')===null){
        this.setState({
          dloading:false
        })
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.showAccount.isSuccess === true && this.props.showAccount!==nextProps.showAccount) {
        this.setState({
          accountLoading: false,
        });  
    }

    if(nextProps.deliveries.listSuccess===true){
      this.setState({
        deliveriesList:nextProps.deliveries.listData,
        dloading: false,
      })
    }
  }


    onchange = (selectedOption) => {
      window.localStorage.setItem('aid', selectedOption.value)
      window.localStorage.setItem('accountName', selectedOption.label)
      this.setState({
        accountId: selectedOption.value,
        accountName:selectedOption.label
      })
      this.props.getApi({
        url:`accounts/${selectedOption.value}/deliveries`,
        method:'get',
        errorActionName:'DELIVERIES_LOAD_FAIL',
        successActionName:'DELIVERIES_LOAD_SUCCESS'
      })
    }


    render() {
      return (
        <div>
          {(!this.state.accountLoading && !this.state.dloading)
            ? <Component
                id={this.props.showAccount.data}
                change={this.onchange}
                toggleReschedule={this.toggleReschedule}
                handleResendEmail={this.handleResendEmail}
                toggleDatePicker={this.toggleDatePicker}
                {...this.state} {...this.props}/>
            : <div className="loader"><Loader height={80} width={80}/></div>}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      showAccount: state.showAccount, 
      deliveries:state.deliveries 
    }
  }

  return connect(mapStateToProps, {fetchAccounts, getApi})(Container)
}