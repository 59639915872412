/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import { SHOW_PROFILE_SUCCESS, SHOW_PROFILE_FAIL } from '../../action/profile'
import api from "../../config/api.js";

export function showProfile(id) {
    return dispatch => {
      const token = localStorage.getItem('access_token')
      let url = `${api.apiUrl}${api.version}/users/${window.localStorage.getItem('user_id')}`
      let option={
        method: 'GET',
        headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+token
                }
      }
      return fetch(url, option)
      .then(response => response.json().then(showProfile=>({showProfile, response})))
      .then(({showProfile, response})=>{
       if (!response.ok) {
        if(response.status===401){
          window.localStorage.clear();
          window.location.reload();
        }
         showProfile.status = response.status
         return Promise.reject(showProfile)
           } else {
           dispatch(receiveShowProfile(showProfile.data))
      }})
      .catch(err => dispatch(showProfileError(err)))
    }
  }
  
  
  function receiveShowProfile(data) {
   return {
      type:SHOW_PROFILE_SUCCESS,
      data
    }
  }
  
  function showProfileError(err) {
    return {
      type:SHOW_PROFILE_FAIL,
      err
    }
  }