/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import { LIST_MOBILENO_SUCCESS, LIST_MOBILENO_FAIL } from '../../action/mobile_no';
export function listMobileno(id) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}/numbers`
    let option={
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
      // body:JSON.stringify(data)         
    }
    return fetch(url, option)
    .then(response => response.json().then(listMobileno=>({listMobileno, response})))
    .then(({listMobileno, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      listMobileno.status = response.status
       return Promise.reject(listMobileno)
         } else {
         dispatch(receiveListMobileno(listMobileno.data))
    }})
    .catch(err => dispatch(listMobilenoError(err)))
  }
}


/**
 * @description below are function which dispatch action
 * they can fire the action with the help of redux thunk.
 */


function receiveListMobileno(data) {
  return {
    type: LIST_MOBILENO_SUCCESS,
    listData:data
  }
}

function listMobilenoError(err) {
  return {
    type: LIST_MOBILENO_FAIL,
    listError:err
  }
}



