import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';
import {showDeposit} from '../../../../api/deposit_service/api.show.deposit';
import {deleteDeposit} from '../../../../api/deposit_service/api.delete.deposit'
import Loader from '../../loading/loader';


export function showDepositContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        depositLoading:true,
        depositList: null,
        accountId: window.localStorage.getItem('aid'),
        accountName:window.localStorage.getItem('accountName'),
        initdeposit:'',
        deleteRequest:false
      }
    }

    componentWillMount() {
      this.props.fetchAccounts()
      if(this.state.accountId) {
      this.props.showDeposit(window.localStorage.getItem('aid'))
      }
    }

    handleDeleteDeposit=(e)=>{
      let depositsID = e
      let billID = this.state.accountId
      this.setState({
        deleteRequest:true
      })
      this.props.deleteDeposit(billID, depositsID)
    }

  componentWillReceiveProps(nextProps){
   if(typeof nextProps.showAccount.isSuccess!==undefined
    && nextProps.showAccount.isSuccess===true) {
   this.setState({
    loading: false,
   });
   }

   if(nextProps.deposit.deleteSuccess === true && this.state.deleteRequest){
     this.setState({
      deleteRequest:false
     })
    this.props.showDeposit(this.state.accountId)
   }

    if(nextProps.showDeposits.isSuccess===true ) {
      this.setState({depositList:nextProps.showDeposits.data, depositLoading:false})

      //   Object.keys(nextProps.showDeposits.data).map(key=>{
      //    var data = nextProps.showDeposits.data[key]

      //   return(
      //     <tr key={key}>
      //     <td>{data.deposited_date}</td>
      //     <td>{data.deposit_type}</td>
      //     <td><strong>N$ {data.amount/100}</strong></td>
      //     <td><span className={`is-${data.deposit_status}`}>{data.deposit_status}</span></td>
      //     <td>
      //     {(data.deposit_status==="pending")?<Link to="#"
      //      onClick={this.handleDeleteDeposit.bind(this, data.id)}
      //      className="action-icon"><Tooltips icon={'icon-trash'} message={'Delete'} id={`delete${data.id}`}></Tooltips></Link>:null}
      //     </td>
      //   </tr>
      //    )
      // })P
    }
  }


 onchange=(selectedOption)=>{
  window.localStorage.setItem('aid', selectedOption.value)
  window.localStorage.setItem('accountName', selectedOption.label)
  this.setState({
    accountId: selectedOption.value,
    accountName:selectedOption.label
  })

  this.props.showDeposit(selectedOption.value)
 }

  render() {
    return (
      <div>
        {(!this.state.loading)?
          <Component id={this.props.showAccount.data}
          change={this.onchange}
          handleDeleteDeposit={this.handleDeleteDeposit} 
          deposits={this.state.depositList} 
          {...this.state}/>
        :<div className="loader"><Loader height={80} width={80}/></div>}
      </div>
    );
  }
}

 function mapStateToProps(state){
  return {
    showAccount:state.showAccount,
    showDeposits:state.showDeposits,
    deposit:state.deposit
  }
}

return connect(mapStateToProps,{fetchAccounts,showDeposit, deleteDeposit})(Container)
}
