import React from "react";

export default class Terms extends React.Component {
  componentDidMount = () => {
    window.location.href =
      "https://timeslick-static-assets-production.s3.amazonaws.com/terms/terms.pdf";
  };

  render() {
    return <div />;
  }
}
