import {
  CREATE_DEPOSITE_SUCCESS,
  CREATE_DEPOSIT_FAIL,
  SHOW_DEPOSITE_SUCCESS,
  SHOW_DEPOSIT_FAIL,
  UPDATE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT_FAIL,
  DELETE_DEPOSIT_SUCCESS,
  DELETE_DEPOSIT_FAIL
} from '../../action/deposit';

export function deposit(state = {
  fetch: true,
  deleteSuccess: false
}, action) {

  switch (action.type) {

    case CREATE_DEPOSITE_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        createSuccess: true,
        createError: false,
        createData: action.createData,
        createErrorData: null
      })

    case CREATE_DEPOSIT_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        createSuccess: false,
        createError: true,
        createData: null,
        createErrorData: action.createError
      })

    case SHOW_DEPOSITE_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        showSuccess: true,
        showError: false,
        showData: action.showData,
        showErrorData: null
      })

    case SHOW_DEPOSIT_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        showSuccess: false,
        showData: null,
        showError: true,
        showErrorData: action.showError
      })

    case UPDATE_DEPOSIT_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        updateSuccess: true,
        updateError: false,
        updateData: action.updateData,
        updateErrorData: null
      })

    case UPDATE_DEPOSIT_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        updateSuccess: false,
        updateError: true,
        updateData: null,
        updateErrorData: action.updateError
      })

    case DELETE_DEPOSIT_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        deleteSuccess: true,
        deleteError: false,
        deleteData: action.deleteData,
        deleteErrorData: null
      })

    case DELETE_DEPOSIT_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        deleteSuccess: false,
        deleteError: true,
        deleteData: null,
        deleteErrorData: action.deleteError
      })

    default:
      return state;
  }

}
