import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon
} from 'react-share';
class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      toggleClass: '',
      update:true
    }
  }

  componentDidMount() {
    let location = this.props.location.pathname.substr(1)
    let currentLink = document.getElementById(location)
    if (currentLink) {
      let dashboardLink = document.getElementById('dashboard')
      dashboardLink.classList.remove('active')
      currentLink.classList.add('active')
    }
  }

  handleClick=({target})=>{
  // Get the element with id="myDIV" (a div), then get all elements inside div with class="example"
      var x = document.getElementById("nav").querySelectorAll(".nav-link");
         x.forEach(key=>{
          key.classList.remove('active')
         })
       let currentList = document.getElementById(target.id)
       currentList.classList.add('active');
  }

  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav" id="nav">
            <li className="nav-item nav-dropdown " onClick={this.handleClick}>
              <Link
                to="/"
                id="dashboard"
                className="nav-link nav-dropdown-toggle active">
                <i className="fa fa-dashboard"></i>
                Dashboard
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link to="/accounts"
              id="accounts"
              className="nav-link">
              <i className="fa fa-address-book"></i>
              Accounts
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link
                to="/recharge_number"
                id="recharge_number"
                className="nav-link">
                <i className="fa fa-th-list"></i>
                Recharge Numbers
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link to="/deposits"
                id="deposits"
                className="nav-link">
                <i className="fa fa-credit-card"></i>
                Deposits
              </Link>
            </li>
            <li onClick={this.handleClick} className="nav-item nav-dropdown disabled" disabled={window.localStorage.getItem('aid')===null?true:false}>
              <Link
                to="/orders"
                id="orders"
                className="nav-link nav-dropdown-toggle">
                <i className="fa fa-paper-plane"></i>
                Orders
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link to="/invoices"
                id="invoices"
                className="nav-link">
                <i className="fa fa-file-text"></i>
                Invoices
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link to="/creditnote"
                id="creditnote"
                className="nav-link">
                <i className="fa fa-file-text"></i>
                Credit Notes
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link
                to="/deliveries"
                id="deliveries"
                className="nav-link">
                <i className="fa fa-cloud-download"></i>
                Deliveries
              </Link>
            </li>
            <li className="nav-item" onClick={this.handleClick}>
              <Link
                to="/statement"
                id="statement"
                className="nav-link">
                <i className="fa fa-th-list"></i>
                Statements
              </Link>
            </li>

            <li>
              <div className="bank-info">
                <h4> DIGITAL TRAVERSE TECHNOLOGIES CC</h4>
                <div className="bank-info__name">
                  <span> Bank name: </span>
                   BANK WINDHOEK
                </div>
                <div className="bank-info__branch">
                  <span> Branch Code:</span>
                  481-972
                </div>
                <div className="bank-info__account">
                  <span> Acc No:</span>
                  8004332780
                </div>
              </div>
            </li>
            {/*<li className="nav-item mt-auto p-3 social">
              {<p> Share to families and friends</p> }
              <ul className="list-unstyled d-flex justify-content-between">
                <li>
                  <div>
                    <FacebookShareButton
                      url={'http://web.digitaltraverse.com/'}
                      quote={'title'}
                      className="Demo__some-network__share-button">
                      <FacebookIcon
                        size={32}
                        round />
                    </FacebookShareButton>
                  </div>
                </li>
                <li >
                  <div>
                    <GooglePlusShareButton
                      url={'http://web.digitaltraverse.com/'}
                      quote={'title'}
                      className="Demo__some-network__share-button">
                      <GooglePlusIcon
                        size={32}
                        round />
                    </GooglePlusShareButton>
                  </div>
                </li>
                <li>
                  <div className="Demo__some-network">
                    <TwitterShareButton
                      url={'http://web.digitaltraverse.com/'}
                      quote={'title'}
                      className="Demo__some-network__share-button">
                      <TwitterIcon
                        size={32}
                        round />
                    </TwitterShareButton>
                  </div>
                </li>
              </ul>
            </li>*/}
          </ul>

        </nav>
      </div>
    )
  }
}

export default withRouter(Sidebar);
