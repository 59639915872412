import React, { Component } from 'react';
import {CardBody, CardBlock} from 'reactstrap'
import _ from 'lodash'

class EmptyItemsCard extends Component {
  render() {
    let location = _.capitalize(this.props.items)
    
    return (
      <div>
          <CardBody className="text-center">
          <CardBlock className="card-body">
            <blockquote className="card-bodyquote">
            <p>Please transfer funds using the banking details on the left.
              Then add the {this.props.items} using the Add {location} from the top-right corner.</p>
            </blockquote>
          </CardBlock>
        </CardBody>
      </div>
    );
  }
}

export default EmptyItemsCard;