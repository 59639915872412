import React from 'react';
import {connect} from 'react-redux';
import {registerUser} from '../../../api/auth/api.register';
import {bindActionCreators} from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'

/**
 * @description register component.
 */

export function registerContainer(Register) {
  class RegisterContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        mobileNumber: '',
        button: false
      }
    }

   capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  /**
   * @description check if user is login before loading.
   */
    componentWillMount() {
      if(this.props.auth.isAuthenticated){
        this.props.history.push('./dashboard')
      }
    }

    handleChange = (e) => {
      e.preventDefault();
      this.setState({
        [e.target.name]: e.target.value
      });

    }

    /**
     * @description register user
     */

    handleSubmit = (e) => {
      e.preventDefault();
      this.setState({button: true})
      let user = {
        user: {
          first_name: this.capitalize(this.state.firstName),
          last_name: this.capitalize(this.state.lastName),
          mobile_number: this.state.mobileNumber,
          email: this.state.email,
          password: this.state.password
        }
      }
      this.props.registerUser(user);

    }

    /**
     * 
     * @param {register} nextProps receive api data from redux. 
     * @param {isRegister, hasError} are flag to show success annd failure.
     */
    componentWillReceiveProps(nextProps) {
      if (nextProps.register.isRegister === true) {
        this.setState({button: false})
        this.props.add({
          id: '74378257fdshhjajhbvhj',
          title: `Dear ${nextProps.register.data.first_name}`,
          message: 'Please verify your email before continuing.',
          type: 'info',
          timeOut:10000,
          options:{showCloseButton:true, removeOnHover:true}
        })
        var {history} = this.props
        history.push('/')
      }

      if (nextProps.register.hasError === true) {
        this.setState({button: false})
        let error = nextProps.register.errorMessage.errors.message
        this
          .props
          .add({id: '89578376583478', title: 'There is an error.', message: ` ${error}`, type: 'error', options:{showCloseButton:true} ,progessBar: true})

      }

    }
    /**
     * @description show register view with container function.
     */
    render() {
      return (
        <div>
          <Register
            {...this.state}
            change={this.handleChange}
            submit={this.handleSubmit}/>
        </div>
      );
    }
  }

  /**
   * redux method.
   */

  function mapDispatchToProps(dispatch) {
    let add = toastrActions.add;
    return bindActionCreators({
      registerUser,
      add: add
    }, dispatch)
  }

  /**
   * @description redux method.
   */

  function mapStateToProps(state) {
    return {register: state.register, auth:state.auth}
  }

  return connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)
}