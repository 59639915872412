import {CONFIRM_SUCCESS, CONFIRM_FAILURE} from '../../api/auth/api.confirmRegister';

export function registerConfirm(state = {}, action) {
		switch (action.type) {

				case CONFIRM_SUCCESS:
						return Object.assign({}, state, {
								isRequested: false,
								isConfirm: true,
								hasError: false,
								data: action.res
						})

				case CONFIRM_FAILURE:
						return Object.assign({}, state, {
								isRequested: false,
								isConfirm: false,
								hasError: true,
								errorMessage: action.err
						})

				default:
						return state;

		}
}