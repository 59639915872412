import React from 'react';
import { connect } from 'react-redux';
import { updateAccountFromStore } from '../../../../api/account_service/api.update.accounts';
import { fetchAccounts } from '../../../../api/account_service/api.show.accounts';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr'
import Loader from '../../loading/loader';

export function editContainer(AddressNew) {
  class RegisterContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        account_details: '',
        postal_address: '',
        city: '',
        country: '',
        account_email: '',
        account_mobile_number: '',
        data: null,
        currentID: this.props.match.params.id,
        updateRequest: false,
        billLoad: false
      }
    }

    //handle change in form input element.
    handleChange = (e) => {
      e.preventDefault();
      this.setState({
        [e.target.name]: e.target.value
      });
      
    }

    //call api to save the change.
    handleSubmit = (e) => {
      e.preventDefault();
      this.setState({ updateRequest: true })
      let user = {
        account: {
          account_details: this.state.account_details,
          postal_address: `P.O.Box ${this.state.postal_address}`,
          city: this.state.city,
          country: this.state.country,
          account_email: this.state.account_email,
          account_mobile_number: this.state.account_mobile_number
        }
      }
      this.props.updateAccountFromStore(this.state.id, user);
    }

    // get a billing address list to show billing address.
    componentWillMount() {
      this.props.fetchAccounts()
    }


    componentWillReceiveProps(nextProps) {
      //filter current billing address from api data list.
      if (typeof nextProps.showAccount.isSuccess !== undefined && nextProps.showAccount.isSuccess === true) {
        nextProps.showAccount.data
          .map((key) => {   // eslint-disable-next-line
            if (this.state.currentID == key.id) {
              let postal_address = `${key.postal_address}`
              let trimedPostalAddress= postal_address.slice(7)
              
              this.setState({
                id: key.id,
                account_details: key.account_details,
                //eslint-disable-next-line
                postal_address: parseInt(trimedPostalAddress),
                city: key.city,
                country: key.country,
                account_email: key.account_email,
                account_mobile_number: key.account_mobile_number
              })
              this.setState({ billLoad: true })
            }
            return null;
          })
      }

      //if update was success show flash message and redirect.
      if (this.state.updateRequest && nextProps.updateBilling.updateSuccess) {
        this.setState({ updateRequest: false });
        let { history } = this.props;
        history.push('/accounts')
       }

    }

    // render view if billing address was loaded into state.  
    render() {
      return (
        <div>
          {(!this.state.billLoad)
            ? <div className="loader"><Loader height={80} width={80} /></div>
            : <AddressNew
              change={this.handleChange}
              submit={this.handleSubmit}
              {...this.state} />
          }
        </div>
      );
    }
  }

  //  
  function mapDispatchToProps(dispatch) {
    let notification = toastrActions.add;
    return bindActionCreators({
      updateAccountFromStore,
      notification,
      fetchAccounts
    }, dispatch)
  }

  function mapStateToProps(state) {
    return { createBill: state.createAccount, showAccount: state.showAccount, updateBilling: state.UpdateAccount }
  }

  return connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)
}
