/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import { CREATE_ORDER_SUCCESS, CREATE_ORDER_FAIL} from '../../action/order';
import {actions as toastrActions} from 'react-redux-toastr'


export function createOrder(id, data) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}/orders`
    let option={
      method: 'POST',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)
    }
    return fetch(url, option)
    .then(response => response.json().then(createOrder=>({createOrder, response})))
    .then(({createOrder, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      } 
      createOrder.status = response.status
       return Promise.reject(createOrder)
         } else {
         dispatch(receiveCreateOrder(createOrder))
    }})
    .catch(err => {
      dispatch(createOrdererror(err))
      dispatch(toastrActions.add({
        id:"hdfjka",
        title:"errors",
        type:"error",
        preventDuplicates:true,
        message:err.errors.message,
        options:{showCloseButton:true, removeOnHover:true}
      }))
     
    })
  }
}



function receiveCreateOrder(data) {
  return {
    type: CREATE_ORDER_SUCCESS,
    createData:data
  }
}

function createOrdererror(err) {
  return {
    type: CREATE_ORDER_FAIL,
    createErrorData:err
  }
}



