/**
 *@description refer src/api/api.js. same as that 
 */
import api from "../../config/api.js";
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS'
export const CONFIRM_FAILURE = 'CONFIRM_FAILURE'



export function confirmRegister(data, location){
  let tokenurl = `${api.apiUrl}${api.version}${api.resource.apiToken}`
  let url = `${api.apiUrl}${api.version}/${location}`
  return dispatch => {
  return fetch(tokenurl, {
      method: 'POST',
      Content:'text/plain',
      body: JSON.stringify({"application":{"application_name":"front-end", "password":"xS3g7j8EYefUE8wk"}})})
     .then(res => res.json())
     .then((res)=> {
            let option={
            method: 'POST',
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+res.data.token},
            body:JSON.stringify(data) }
        fetch(url, option)
        .then(response => response.json().then(user => ({ user, response })))
        .then(({ user, response }) =>  {
          if (!response.ok) {
          user.status=response.status;
          return Promise.reject(user)
            } else {
            dispatch(receiveRegisterConfirm(user.data.user))
            window.localStorage.clear()
          }
        }).catch(err => dispatch(registerConfirmError(err)))
      })
      .catch(err => dispatch(registerConfirmError(err)))
    
    }

  }
  




function receiveRegisterConfirm(res) {
  return {
    type: CONFIRM_SUCCESS,
    res
  }
}

function registerConfirmError(err) {
  return {
    type: CONFIRM_FAILURE,
    err
  }
}





