/* eslint-disable react/jsx-no-bind */
import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

/** Local Import */
import { showOrderContainer } from "./showOrderContainer";
import EmptyMessageCard from "../../IdSelector/emptyMessageCard";
import Tooltips from "../../common/tooltips";
import CreateLabelModal from "./createLabelModal";

class Orders extends React.Component {
  state = {
    showCreateLabelModal: false,
    selectedOrder: {}
  };

  toggleCreateLabelModal = (selectedOrder = {}) => {
    this.setState(state => ({
      showCreateLabelModal: !state.showCreateLabelModal,
      selectedOrder
    }));
  };
  entity = () => {
    const list = Object.keys(this.props.id).map(key => {
      const data = this.props.id[key];
      return { value: data.id, label: data.account_details };
    });
    return list;
  };

  formatStatus = (col, row) => {
    return <span className={`is-${row.order_status}`}>{row.order_status}</span>;
  };

  formatLabel = (col, row) => {
    if (col === null || col === "") {
      return (
        <span
          style={{
            opacity: "0.6",
            transform: "translateY(17px)",
            fontStyle: "italic"
          }}
        >
          Add label from actions.
        </span>
      );
    }
    return (
      <span
        style={{ fontSize: "12px", color: "#FFF" }}
        className="badge badge-pill badge-info"
      >
        {col.length > 30 ? `${col.slice(0, 30)}...` : col}
      </span>
    );
  };

  formatReorder = (col, row) => {
    return row.order_status === "processed" ? (
      <Button
        onClick={() => {
          this.props.handleReorder(row);
        }}
        className="btn btn-outline-primary btn-sm"
      >
        Reorder
      </Button>
    ) : null;
  };

  formatActions = (col, row) => (
    <div>
      <Link
        to="#"
        onClick={this.props.showOrderItems.bind(this, row)}
        className="action-icon"
      >
        <Tooltips
          icon={"icon-eye"}
          message={"View Detail"}
          id={`view${row.id}`}
        />
      </Link>
      {row.order_status !== "processed" ? (
        <Link
          to="#"
          onClick={this.props.editOrder.bind(this, row)}
          className="action-icon"
        >
          <Tooltips
            icon={"icon-pencil"}
            message={"Edit"}
            id={`edit${row.id}`}
          />
        </Link>
      ) : null}
      {row.order_status === "draft" ? (
        <Link
          to="#"
          onClick={this.props.deleteDraft.bind(this, row)}
          className="action-icon"
        >
          <Tooltips icon={"icon-trash"} message={"Edit"} id={`edit${row.id}`} />
        </Link>
      ) : null}
      {/* Added new action button */}
      <Link
        to="#"
        onClick={() => this.toggleCreateLabelModal(row)}
        className="action-icon"
      >
        <Tooltips
          icon={"icon-note"}
          message={"Create Label"}
          id={`view${row.id}`}
        />
      </Link>
      {/* Added new action button end here */}
    </div>
  );

  render() {
    const list = this.entity();
    const selected = {
      value: this.props.accountId,
      label: this.props.accountName
    };

    if (list.length === 0) {
      return <EmptyMessageCard />;
    }
    return (
      <div>
        <CreateLabelModal
          showCreateLabelModal={this.state.showCreateLabelModal}
          toggleCreateLabelModal={this.toggleCreateLabelModal}
          selectedOrder={this.state.selectedOrder}
          getApi={this.props.getApi}
          showOrder={this.props.showOrder}
          accountId={this.props.accountId}
        />
        <Card>
          <CardHeader>
            <div className="d-flex heading">
              <h5 className="">Orders for</h5>
              <Select
                className="heading__entity"
                name="entity"
                value={selected}
                onChange={this.props.change}
                options={list}
                clearable={false}
                searchable={false}
              />
              <Link to="/order/new" className="ml-auto btn btn-primary">
                Create Order
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <BootstrapTable
              pagination
              data={this.props.orders}
              version="4"
              bordered={false}
            >
              <TableHeaderColumn width={"90px"} isKey dataField="id">
                Order No
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={this.formatLabel}
                dataField="order_label"
              >
                Order Label
              </TableHeaderColumn>
              <TableHeaderColumn dataField="order_date">
                Order Date
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatStatus}>
                Status
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={this.formatReorder}
                className="asa"
              />
              <TableHeaderColumn
                dataFormat={this.formatActions}
                className="asa"
              >
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default showOrderContainer(Orders);
