import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";


import Header from './../../views/dashboard/header';
import Sidebar from './../../views/dashboard/sidebar';
import GlobalAlert from '../../views/common/userAlert';

import Accounts from './../../views/accounts/accounts';
import AddressNew from './../../views/accounts/new';
import AddressShow from './../../views/accounts/show';
import Deposits from './../../views/deposits/index';
import DepositsNew from './../../views/deposits/new';
import {dashboardContainer} from './dashboardContainer';
import Orders from './../../views/order/orders';
import Order from './../../views/order/show';
import OrderNew from './../../views/order/new';
import ReOrder from './../../views/order/reorder';
import EditOrder from '../../views/order/edit/index'
import ProfileSetting from './../../views/users/edit';
import Packages from './../../views/packages';
import Package from '../../views/packages/show'
import Invoices from '../../views/invoices/index';
import UpdateAccount from '../../views/accounts/edit'
import Passwordreset from './../../views/passwordreset';
import EditDeposit from '../../views/deposits/editDeposit/index';
import ConfirmOrder from '../../views/order/confirm_order';
import InvociceTemplate from '../../views/invoices/invoice_template';
import StatementTemplate from '../../views/statement/statement_template';
import ThankYou from './../../views/order/thankyou/';
import Deliveries from './../../views/deliveries';
import CreditNote from './../../views/creditnote';
import Statement from './../../views/statement/index'
import PromotionDetail from '../../views/packages/showPromotion'
import RechargeNumber from '../../views/rechargeNumber'

/**
 * @description Dashboard route.
 */
class Dashboard extends Component {  
  render() {
    return (
      <BrowserRouter basename="dashboard">
        <div className="app">
          <Header/>
          <div className="app-body">
            <Sidebar/>
            <main className="main">
              <div className="container-fluid">
                  <GlobalAlert setting={this.props.setting}/>
                  <Switch>
                  <Route exact path="/" component={Packages}/>
                  <Route exact path="/package/:id" component={Package}/>
                  <Route exact path="/accounts" component={Accounts}/>
                  <Route exact path="/account/new" component={AddressNew}/>
                  <Route exact path="/accounts/:id/edit" component={UpdateAccount}/>
                  <Route exact path="/account/show/:id" component={AddressShow}/>

                  <Route exact path="/recharge_number" component={RechargeNumber}/>
                    

                  <Route exact path="/deposits" component={Deposits}/>
                  <Route exact path="/orders" component={Orders}/>
                  <Route exact path="/invoices" component={Invoices}/>
                  <Route exact path="/deposits/edit" component={EditDeposit}/>
                  <Route exact path="/deposits/new" component={DepositsNew}/>
                  <Route exact path="/order/:aid/items/:oid" component={Order}/>
                  <Route exact path="/order/new" component={OrderNew}/>
                  <Route exact path="/order/reorder" component={ReOrder}/>
                  <Route exact path="/order/thankyou" component={ThankYou}/>
                  <Route exact path="/orders/edit/:id" component={EditOrder}/>
                  <Route exact path="/profile_setting" component={ProfileSetting}/>
                  <Route exact path="/passwordreset" component={Passwordreset}/>
                  <Route exact path="/confirm_order" component={ConfirmOrder}/>
                  <Route exact path="/invoice_template" component={InvociceTemplate}/>
                  <Route exact path="/deliveries" component={Deliveries}/>
                  <Route exact path="/creditnote" component={CreditNote}/>
                  <Route exact path="/promotion/detail" component={PromotionDetail}/>                  
                  <Route exact path="/statement" component={Statement}/>
                  <Route exact path="/statement_template" component={StatementTemplate}/>
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </BrowserRouter>
    )
  }
}


export default dashboardContainer(Dashboard);


