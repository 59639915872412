import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';
import { addMobileNo } from '../../../../api/entity_mobileno/api.create.mobileno';
import { listMobileno } from '../../../../api/entity_mobileno/api.show.mobileno';
import { deleteMobileno } from "../../../../api/entity_mobileno/api.delete.mobileno"
import {bindActionCreators} from 'redux';
import Loader from '../../loading/loader';
import {actions as toastrActions} from 'react-redux-toastr'
import { updateAccountFromStore } from '../../../../api/account_service/api.update.accounts';


export function showContainer(AddressNew) {
  class RegisterContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: '',
        billLoad: false,
        loadMobileList:false,
        currentID: '',
        mobileHolderName:'',
        mobileNumber:'',
        addMobileno:false,
        modal:false,
        deleteMobileNoID:'',
        showMobileNoAddMessage:false,        
      }
    }

    componentWillMount() {
      this.props.fetchAccounts()
      if(this.props.location.state!==undefined){
        this.setState({
          showMobileNoAddMessage:true
        })
      }
    }

    onDismissAddMobileMessage=()=>{
      this.setState({
        showMobileNoAddMessage:false
      })
    }

    componentDidMount(){
     this.setState({currentID:this.props.match.params.id});
     
    }


    componentWillReceiveProps(nextProps) {
      if (nextProps.showAccount.isSuccess === true) {
        nextProps.showAccount.data.map((key) => {
          // eslint-disable-next-line
            if (this.state.currentID==key.id) {
              this.setState({data: key, billLoad: true})
            }
            return null;
          })}
      
      if(nextProps.mobileno.removeSuccess===true && this.state.deleteInit===true){
        this.setState({deleteInit:false})
        this.props.notification({
          title:'Number has been deleted successfully',
          type:'success',
          options:{showButtonClose:true, removeOnHover:true},
        })
        this.props.listMobileno(this.state.currentID)
      }
    }


    handleDeleteConfirm=()=>{
      this.setState({deleteInit:true, modal:false})
      this.props.deleteMobileno(this.state.currentID, this.state.deleteMobileNoID)
    }

    handleDelete=(data)=>{
      this.setState({modal:true, deleteMobileNoID:data})
    }
    
    handleModalClose=()=>{
      this.setState({modal:false})
    }
    render() {
      return (
        <div>
          {(!this.state.billLoad)
            ? <div className="loader"><Loader height={80} width={80}/></div>
            : <AddressNew
              change={this.handleChange}
              submit={this.handleSubmit}
              data={this.state.data}
              handleDelete={this.handleDelete}
              deleteConfirm={this.handleDeleteConfirm}
              close={this.handleModalClose}
              onDismissAddMobileMessage={this.onDismissAddMobileMessage}
              {...this.state}{...this.props}
              />
            }
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    let notification = toastrActions.add;
    return bindActionCreators({
      addMobileNo,
      listMobileno,
      notification,
      deleteMobileno,
      fetchAccounts,
      updateAccountFromStore
    }, dispatch)
  }

  function mapStateToProps(state) {
    return {
      showAccount: state.showAccount,
      mobileno: state.mobileno,
      updateBilling: state.UpdateAccount
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)
}
