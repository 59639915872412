import React from "react";
import { connect } from "react-redux";
import { fetchAccounts } from "../../../../api/account_service/api.show.accounts";
import { showOrder } from "../../../../api/order_service/api.show.order";
import Loader from "../../loading/loader";
import { getApi } from "../../../../api/api";

export function showOrderContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        order: null,
        loading: true,
        fetchOrder: false,
        currentBillId: "",
        init: null,
        accountId: window.localStorage.getItem("aid"),
        accountName: window.localStorage.getItem("accountName")
      };
    }

    componentWillMount() {
      this.props.fetchAccounts();
      this.props.showOrder(this.state.accountId);
    }

    componentWillReceiveProps(nextProps) {
      if (
        typeof nextProps.showAccount.isSuccess !== undefined &&
        nextProps.showAccount.isSuccess === true
      ) {
        this.setState({
          loading: false
        });
      }

      if (nextProps.order.showSuccess === true) {
        this.setState({
          fetchOrder: false,
          order: nextProps.order.showData.order
        });
      }
    }

    onChange = selectedOption => {
      window.localStorage.setItem("aid", selectedOption.value);
      window.localStorage.setItem("accountName", selectedOption.label);
      this.setState({
        accountId: selectedOption.value,
        accountName: selectedOption.label
      });
      this.props.showOrder(selectedOption.value);
    };
    editOrder = data => {
      this.props.history.push({
        pathname: `orders/edit/${data.id}`,
        state: { detail: data.id }
      });
    };

    showOrderItems = data => {
      this.props.history.push(
        `/order/${this.state.accountId}/items/${data.id}`
      );
    };

    handleReorder = data => {
      const { order_items } = data;
      const { accountId } = this.state;
      this.props.history.push({
        pathname: "./order/reorder",
        state: { orderItem: order_items, accountId }
      });
    };
    deleteDraft = data => {
      this.props
        .getApi({
          url: `accounts/${this.state.accountId}/orders/${data.id}`,
          method: "DELETE",
          succcessActionName: "ORDER_DELETE_SUCCESS",
          errorActionName: "ORDER_DELETE_ERROR",
          showErrorMessage: false,
          showSuccessMessage: true,
          errorMessage: {
            title: "Error Occurs",
            type: "error"
          },
          successMessage: {
            message: "Draft has been deleted successfully.",
            title: "Draft has been deleted successfully.",
            type: "success"
          }
        })
        .then(() => {
          this.props.showOrder(this.state.accountId);
        });
    };

    render() {
      return (
        <div>
          {!this.state.loading ? (
            <Component
              id={this.props.showAccount.data}
              change={this.onChange}
              deposits={this.state.depositList}
              orders={this.state.order}
              editOrder={this.editOrder}
              showOrderItems={this.showOrderItems}
              handleReorder={this.handleReorder}
              deleteDraft={this.deleteDraft}
              {...this.state}
              {...this.props}
            />
          ) : (
            <div className="loader">
              <Loader height={80} width={80} />
            </div>
          )}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return { showAccount: state.showAccount, order: state.order };
  }

  return connect(
    mapStateToProps,
    { fetchAccounts, showOrder, getApi }
  )(Container);
}
