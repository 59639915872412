/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {DELETE_DEPOSIT_SUCCESS, DELETE_DEPOSIT_FAIL } from '../../action/deposit';
export function deleteDeposit(billId, depositeId) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${billId}/deposits/${depositeId}`
    let option={
      method: 'DELETE',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }        
    }
    return fetch(url, option)
    .then(response => response.json().then(deleteDeposit=>({deleteDeposit, response})))
    .then(({deleteDeposit, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      deleteDeposit.status = response.status
       return Promise.reject(deleteDeposit)
         } else {
         dispatch(receiveShowDeposit(deleteDeposit.data))
    }})
    .catch(err => dispatch(depositShowError(err)))
  }
}



function receiveShowDeposit(data) {
  return {
    type: DELETE_DEPOSIT_SUCCESS,
    deleteData:data
  }
}

function depositShowError(err) {
  return {
    type: DELETE_DEPOSIT_FAIL,
    deleteError:err
  }
}



