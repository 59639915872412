import React from 'react';
import {showContainer} from './showContainer';
import {Link} from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';
import {formatAmount} from '../../common/formater';

class AddressShow extends React.Component {
  state = {
    id:'',
    account_details: '',
    postal_address: '',
    city: '',
    country: '',
    account_email: '',
    account_mobile_number: '',
  }

  updateState=(updatePhone)=>{
    this.setState({
      id:this.props.data.id,
      account_details:this.props.data.account_details,
      postal_address:this.props.data.postal_address.slice(8),
      city:this.props.data.city,
      country:this.props.data.country,
      account_email:this.props.data.account_email,
    })
    if(updatePhone){
      this.setState({
        account_mobile_number: this.props.data.account_mobile_number,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let user = {
      account: {
        account_details: this.state.account_details,
        postal_address: `P.O.Box ${this.state.postal_address}`,
        city: this.state.city,
        country: this.state.country,
        account_email: this.state.account_email,
        account_mobile_number: this.state.account_mobile_number
      }
    }
    this.props.updateAccountFromStore(this.state.id, user)
      .then(()=>{this.props.fetchAccounts()})
      .then(()=>{this.updateState(false)});
  }

  handleChange = (e) => {
    //e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  selectCountry =(val)=>{
    this.setState({ country: val });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data !== this.props.date){
      this.updateState();
    }
  }

   render() {
    return (
      <div>
           <Modal isOpen={this.props.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Confirm Delete</ModalHeader>
          <ModalBody>
           Are you sure you want to delete this mobile number?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.props.deleteConfirm}>Delete</Button>
            <Button color="default" onClick={this.props.close}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Card>
          <CardHeader>
            <div className="d-flex">
              <h5 className="">
                Account Details
              </h5>
              <div className="ml-auto">
              <Link to={`/accounts`} className="btn btn-default mr-2">Cancel</Link>              
              <Link to={`/accounts/${this.props.data.id}/edit`} className="btn btn-primary">Edit</Link>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={this.handleSubmit}>
            <div className="entity">
            <Row>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Account Name</strong>
                    <span className="d-block">{this.props.data.account_details}</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Postal Address</strong>
                    <span className="d-block">{this.props.data.postal_address}</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">City</strong>
                    <span className="d-block">{this.props.data.city}</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Country</strong>
                    <span className="d-block">{this.props.data.country}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Mobile Number</strong>
                    <span className="d-block">{this.props.data.account_mobile_number}</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Email Address</strong>
                    <span className="d-block">{this.props.data.account_email}</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Account Number</strong>
                    <span className="d-block">{this.props.data.account_number}</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Available Funds</strong>
                    <span className="d-block">N$ {(formatAmount(this.props.data.available_funds))}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="entity__info">
                    <strong className="d-block">Available Credit</strong>
                    <span className="d-block">N$ {(this.props.data.available_credit/100.00).toFixed(2)}</span>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <MobileNumber data={this.props.data} {...this.props}/> */}
          </form>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default showContainer(AddressShow)
