import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';
import {createDeposit} from '../../../../api/deposit_service/api.create.deposit';
import moment from 'moment';
import Loader from '../../loading/loader';
import {bindActionCreators} from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'

export function createDepositContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        depositID: window.localStorage.getItem('aid'),
        depositType: 'instant recharge',
        depositAmount: '',
        deposit_date: '2017-12-15',
        buttonDisable: false,
        depositCreate:false,
        accountId: window.localStorage.getItem('aid'),
        accountName:window.localStorage.getItem('accountName'),
        date: moment()
      }
    }

    componentWillMount() {
      this.props.fetchAccounts()
    }

    componentDidMount() {
      if (typeof this.props.fetchAccount.isSuccess !== undefined && this.props.fetchAccount.isSuccess === true) {
        this.setState({loading: false});
      }
    }

    componentWillReceiveProps(nextProps) {
      if (typeof nextProps.fetchAccount.isSuccess !== undefined && nextProps.fetchAccount.isSuccess === true)
        this.setState({loading: false});

      if (typeof nextProps.createDeposits.isSuccess !== undefined && nextProps.createDeposits.isSuccess === true && this.state.depositCreate) {
        this.setState({depositCreate:false})
        this.props.notification({
          title: 'Deposit has been created successfully',
          type: 'success',
          options:{showCloseButton:true, removeOnHover:true}
        });
        const {history} = this.props
        history.push('../deposits')
      }

    }

    createDeposit = (e) => {
      e.preventDefault()
      this.setState({depositCreate:true})
      var data = {
        deposit: {
          deposit_type: this.state.depositType,
          amount: this.state.depositAmount*100,
          deposit_date: this
            .state
            .date
            .format('YYYY-MM-DD')
        }
      }
      this.setState({buttonDisable: true})
      this.props.createDeposit(this.state.depositID, data)

    }

    handleDate = (date) => {
      this.setState({date: date});
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    _handleBillingChange=(e)=>{
      window.localStorage.setItem('aid', e.target.value)
      window.localStorage.setItem('accountName', e.target.options[e.target.selectedIndex].text)
      this.setState({
        accountId: e.target.value,
        accountName:e.target.options[e.target.selectedIndex].text,
        [e.target.name]: e.target.value
      })
    }

    render() {
      return (
        <div>
          {(!this.state.loading)
            ? <Component
                id={this.props.fetchAccount.data}
                deposit={this.createDeposit}
                {...this.state}
                change={this.handleChange}
                changeBill={this._handleBillingChange}
                data={this.handleDate}/>
            : <div className="loader"><Loader height={80} width={80}/></div>}
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    let notification = toastrActions.add;
    return bindActionCreators({
      fetchAccounts,
      createDeposit,
      notification
    }, dispatch)
  }

  function mapStateToProps(state) {
    return {fetchAccount: state.showAccount, createDeposits: state.createDeposits}
  }

  return connect(mapStateToProps, mapDispatchToProps)(Container)
}
