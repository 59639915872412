/* eslint-disable camelcase */
import React, { Component } from "react";
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
  FormGroup
} from "reactstrap";

class CreateLabelModal extends Component {
  state = {
    label: this.props.selectedOrder.order_label
  };

  componentWillReceiveProps(nextProps, props) {
    if (nextProps.selectedOrder !== props.selectedOrder) {
      this.setState({ label: nextProps.selectedOrder.order_label });
    }
  }

  handleToggle = e => {
    e.preventDefault();
    this.props.toggleCreateLabelModal();
  };

  /** call and api to create a label */
  handleSubmit = e => {
    e.preventDefault();
    const {
      getApi,
      showOrder,
      toggleCreateLabelModal,
      accountId,
      selectedOrder: { id }
    } = this.props;
    const { label } = this.state;
    getApi({
      url: `accounts/${accountId}/update_orders/${id}`,
      method: "PUT",
      body: { order: { order_label: label } },
      successActionName: "CREATE_ORDER_LABEL_SUCCESS",
      errorActionName: "CREATE_ORDER_LABEL_ERROR",
      showErrorMessage: false,
      showSuccessMessage: false,
      errorMessage: {
        title: "Error Occurs",
        type: "error"
      },
      successMessage: {
        message: "Order label created successfully.",
        title: "Order label created successfully.",
        type: "success"
      }
    }).then(() => {
      showOrder(accountId);
      toggleCreateLabelModal();
    });
  };

  render() {
    const { showCreateLabelModal, toggleCreateLabelModal } = this.props;

    return (
      <Modal
        isOpen={showCreateLabelModal}
        toggle={toggleCreateLabelModal}
        className={this.props.className}
      >
        <ModalHeader>Add Label For This Order</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="exampleEmail">Label</Label>
              <Input
                value={this.state.label}
                onChange={({ target: { value } }) =>
                  this.setState({ label: value })
                }
              />
            </FormGroup>
            <div className="pull-right">
              <button type="submit" className="mt-2 btn btn-primary">
                Update Label
              </button>

              <button
                onClick={this.handleToggle}
                className="mt-2 ml-2 btn btn-outline-primary btn-sm btn btn-secondary"
              >
                Cancel
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default CreateLabelModal;
