import {SHOW_INVOICE_SUCCESS, SHOW_INVOICE_FAIL, SEND_INVOICE_SUCCESS, SEND_INVOICE_FAIL} from '../../action/invoice';

export function invoice(state = {
  fetch: true,
  showSuccess:false,
  showData:null
}, action) {

  switch (action.type) {

    case SHOW_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        invoiceShowSuccess: true,
        showError: false,
        showData: action.showData,
      })

    case SHOW_INVOICE_FAIL:
      return Object.assign({}, state, {
        showError: true,
        invoiceShowSuccess:false,
        showErrorData: action.showErrorData
      })

    case SEND_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        fetch: false,
        sendSuccess: true,
        sendError: false,
        sendData: action.sendData,
        sendErrorData: null
      })

    case SEND_INVOICE_FAIL:
      return Object.assign({}, state, {
        fetch: false,
        sendSuccess: false,
        sendData: null,
        sendError: true,
        sendErrorData: action.sendError
      })

    default:
      return state;
  }

}