/**
 *@description refer src/api/api.js. same as that 
 */
import {actions as toastrActions} from 'react-redux-toastr'
import api from "../../config/api.js";
export const FORGET_SUCCESS = 'FORGOT_SUCCESS'
export const FORGET_FAILURE = 'FORGOT_FAILURE'


export function forgetPassword(data){
  let tokenurl = `${api.apiUrl}${api.version}${api.resource.apiToken}`
  let url = `${api.apiUrl}${api.version}/forgot_password/request`
  return dispatch => {
  return fetch(tokenurl, {
      method: 'post',
      Content:'text/plain',
      body: JSON.stringify({"application":{"application_name":"front-end", "password":"xS3g7j8EYefUE8wk"}})})
     .then(res => res.json())
     .then((res)=> {
            let option={
            method: 'POST',
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+res.data.token},
            body:JSON.stringify(data) }
        fetch(url, option)
        .then(response => response.json().then(user => ({ user, response })))
        .then(({ user, response }) =>  {
          if (!response.ok) {
           user.status=response.status;
          return Promise.reject(user)
            } else {
              dispatch(toastrActions.add({
                title:'Reset link has been sent to your email',
                type:'success',
                options:{showCloseButton:true,removeOnHover:true},
                timeOut:4000
              }))   
            dispatch(receiveForgotEmail(user.data.message))
          }
        }).catch(err =>{
           dispatch(forgotEmailError(err))
           dispatch(toastrActions.add({
            type:'error',
            message:err.errors.message,
            options:{showCloseButton:true,removeOnHover:true},
            timeOut:4000
          }))  
         })
      })
      .catch(err => dispatch(forgotEmailError(err)))
    
    }

  }
  




function receiveForgotEmail(res) {
  return {
    type: FORGET_SUCCESS,
    res
  }
}

function forgotEmailError(err) {
  return {
    type: FORGET_FAILURE,
    err
  }
}





