/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
import { UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAIL } from '../../action/order';

export function updateOrder(aid, oid, data) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${aid}/orders/${oid}`
    let option={
      method: 'PUT',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)         
    }
    return fetch(url, option)
    .then(response => response.json().then(createOrder=>({createOrder, response})))
    .then(({createOrder, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      if(response.status===401){alert('session expire'); window.top.location.href = "/";}
      if(response.status===404){window.top.location.href = "/404";}
      createOrder.status = response.status
       return Promise.reject(createOrder)
         } else {
         dispatch(receiveUpdateOrder(createOrder.data))
    }
  })
    .catch(err => { 
      dispatch(updateOrderError(err))
      dispatch(toastrActions.add({
        title:'Update Error',
        type:'error',
        message:err.message,
        options:{showCloseButton:true, removeOnHover:true},
        timeOut:4000
      })) 
    })
  }
}



function receiveUpdateOrder(data) {
  return {
    type: UPDATE_ORDER_SUCCESS,
    updateData:data
  }
}

function updateOrderError(err) {
  return {
    type: UPDATE_ORDER_FAIL,
    updateErrorData:err
  }
}



