/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'


class Schedule extends React.Component {
  constructor() {
    super()
    this.state = {
      date: moment()

    }
  }


  componentWillMount () {
    const {
      voucher_type: voucherType,
      id,
      date: lastRechargeDate,
      handleChange,
    } = this.props

    const count = id === 1 ? 0 : id - 1
    const referenceDate = lastRechargeDate !== "false" ? moment(lastRechargeDate) : moment()
    const hasAweh = voucherType.toLowerCase().includes("aweh")
    const isAweh = hasAweh && !voucherType.includes("30")
    const isAweh30 = hasAweh && voucherType.includes("30")
    const isTango = !isAweh && !isAweh30

    if (isTango) {
      this.setState({ date: moment() })
      handleChange(id, moment().format("YYYY/MM/DD"))
      return
    } else if (isAweh) {
      const daysApart = 8
      const newDate = moment(referenceDate).add(daysApart * count, "days")
      this.setState({ date: newDate })
      handleChange(id, newDate.format("YYYY/MM/DD"))
      return
    } else if (isAweh30) {
      const daysApart = 31
      const newDate = moment(referenceDate).add(daysApart * count, "days")
      this.setState({ date: newDate })
      handleChange(id, newDate.format("YYYY/MM/DD"))
      return
    }
    // JUST IN CASE: if all else fails, set the date to today
    this.setState({ date: moment() })
    handleChange(id, moment().format("YYYY/MM/DD"))
  }

  handleChange = (date) => {
    this.setState({ date: date })
  }

  handleBlur = (data) => {
    this.props.handleChange(this.props.id, this.state.date.format('YYYY/MM/DD'))
  }

  ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100
    // eslint-disable-next-line    
    if (j == 1 && k != 11) {
      return i + "st"
    }
    // eslint-disable-next-line
    if (j == 2 && k != 12) {
      return i + "nd"
    }
    // eslint-disable-next-line
    if (j == 3 && k != 13) {
      return i + "rd"
    }
    return i + "th"
  }




  render () {

    return (
      <Row>
        <Col xs="6">{ this.ordinal_suffix_of(this.props.id) } Recharge Schedule Date</Col>
        <Col xs="6">
          <div className="select-date">
            <DatePicker
              required={ true }
              className="form-control "
              name="deposit_date"
              value={ this.state.date.format('YYYY/MM/DD') }
              minDate={ moment() }
              selected={ this.state.date }
              onChange={ this.handleChange }
              onBlur={ this.handleBlur } />
          </div>
        </Col>
      </Row>
    )
  }
}




class ScheduleModal extends React.Component {
  constructor() {
    super()
    this.state = {
      form: [],
      hasQuantity: false,
      scheduleDate: []
    }
  }


  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      scheduleDate: []
    })
    this.props.submit(this.state.scheduleDate)
  }

  toggle = () => {
    this.setState({
      form: [],
      hasQuantity: false,
      scheduleDate: []
    })
    this.props.toggle()
  }

  handleChange = (key, date) => {
    let scheduleDate = this.state.scheduleDate
    scheduleDate[key - 1] = date
    this.setState({
      scheduleDate: scheduleDate
    })

  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.voucher_quantity && nextProps.modal && this.state.hasQuantity === false) {
      let i
      let form = this.state.form
      for (i = 1; i <= nextProps.voucher_quantity; i++) {
        form.push(<Schedule id={ i } key={ i } { ...this.props } handleChange={ this.handleChange } />)
      }
      this.setState({
        form: form,
        hasQuantity: true
      })
    }
  }

  render () {
    return (
      <div>
        <Modal className='order__schedule-modal' isOpen={ this.props.modal } toggle={ this.toggle }>
          <form onSubmit={ this.handleSubmit }>
            <ModalHeader toggle={ this.toggle }><strong className="mr-2 ">{ this.props.voucher_type }</strong>voucher schedule date</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs="6"><strong> Recharge Delivery</strong></Col>
                <Col xs="6"><strong> Schedule date</strong></Col>
              </Row>
              { this.state.form }
            </ModalBody>
            <ModalFooter>
              <Button color="default" onClick={ this.toggle }>Cancel</Button>
              <Button type="submit" color="primary" >Save Schedule</Button>{ ' ' }
            </ModalFooter>
          </form>
        </Modal>
      </div>
    )
  }
}

export default ScheduleModal