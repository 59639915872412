import React from 'react';
import { connect } from 'react-redux';
import { updateProfile } from '../../../../api/user_profile/profile.update'
import { showProfile } from '../../../../api/user_profile/profile.show'
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr'
import Loader from '../../loading/loader';
import { getApi } from '../../../../api/api'

export function profileUpdateContainer(Component) {

  class ProfileContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        id: '',
        loading: true,
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        disable: false,
        updateRequest: false,
        old_password: '',
        new_password: '',
        password_confirmation: '',
        event:'',
        newEmail:''

      }

    }

    componentWillMount() {
      this.props.showProfile(localStorage.getItem('user_id'));
    }

    handleChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value,
        event:event.target.setCustomValidity
      })

      // if(event.target.name === 'password_confirmation' {

      // } ) 
    }

    handleEmailChange=(e)=>{
      e.preventDefault()
      this.props.getApi({
        url:`users/${this.state.id}`,
        method:'put',
        body:{user:{new_email:this.state.newEmail}},
        successActionName:' ',
        errorActionName:' ',
        showErrorMessage:true,
        showSuccessMessage:true,
        errorMessage:{
          type:'error',
          title:'Error occurred.'
        },
        successMessage:{
          type:'success',
          title:'Email has been trigger. Please validate you email address for change to take effect.'
        }
      })
    }

      // handleBlur=(event)=>{
      //   if(this.state.password_confirmation!==this.state.new_password){
      //     event.target.setCustomValidity('Confirmation password does not match new password.');
      //   }
      // }
  
      
    

    onSubmit = (e) => {
      this.setState({ disable: true, updateRequest: true })
      window.localStorage.setItem('user_first_name', this.state.first_name)
      window.localStorage.setItem('user_last_name', this.state.last_name)
      
      let data = {
        user: {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          mobile_number: this.state.mobile_number
        }
      }
      this.props.updateProfile(this.state.id, data).then(()=>{
        this.props.history.push('/')
      })
    }

    handlePasswordChange = (e) => {
      e.preventDefault();
      if(this.state.new_password!==this.state.password_confirmation){
        this.props.add({
          title:'Confirmation password does not match new password.',
          type:'error',
          timeOut: 4000,
          options: { showCloseButton: true }
        })
      } else {
      let data = {
        user: {
          old_password: this.state.old_password,
          password: this.state.new_password,
          password_confirmation: this.state.password_confirmation
        }
      }
      this.props.getApi({
        url: `/users/${window.localStorage.getItem('user_id')}`,
        method: 'PUT',
        body: data,
        errorActionName: 'UPDATE_PASSWORD_ERROR',
        successActionName: 'UPDATE_PASSWORD_SUCCESS',
        showErrorMessage: true,
        showSuccessMessage: true,
        successMessage: {
          title: 'Update Successful',
          message: 'Password has been updated successfully.',
          type: 'success',
        },
        errorMessage: {
          title: 'Error Updating.',
          type: 'error',
        }
      })
      document.getElementById("changePassword").reset();
    }
  }  

    requestDeactivate=(e)=>{
      e.preventDefault();
      this.props.getApi({
        url: `/users/${window.localStorage.getItem('user_id')}/deactivation`,
        method: 'PUT',
        body: {user:{deactivation_request: true}},
        errorActionName: 'ACCOUNT_DEACTIVATION_ERROR',
        successActionName: 'ACCOUNT_DEACTIVATION_SUCCESS',
        showErrorMessage: true,
        showSuccessMessage: true,
        successMessage: {
          title: 'Request Successful',
          message: 'Account deactivation request has been sent.',
          type: 'success',
        },
        errorMessage: {
          title: 'Error Updating',
          type: 'error',
        }
      })

    }

    componentWillReceiveProps(nextProps) {
      if (typeof nextProps.show.showSuccess !== undefined && nextProps.show.showSuccess === true) {
        let user = nextProps.show.showData
        this.setState({
          loading: false,
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          mobile_number: user.mobile_number,
          avatar_url:user.avatar_url
        })
      }

      if (this.state.updateRequest !== false && nextProps.profile.updateSuccess === true) {
        this.setState({ disable: false, count: 2 })
        this
          .props
          .add({
            id: '485289dkjaslk',
            title: 'Profile has been updated successfully.',
            type: 'success',
            preventDuplicates: true,
            timeOut: 4000,
            options: { showCloseButton: true }
          })
        let { history } = this.props;
        history.push('/');
      }

    }

    render() {
      return (
        <div>
          {(this.state.loading !== true)
            ? <Component change={this.handleChange} {...this.state} submit={this.onSubmit}
              handlePasswordChange={this.handlePasswordChange}
              requestDeactivate={this.requestDeactivate}
              handleBlur={this.handleBlur}
              handleEmailChange={this.handleEmailChange}
               />
            : <div className="loader"><Loader height={80} width={80} /></div>
          }
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    let add = toastrActions.add;
    return bindActionCreators({
      updateProfile,
      showProfile,
      add: add,
      getApi
    }, dispatch)
  }

  function mapStateToProps(state) {

    return { profile: state.profile, show: state.showprofile }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
}