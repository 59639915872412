/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
import {UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAIL } from '../../action/bill';

export function updateAccountFromStore(id, data) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${id}`
    let option={
      method: 'PUT',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)                
    };
    return fetch(url, option)
    .then(response => response.json().then(updatedebill=>({updatedebill, response})))
    .then(({updatedebill, response})=>{
      if(!response.ok){
        if(response.status===401){
          window.localStorage.clear();
          window.location.reload();
        }
        return Promise.reject(updatedebill)
      } else {
        
         if(response.status == 206){
          dispatch(
            toastrActions.add({
              message: 'Only mobile number has been updated. Other fields cannot be updated while there are associated numbers & orders.',
              type: 'warning',
              preventDuplicates: true,
              options: { showCloseButton: true, removeOnHover:true }
            })
          )
         } else {
        dispatch(
          toastrActions.add({
            message: 'Account has been update successful.',
            type: 'success',
            preventDuplicates: true,
            options: { showCloseButton: true, removeOnHover:true }
          })
        )}
        dispatch(receiveUpdateAccount(updateAccountFromStore));                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
      }})
    .catch(err => { 
      dispatch(billUpdateError(err))
      dispatch(
        toastrActions.add({
          message: err.errors.message,
          type: 'error',
          id: '5784832',
          progressBar: true,
          preventDuplicates: true,
          options: { showCloseButton: true, removeOnHover:true }
        })
        )
    })
   }
 }
  



function receiveUpdateAccount(data) {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    updateData:data
  }

}


function billUpdateError(err) {
  return {
    type: UPDATE_ACCOUNT_FAIL,
    updateError:err
  }
}



