import React from 'react'
import { connect } from 'react-redux'
import { fetchAccounts } from '../../../../api/account_service/api.show.accounts'
import { listMobileno } from '../../../../api/entity_mobileno/api.show.mobileno'
import { addMobileNo } from '../../../../api/entity_mobileno/api.create.mobileno'
import { indexProducts } from '../../../../api/product/api.index.product'
import Loader from '../../loading/loader'
import { bindActionCreators } from 'redux'
import { actions as toastrActions } from 'react-redux-toastr'
import { createOrder } from '../../../../api/order_service/api.create.order'
import _ from 'lodash'
import { getApi } from '../../../../api/api'
import moment from 'moment'
import { getOrderItemList } from './helpers/getOrderItemList'

export function reOrderContainer (Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        aid: window.localStorage.getItem('aid'),
        accountEmail: '',
        accountLoading: true,
        oloading: true,
        mloading: true,
        ploading: true,
        numberList: {},
        product: {},
        email: '',
        orderList: [],
        accountId: window.localStorage.getItem('aid'),
        accountName: window.localStorage.getItem('accountName'),
        createOrder: false,
        calculateTotalPrice: true,
        totalPrice: 0,
        amount: 0,
        credit: 0.0,
        discountID: null,
        discountRate: null,
      }
    }

    componentDidMount () {
      this.props.fetchAccounts()
      this.props.listMobileno(this.state.accountId).then(() => {
        const { numbers } = this.props.mobileno.listData
        if (typeof this.props.location.state !== 'undefined') {
          const { orderItem } = this.props.location.state
          const newOrderItemsList = getOrderItemList(orderItem, numbers)
          this.setState({ orderList: newOrderItemsList })
          window.addEventListener('beforeunload', this.onUnload)
        } else {
          this.props.history.push('/orders')
        }
      })
      this.props.indexProducts()
      this.getSingleAccount()
      // eslint-disable-next-line
    }

    getSingleAccount = () => {
      this.props.getApi({
        url: `accounts/${this.state.accountId}`,
        method: 'GET',
        successActionName: 'GET_ACCOUNT_DETAIL_SUCCESS',
        errorActionName: 'GET_ACCOUNT_DETAIL_FAIL',
        showErrorMessage: false,
        showSuccessMessage: false,
      })
    }

    loadMobileNumber = () => {
      this.props.listMobileno(this.state.billingId)
    }

    getDiscountId = (discountID, discountRate) => {
      this.setState({ discountID, discountRate })
    }

    calculateProductPrice = () => {
      if (this.state.orderList.length !== 0) {
        let itemPrice = 0
        // eslint-disable-next-line
        this.state.orderList.map(key => {
          const price = _.find(this.state.product, { value: key.voucher_type })
          itemPrice += price.price * key.voucher_quantity
        })
        this.setState({
          totalPrice: (itemPrice * 1.0) / 100,
          calculateTotalPrice: false,
        })
      } else {
        this.setState({
          totalPrice: 0,
          calculateTotalPrice: false,
        })
      }
    }

    onUnload = e => {
      // the method that will be used for both add and remove event
      this.props.history.push('/orders')
    }

    componentWillUnmount = () => {
      window.removeEventListener('beforeunload', this.onUnload)
    }

    componentWillReceiveProps (nextProps) {
      if (nextProps.billingDetail.listSingleAccountSuccess) {
        this.setState({
          amount: nextProps.billingDetail.singleAccountData.available_funds,
          credit: nextProps.billingDetail.singleAccountData.available_credit,
        })
      }

      if (
        nextProps.orderReducer.createSuccess === true &&
        this.state.createOrder
      ) {
        this.setState({
          createOrder: false,
          saveDraftRequest: false,
          proceedOrderRequest: false,
        })
        if (
          nextProps.orderReducer.createData.data.order.order_status === 'draft'
        ) {
          this.props.notification({
            title: 'Your Order has been saved',
            type: 'success',
            options: { showCloseButton: true, removeOnHover: true },
          })
          this.props.history.push('/orders')
        } else {
          const { history } = this.props
          history.push({
            pathname: '/order/thankyou',
            state: {
              type: nextProps.orderReducer.createData.data.order_placed,
              email: this.state.accountEmail,
            },
          })
        }
      }

      if (nextProps.showAccount.isSuccess === true) {
        // eslint-disable-next-line
        Object.keys(nextProps.showAccount.data).map(key => {
          const account = nextProps.showAccount.data[key]
          // eslint-disable-next-line
          if (account.id == this.state.accountId) {
            this.setState({
              accountEmail: account.account_email,
              accountLoading: false,
            })
          }
        })
      }

      if (nextProps.mobileno.listSuccess === true) {
        const phoneNumberSelectArray = []
        // eslint-disable-next-line
        const { numbers } = nextProps.mobileno.listData
        const ActiveNumberList = numbers.filter(item => item.active === true)

        ActiveNumberList.map(item => {
          phoneNumberSelectArray.push({
            value: item.mobile_number,
            label: `${item.associated_name} | ${item.mobile_number}`,
            date: `${item.last_recharge_date}`,
            delivery_person: item.associated_name,
          })
        })
        this.setState({ numberList: phoneNumberSelectArray, mloading: false })
      }

      if (nextProps.products.isSuccess === true) {
        const list = Object.keys(nextProps.products.data).map(key => {
          const data = nextProps.products.data[key]
          return {
            value: data.product_name,
            label: data.product_name,
            price: data.product_price,
          }
        })
        this.setState({
          ploading: false,
          product: list,
        })
      }
    }

    editOrder = (data, index) => {
      const items = this.state.orderList
      items.splice(index, 1)
      items.push(data.orderItems)
      // items.splice(data.index, 1);
      // items.splice(data.index, 0, data.orderItems)
      this.setState({
        orderList: items,
        calculateTotalPrice: true,
      })
    }

    addOrder = data => {
      const items = this.state.orderList
      items.push(data)
      this.setState({ order: items, calculateTotalPrice: true })
    }

    getBillId = e => {
      const data = document.getElementById(e.target.value)
      window.localStorage.setItem(data.dataset.email, data.dataset.email)
      this.setState({
        aid: e.target.value,
        email: data.dataset.email,
        order: [],
      })
      this.props.fetchAccounts()
      this.props.listMobileno(e.target.value)
    }

    saveDraft = e => {
      e.preventDefault()
      if (this.state.orderList.length === 0) {
        this.props.notification({
          title: 'Order items canot be blank',
          type: 'error',
          options: { showCloseButton: true, removeOnHover: true },
        })
      } else {
        this.setState({ createOrder: true })
        const order = {
          order: {
            order_items: this.state.orderList.map(item => {
              delete item.delivery_person
              return item
            }),
            order_status: 'draft',
          },
        }
        this.props.createOrder(this.state.aid, order)
      }
    }

    processOrder = e => {
      e.preventDefault()
      if (this.state.orderList.length === 0) {
        this.props.notification({
          title: 'Order items canot be blank',
          type: 'error',
          options: { showCloseButton: true, removeOnHover: true },
        })
      } else {
        this.setState({ createOrder: true })
        const order = {
          order: {
            order_items: this.state.orderList.map(item => {
              delete item.delivery_person
              return item
            }),
            order_status: 'pending',
            promotion_id: this.state.discountID ? this.state.discountID : null,
            discount_amount: this.state.discountRate,
          },
        }
        this.props.createOrder(this.state.aid, order)
      }
    }

    handleDeleteOrderItems = index => {
      const { orderList } = this.state
      // eslint-disable-next-line
      orderList.splice(index, 1)
      this.setState({ orderList, calculateTotalPrice: true })
    }
    render () {
      if (this.state.calculateTotalPrice) {
        this.calculateProductPrice()
        return 0
      }
      return (
        <div>
          { !this.state.accountLoading &&
            !this.state.mloading &&
            !this.state.ploading ? (
            <Component
              bill={ this.props.showAccount.data }
              getBillId={ this.getBillId }
              getInitialNumber={ this.getInitialNumber }
              saveDraft={ this.saveDraft }
              processOrder={ this.processOrder }
              { ...this.state }
              { ...this.props }
              addOrder={ this.addOrder }
              calculateProductPrice={ this.calculateProductPrice }
              editOrder={ this.editOrder }
              getDiscountId={ this.getDiscountId }
              handleDeleteOrderItems={ this.handleDeleteOrderItems }
              loadMobileNumber={ this.loadMobileNumber }
            />
          ) : (
            <div className="loader">
              <Loader height={ 80 } width={ 80 } />
            </div>
          ) }
        </div>
      )
    }
  }

  function mapDispatchToProps (dispatch) {
    const notification = toastrActions.add
    return bindActionCreators(
      {
        fetchAccounts,
        listMobileno,
        addMobileNo,
        notification,
        indexProducts,
        createOrder,
        getApi,
      },
      dispatch,
    )
  }

  function mapStateToProps (state) {
    return {
      showAccount: state.showAccount,
      mobileno: state.mobileno,
      products: state.indexProducts,
      billingDetail: state.UpdateAccount,
      orderReducer: state.order,
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Container)
}
