import {CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE} from '../../api/account_service/api.create.accounts';
export function createAccount(state = {
		isInit: false,
		hasError: false,
}, action) {
		switch (action.type) {
				case CREATE_ACCOUNT_REQUEST:
						return Object.assign({}, state, {
								isRequested: true,
								isRegister: false,
								hasError: false,
								message: 'Requesting'
						})

				case CREATE_ACCOUNT_SUCCESS:
				
						return Object.assign({}, state, {
								isRequested: false,
								isSuccess: true,
								hasError: false,
								data: action.data
						})

				case CREATE_ACCOUNT_FAILURE:
						return Object.assign({}, state, {
								isRequested: false,
								isRegister: false,
								hasError: true,
								errorMessage: action.err
						})

				default:
						return state;

		}
}