import {SHOW_DEPOSIT_REQUEST, SHOW_DEPOSIT_SUCCESS, SHOW_DEPOSIT_FAILURE} from '../../api/deposit_service/api.show.deposit';

export function showDeposits(state = {
  isInit: true

}, action) {
  switch (action.type) {
    case SHOW_DEPOSIT_REQUEST:
      return Object.assign({}, state, {
        isRequested: true,
        isSuccess: false,
        hasError: false,
        message: 'Requesting'
      })

    case SHOW_DEPOSIT_SUCCESS:
      return Object.assign({}, state, {
        isRequested: false,
        isSuccess: true,
        hasError: false,
        data: action.data.deposit
      })

    case SHOW_DEPOSIT_FAILURE:
      return Object.assign({}, state, {
        isRequested: false,
        isRegister: false,
        hasError: true,
        errorMessage: null
      })

    default:
      return state;

  }
}