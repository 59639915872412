import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getApi} from '../../../../api/api';
import moment from 'moment';

class Discount extends Component {

  _calculateSubTotal=()=>{
    let vatPercentage  = this.props.isVatActive?this.props.vatPercentage:0.00
    let totalAmount = this.props.totalAmount
    //eslint-disable-next-line
    return (totalAmount*100/(100+ parseInt(vatPercentage)))
  }

  _calculateDiscountAmount=()=>{
    let subtotal = this._calculateSubTotal();
    return (subtotal*((this.props.discountRate)/100))
  }

  _calculateVatAmount=()=>{
    let subtotal = this._calculateSubTotal();
    let discountedTotal = subtotal - (subtotal*((this.props.discountRate)/100))
    if (this.props.isVatActive) {
      return discountedTotal*(this.props.vatPercentage/100)
     
    }
    return ((discountedTotal*10)/10).toFixed(2)
  }

  _calculateTotalAmount=()=>{
    let subtotal = this._calculateSubTotal();
    let discountedTotal = subtotal - (subtotal*((this.props.discountRate)/100))
    if (this.props.isVatActive) {
      let totalAmount = discountedTotal+ (discountedTotal*(this.props.vatPercentage/100))
      return ((totalAmount*10)/10).toFixed(2)
    }
    return ((discountedTotal*10)/10).toFixed(2)
  }

  render() {
 
    return (
      <table className="pull-right">
        {this.props.discountRate > 0 || this.props.isVatActive ?
          <tr>
            <th>Sub Total</th>
            <td>N$ {(this._calculateSubTotal()).toFixed(2)}</td>
          </tr> :null}
        {this.props.discountRate > 0 ?
          <tr>
            <th>Discount Applied ({this.props.discountRate} %)</th>
            <td>N$ {(this._calculateDiscountAmount()).toFixed(2)}</td>
          </tr>
          :null}
        {this.props.isVatActive > 0 ?
          <tr>
            <th>Vat ({this.props.vatPercentage} %)</th>
            <td>N$ {
              //eslint-disable-next-line              
              (this._calculateVatAmount()).toFixed(2)}</td>
          </tr>
          :null}  
        <tr>
          <th><strong> Total Amount:</strong></th>
          <td><strong> N$ {this._calculateTotalAmount()}</strong></td>
        </tr>
      </table>
    );
  }
}

export default container(Discount);

function container(Comp) {
  class Container extends Component {
    constructor(props) {
      super(props);
      this.state={
        Amount:0.00 ,
        isLoading:true,
        offer:[],
        hasPromotion:false,
        discountRate:0.00,
        promotionId:null,
        isVatActive:false,
        vatPercentage:0.00,
        isVatLoading:true
      }
    }



    componentWillMount() {
      this.props.getApi({
        url:'promotions',
        method:'get',
        successActionName:'FETCH_PROMOTION_SUCCESS',
        errorActionName:'FETCH_PROMOTION_FAIL',
        showSuccessMessage:false,
        showErrorMessage:false,
      })

      this.props.getApi({
        url:'settings',
        method:'get',
        successActionName:'FETCH_VAT_SUCCESS',
        errorActionName:'FETCH_VAT_FAIL',
        showSuccessMessage:false,
        showErrorMessage:false,
      })
    }

    componentDidMount() {
      this.setState({
        Amount:this.props.totalAmount
      })
    }

    caculateDiscount=(promotion)=>{
      var hasPromotion = false
      var discountRate = 0.00
      var promotionId = null
      //eslint-disable-next-line      
      promotion.map(key=>{
        if (key.promotion_type==='date_based'&& !hasPromotion) {
          let startDate = moment(key.start_date.trimRight(16))
          let endDate = moment(key.end_date.trimRight(16))
          let date = moment().utcOffset('-345')
          if (date.isBetween(startDate, endDate)) {
            hasPromotion = true
            discountRate = key.discount
            promotionId = key.id
          }
        }
      })

      if (hasPromotion===false) {
        //eslint-disable-next-line
        promotion.map(key=>{
          if (key.promotion_type==='amount_based') {
            let maxAmount = key.maximum_amount
            let minAmount = key.minimum_amount
            let totalAmount = this.props.totalAmount
            if (minAmount<=totalAmount && maxAmount>=totalAmount) {
              hasPromotion = true
              discountRate = key.discount
              promotionId = key.id
            }
          }
        })
      }

      this.props.getDiscountId(promotionId, discountRate)
      this.setState({
        hasPromotion:hasPromotion,
        discountRate:discountRate,
        promotionId:promotionId
      })
    }



    componentWillReceiveProps(nextProps) {
      if (nextProps.promotions.fetchSuccess) {
        let {promotion} = nextProps.promotions.data.data
        this.setState({
          offer:promotion,
          isLoading:false
        })
        this.caculateDiscount(promotion)   
      }

      if (nextProps.promotions.vatFetch) {
        let {setting} = nextProps.promotions.vatDetail.data
        this.setState({
          isVatActive:setting.vat_enabled,
          vatPercentage:setting.vat_percentage,
          isVatLoading:false
        })
      }
    }
 
    render() {
      return ( 
        <div> 
          {!this.state.isLoading && !this.state.isVatLoading ?  
            <Comp {...this.props} {...this.state}/>:
            <div><p>Calculating Total Amount...</p></div>}
        </div>
      )
    }
  }
  
  function mapStateToProps(state) {
    return {
      promotions:state.promotions
    }
  }
 
  return connect(mapStateToProps,{getApi})(Container)
}