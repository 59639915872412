import React, {Component} from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import {Link} from 'react-router-dom';

class Styleguide extends Component {
  render() {
    return (
      <div className="container">
        <div className="style">
          <h1 className="p-1">
            <span className="text-primary text-uppercase font-weight-bold">TimeSlick</span>
            Styleguide</h1>
          <div className="style__colour">
            <h3 className="mt-5 ">
              <span className="text-muted h5">01.</span>
              <span className="text-dark-light font-weight-bold">Color</span>
            </h3>
            <hr/>
            <Row>
              <Col sm="4" className="mb-3">
                <Row className="white">
                  <Col sm="12 ">
                    <div className="bg-primary p-5"></div>
                  </Col>
                </Row>
                <div className="p-3 bg-white">
                  <h5>Brand color</h5>
                  <span className="bg-primary d-inline-block p-1 rounded-circle"></span>
                  <span className="ml-1">$bg-primary, #2B98F0, rgb(33, 150, 243)</span>
                </div>
              </Col>
              <Col sm="4" className="mb-3">
                <Row className="white">
                  <Col sm="12 ">
                    <div className="bg-secondary p-5"></div>
                  </Col>
                </Row>
                <div className="p-3 bg-white">
                  <h5>Secondary color</h5>
                  <span className="bg-secondary d-inline-block p-1 rounded-circle"></span>
                  <span className="ml-1">$bg-secondary, #38454F, rgb(55, 69, 80)</span>
                </div>
              </Col>
            </Row>

            <h4>Support Colours</h4>
            <Row>
              <Col sm="3" className="mb-3">
                <Row className="white">
                  <Col sm="12 ">
                    <div className="bg-success p-5"></div>
                  </Col>
                </Row>
                <div className="p-3 bg-white">
                  <h5>Success color</h5>
                  <span className="bg-success d-inline-block p-1 rounded-circle"></span>
                  <span className="ml-1">$bg-success, #4dbd74</span>
                </div>
              </Col>
              <Col sm="3" className="mb-3">
                <Row className="white">
                  <Col sm="12 ">
                    <div className="bg-info p-5"></div>
                  </Col>
                </Row>
                <div className="p-3 bg-white">
                  <h5>Info color</h5>
                  <span className="bg-info d-inline-block p-1 rounded-circle"></span>
                  <span className="ml-1">$bg-info, #63c2de</span>
                </div>
              </Col>
              <Col sm="3" className="mb-3">
                <Row className="white">
                  <Col sm="12 ">
                    <div className="bg-warning p-5"></div>
                  </Col>
                </Row>
                <div className="p-3 bg-white">
                  <h5>Warning color</h5>
                  <span className="bg-warning d-inline-block p-1 rounded-circle"></span>
                  <span className="ml-1">$bg-warning, #ffc107</span>
                </div>
              </Col>
              <Col sm="3" className="mb-3">
                <Row className="white">
                  <Col sm="12 ">
                    <div className="bg-danger p-5"></div>
                  </Col>
                </Row>
                <div className="p-3 bg-white">
                  <h5>Danger color</h5>
                  <span className="bg-danger d-inline-block p-1 rounded-circle"></span>
                  <span className="ml-1">$bg-danger, #f86c6b</span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="style__typography">
            <h3 className="mt-5 ">
              <span className="text-muted h5">02.</span>
              <span className="text-dark-light font-weight-bold">Typography</span>
            </h3>
            <hr/>
            <Row>
              <Col sm="6">
                <h1>Heading level one</h1>
                <h2 className="">Heading level two</h2>
                <h3>Heading level three</h3>
                <h4>Heading level four</h4>
                <h5>Heading level five</h5>
              </Col>
              <Col sm="6">
                <h3>Paragraph</h3>
                <p>
                  This is a lead paragraph. It might be used as a longer-form tagline or
                  subheading for a page or content section. It has a slightly larger font size and
                  line-height. This is a normal paragraph. On static pages, it's designed for
                  readability and optimizing the number of characters per line. The font size is
                  larger to account for longer chunks of text.
                </p>
                <Link to="#">Hyperlink</Link>
              </Col>
            </Row>
          </div>
          <div className="style__button">
            <h3 className="mt-5 ">
              <span className="text-muted h5">03.</span>
              <span className="text-dark-light font-weight-bold">Buttons</span>
            </h3>
            <hr/>
            <Row>
              <Col sm="4">
                <Row>
                  <Col xs="6" className="mb-2">
                    <Button outline color="primary" className="btn-block btn-lg">Normal</Button>
                  </Col>
                  <Col xs="6" className="mb-2">
                    <Button outline color="secondary" className="btn-block btn-lg">Normal</Button>
                  </Col>
                  <Col xs="6" className="mb-2">
                    <Button color="primary" className="btn-block btn-lg">Normal</Button>
                  </Col>
                  <Col xs="6" className="mb-2">
                    <Button color="secondary" className="btn-block btn-lg">Normal</Button>
                  </Col>
                  <Col xs="6" className="mb-2">
                    <Button color="default" className="btn-block btn-lg">Normal</Button>
                  </Col>
                  <Col xs="6" className="mb-2">
                    <Button color="default" className="btn-block btn-lg">Normal</Button>
                  </Col>
                  
                </Row>

              </Col>
            </Row>
          </div>
          <div className="style__form">
            <h3 className="mt-5 ">
              <span className="text-muted h5">04.</span>
              <span className="text-dark-light font-weight-bold">Form elements</span>
            </h3>
            <hr/>
            <Row>
              <Col sm="6">
                <div className="card">
                  <div className="card-header">
                    Basic form elements
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label for="exampleEmail">Default Input</Label>
                        <Input type="text" name="input" id="input" placeholder="with a placeholder"/>
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail">Input with success</Label>
                        <Input valid/>
                        <FormText>Example help text that remains unchanged.</FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label for="examplePassword">Input with danger</Label>
                        <Input valid={false}/>
                        <FormFeedback>Oh noes! that name is already taken</FormFeedback>
                        <FormText>Example help text that remains unchanged.</FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleSelect">Select</Label>
                        <Input type="select" name="select" id="exampleSelect">
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleText">Text Area</Label>
                        <Input type="textarea" name="text" id="exampleText"/>
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleFile">File</Label>
                        <Input type="file" name="file" id="exampleFile"/>
                        <FormText color="muted">
                          This is some placeholder block-level help text for the above input. It's a bit
                          lighter and easily wraps to a new line.
                        </FormText>
                      </FormGroup>
                      <Button>Submit</Button>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <Form>
                  <FormGroup tag="fieldset">
                    <legend>Radio Buttons</legend>
                    <FormGroup check className="radio i-checks">
                      <Label check>
                        <Input type="radio" name="radio1"/>{' '}
                        <i></i>
                        Option one is this and that—be sure to include why it's great
                      </Label>
                    </FormGroup>
                    <FormGroup check className="radio i-checks">
                      <Label check>
                        <Input type="radio" name="radio1"/>{' '}
                        <i></i>
                        Option two can be something else and selecting it will deselect option one
                      </Label>
                    </FormGroup>
                    <FormGroup check disabled className="radio i-checks">
                      <Label check>
                        <Input type="radio" name="radio1" disabled/>{' '}
                        <i></i>
                        Option three is disabled
                      </Label>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup check className="checkbox">
                    <Label check className="i-checks">
                      <Input type="checkbox"/>{' '}
                      <i></i>
                      Check me out
                    </Label>
                  </FormGroup>
                  <div className="mt-5">
                    <h4>Alerts</h4>
                    <div>
                      <Alert color="primary">
                        This is a primary alert — check it out!
                      </Alert>
                      <Alert color="secondary">
                        This is a secondary alert — check it out!
                      </Alert>
                      <Alert color="success">
                        This is a success alert — check it out!
                      </Alert>
                      <Alert color="danger">
                        This is a danger alert — check it out!
                      </Alert>
                      <Alert color="warning">
                        This is a warning alert — check it out!
                      </Alert>
                      <Alert color="info">
                        This is a info alert — check it out!
                      </Alert>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="style__table">
            <h3 className="mt-5 ">
              <span className="text-muted h5">05.</span>
              <span className="text-dark-light font-weight-bold">Table</span>
            </h3>
            <hr/>
            <div className="card">
              <div className="card-header">
                <div className="d-flex heading">
                  <h5 className="">
                    Orders for</h5>
                  <Input type="select" name="entity" className="heading__entity">
                    <option value="1">Company A</option>
                    <option value="2">Company B</option>
                    <option value="3">Company C</option>
                  </Input>
                  <Link to="/order/new" className="ml-auto btn btn-primary">Create order</Link>
                </div>
              </div>
              <div className="card-body">
                <Table hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>
                        <Link to="#" className="action-icon" title="View detail"><i className="icon-eye"></i></Link>
                        <Link to="#" className="action-icon" title="Edit"><i className="icon-pencil"></i></Link>
                        <Link to="#" className="action-icon" title="Delete"><i className="icon-trash"></i></Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>
                        <Link to="#" className="action-icon" title="View detail"><i className="icon-eye"></i></Link>
                        <Link to="#" className="action-icon" title="Edit"><i className="icon-pencil"></i></Link>
                        <Link to="#" className="action-icon" title="Delete"><i className="icon-trash"></i></Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Larry</td>
                      <td>the Bird</td>
                      <td>
                        <Link to="#" className="action-icon" title="View detail"><i className="icon-eye"></i></Link>
                        <Link to="#" className="action-icon" title="Edit"><i className="icon-pencil"></i></Link>
                        <Link to="#" className="action-icon" title="Delete"><i className="icon-trash"></i></Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="style__miscellaneous">
            <h3 className="mt-5 ">
              <span className="text-muted h5">06.</span>
              <span className="text-dark-light font-weight-bold">Miscellaneous</span>
            </h3>
            <hr/>
            <Row>
              <Col sm="6" xs="12">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body p-5">
                    <p className="text-center">Are you sure you want to delete ?</p>
                    <div className="text-center">
                      <button type="button" className="btn btn-success mr-3">Confirm !</button>
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="6" xs="12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Library</li>
                  </ol>
                </nav>

                <Pagination>
                  <PaginationItem>
                    <PaginationLink previous href="#"/>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      1
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      2
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      3
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      4
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      5
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink next href="#"/>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}
export default Styleguide;