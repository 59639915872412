import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Button, Modal, ModalBody, ModalHeader, Label, ModalFooter
} from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Select from 'react-select'
import {deliveryContainer} from './container'
import EmptyMessageCard from '../IdSelector/emptyMessageCard'



class Deliveries extends React.Component {
  constructor(){
    super()
    this.setState({

    })
  }



  entity = () => {
    var list = Object
      .keys(this.props.id)
      .map(key => {
        let data = this.props.id[key]
        return (
          {value:data.id, label:data.account_details}
        )
      })
    return list
  }

  format=(status)=>{
    if (status==='pending') {
     return <span className="is-pending">{'pending'}</span>
    }
    else if (status==="cancelled"){
      return <span className="is-cancelled">{status}</span>
    }
    else if (status ==="failed"){
      return <span className="is-cancelled">{status}</span>
    }
    else{
     return <span className="is-delivered">{status}</span>
    }
  }

  formatActions=(col, row)=>{
    if(row.delivery_status==='pending' && row.delivery_type==="Direct Recharge") {
      return <span id={row.id}  className="btn btn-outline-primary btn-sm" onClick={()=>{this.props.toggleReschedule(row)}}>Reschedule</span>
    } else if(row.delivery_type==='Email Delivery' && row.delivery_status==='delivered') {
      return (
       <div>

       <span className="btn btn-outline-primary btn-sm" onClick={()=>this.props.handleResendEmail(row)}>Resend Email</span>
      </div>
      )
    }
  }


  handleBtnClick = ({target}) => {
    this.refs.nameCol.applyFilter(target.value);
  }
  render() {
  let list = this.entity()
  let selected ={value:this.props.accountId, label:this.props.accountName}
 let deliveriesList = []
 this.props.deliveriesList && this.props.deliveriesList.data.deliveries.map(key=>{
  if(key.delivery_status==='out_of_stock'){
     key.delivery_status = 'pending';
   }
   deliveriesList.push(key)
 })
    return (
      <div>
         {this.props.togglePopOver && <div>
            <DateChooser
              target={this.props.popOverId}
              popoverOpen={this.props.togglePopOver}
              {...this.props}
              />
          </div>}
         {this.props.deliveriesList===null?
            <EmptyMessageCard/>:
        <Card>
          <CardHeader>
            <div className="d-flex heading">
              <h5 className="">Deliveries for </h5>
              <Select
                className="heading__entity"
                name="entity"
                value={selected}
                onChange={this.props.change}
                options={list}
                clearable={false}
                searchable={false}
                />
              <span className="ml-auto">
                <Input type="select" name="select" onChange={this.handleBtnClick}>
                  <option value={''} defaultValue>All Deliveries</option>
                  <option value={'delivered'}>Delivered</option>
                  <option value={'pending'}>Pending</option>
                  <option value={'cancelled'}>Cancelled</option>
                  <option value={'failed'}>Failed</option>

                </Input>
              </span>

            </div>
          </CardHeader>
          <CardBody>
            <BootstrapTable pagination data={deliveriesList} hover version='4' bordered={ false }>
            <TableHeaderColumn  dataField='delivered_invoice' dataSort={true} >Invoice No</TableHeaderColumn>
              <TableHeaderColumn isKey dataField='delivered_to' dataSort={true} >Name</TableHeaderColumn>
              <TableHeaderColumn dataField='delivery_number' dataSort={true}>Email/Number</TableHeaderColumn>
              <TableHeaderColumn dataField='delivered_voucher' dataSort={true}>Voucher Type</TableHeaderColumn>
              <TableHeaderColumn dataField='delivery_on' dataSort={true} >Scheduled Date</TableHeaderColumn>
              <TableHeaderColumn ref='nameCol' filter={ { type: 'TextFilter', delay: 1000 } } dataFormat={this.format} dataField='delivery_status'>Status</TableHeaderColumn>
              <TableHeaderColumn dataFormat={ this.formatActions}>Action</TableHeaderColumn>
            </BootstrapTable>
          </CardBody>
        </Card>}
      </div>
    );
  }
}

export default deliveryContainer(Deliveries)


class DateChooser extends React.Component {

  state={
    date:moment().utcOffset('-3:45')
  }

  handleChange=(date)=>{
    this.setState({
      date:date
    })
  }

  handleCancel=()=>{
    this.props.toggleDatePicker()
  }
  handleSubmit=(e)=>{
    e.preventDefault();

    this.props.getApi({
      url:`/accounts/${this.props.accountId}/deliveries/${this.props.popOverId}/reschedule`,
      method:'POST',
      body: {delivery:{delivery_date:this.state.date.format('YYYY/MM/DD')}},
      successActionName:' ',
      errorActionName:' ',
      showErrorMessage:true,
      errorMessage:{
        type:'error',
        title:'Could not reschedule delivery.'
      },
      back:true,
      showSuccessMessage:true,
      successMessage:{
        type:'success',
        title:'Delivery has been rescheduled.'
      },
    });
    this.props.toggleDatePicker()
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.props.popoverOpen}  toggle={this.props.toggle}>
          <ModalHeader>Picker new date.</ModalHeader>
          <ModalBody>
            <Label>Please pick new date.</Label>
            <div>
              <DatePicker
                selected={this.state.date}
                onChange={this.handleChange}
                minDate={moment()}
                value={this.state.date.format('YYYY/MM/DD')}
                dateFormat="LLL" />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="pull-right">
              <Button color={'default'} className="mr-2" onClick={this.handleCancel}>Cancel</Button>
              <Button color={'primary'} className="mr-2" onClick={this.handleSubmit} >Reschedule Date</Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
