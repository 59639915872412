import React, {Component} from 'react';
import {logoutUser} from '../../../api/auth/api.logout';
import {connect} from 'react-redux';
import {getApi} from '../../../api/api'
import {bindActionCreators} from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'
import {showProfile} from '../../../api/user_profile/profile.show'
import {fetchAccounts} from '../../../api/account_service/api.show.accounts';


export function dashboardContainer(WarpperComponent) {
  class DashboardContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        first_name:'',
        last_name: '',
        load:false,
        messageSetting:'',
        loadingSetting:true,
      }
    }

    /**
     * check login to redirect to password change or dashboard.
     */
    componentWillMount() {
      if(this.props.location.state!==undefined){
        this.setState({
          load:true
        })
      }
      /**
       * @description call show profile api.
       */
      this.props.showProfile();

      /**
       * @description load billing address from api
       */
      this.props.fetchAccounts();
      this.fetchSetting();
      //eslint-disable-next-line
      window.localStorage.getItem('need_password_change')=="true" ? this.props.history.push('/dashboard/passwordreset')  : null
      this.props.removeNotification('LoginError');
    }


    fetchSetting=()=>{
      this.props.getApi({
        url:'settings',
        method:'get',
        successActionName:'FETCH_VAT_SUCCESS',
        errorActionName:'FETCH_VAT_FAIL',
        showSuccessMessage:false,
        showErrorMessage:false,
      })
    }

    /**
     * 
     * @param nextProps.profile watch profile get. 
     */
    componentWillReceiveProps(nextProps) {
      if (nextProps.profile.showSuccess === true) {
        let user = nextProps.profile.showData
        this.setState({
          loading: true,
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          mobile_number: user.mobile_number
        })
      }

      /**
       * @description watch billing address fetch and set first to default in dropdown.
       */
      if( nextProps.showAccount.isSuccess===true && this.state.load && nextProps.showAccount.data.length!==0) {
          this.setState({load:false})
          localStorage.setItem('aid',nextProps.showAccount.data[0].id)
          localStorage.setItem('accountName', nextProps.showAccount.data[0].account_details)
      }

      if(nextProps.setting.vatFetch){
        let {setting} = nextProps.setting.vatDetail.data
        this.setState({
          messageSetting:setting,
          loadingSetting:false
        })
      }
    }

   
    /**
     * @description render the view.
     */

    render() {
      if(this.state.loadingSetting){
        return <div></div>
      } else {
      return (
        <div><WarpperComponent setting={this.state.messageSetting}/></div>
      );
    }
   }
  }

  /**
   * @description redux method
   */

  function mapDispatchToProps(dispatch) {
    let addNotification = toastrActions.add;
    let removeNotification = toastrActions.remove;
    return bindActionCreators({
      logoutUser,
      addNotification,
      showProfile,
      removeNotification,
      fetchAccounts,
      getApi
    }, dispatch)
  }

  /**
   * 
   * @param {*} state provide by redux 
   */
  function mapStateToProps(state) {
    return {auth: state.auth, token: state.token, profile: state.profile, showAccount:state.showAccount, setting:state.promotions}
  }
  return connect(mapStateToProps, mapDispatchToProps,)(DashboardContainer);
}
