/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'


export function updatePassword(id, data) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}/users/${id}`
    let option={
      method: 'PUT',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              },
      body:JSON.stringify(data)
    };
    return fetch(url, option)
    .then(response => response.json().then(updatedPassword=>({updatedPassword, response})))
    .then(({updatedPassword, response})=>{
      if(!response.ok){
        return Promise.reject(updatedPassword)
      } else {
        dispatch(receiveUpdatePassword(updatedPassword));
      }})
    .catch(err => dispatch(passwordUpdateError(err)))
   }
 }




function receiveUpdatePassword(data) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    updateData:data
  }

}


function passwordUpdateError(err) {
  return {
    type: UPDATE_PASSWORD_FAIL,
    updateError:err
  }
}



