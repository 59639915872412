import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import {store, history} from './store';
// import SimpleLineIcon from 'react-simple-line-icons';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './container/App';
import './assets/stylesheet/vendor/react-select.css';
import './assets/stylesheet/main.css'
import './assets/stylesheet/alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/stylesheet/vendor/react-bootstrap-table.min.css';
import ReduxToastr from 'react-redux-toastr';
import './assets/stylesheet/vendor/react-switch.min.css';

import registerServiceWorker from './registerServiceWorker';


/**
 * @constant Redux-toaster is plugin for flash message
 * 
 */
ReactDOM.render(
   <Provider store={store}>
      <Router history={history}>
      <div>
         <App />
         <ReduxToastr
          timeOut={4000}
          newestOnTop={true}
          //preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          //options={{removeOnHover:true}}
          />
      </div>      
      </Router>
  </Provider>, document.getElementById('root'));
registerServiceWorker();

//TODO:: make sure all the console.log() are remove
//TODO:: Clean all the unnecessay import on Component header.
//TODO:: make sure every map object has a unique key
