import React from "react";
import { connect } from "react-redux";
import { fetchAccounts } from "../../../../api/account_service/api.show.accounts";
import { listMobileno } from "../../../../api/entity_mobileno/api.show.mobileno";
import { addMobileNo } from "../../../../api/entity_mobileno/api.create.mobileno";
import { indexProducts } from "../../../../api/product/api.index.product";
import Loader from "../../loading/loader";
import { bindActionCreators } from "redux";
import { actions as toastrActions } from "react-redux-toastr";
import { updateOrder } from "../../../../api/order_service/api.update.order";
import { orderItems } from "../../../../api/order_service/api.items.order";
import _ from "lodash";
import { getApi } from "../../../../api/api";

export function createOrderContainer(Component) {
  class Container extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        aid: window.localStorage.getItem("aid"),
        accountEmail: "",
        accountLoading: true,
        oloading: true,
        mloading: true,
        ploading: true,
        numberList: {},
        product: {},
        email: "",
        orderList: [],
        accountId: window.localStorage.getItem("aid"),
        accountName: window.localStorage.getItem("accountName"),
        updateOrder: false,
        calculateTotalPrice: true,
        totalPrice: 0,
        amount: 0,
        credit: 0.0,
        discountID: null,
        discountRate: null
      };
    }

    getSingleAccount = () => {
      this.props.getApi({
        url: `accounts/${this.state.accountId}`,
        method: "GET",
        successActionName: "GET_ACCOUNT_DETAIL_SUCCESS",
        errorActionName: "GET_ACCOUNT_DETAIL_FAIL",
        showErrorMessage: false,
        showSuccessMessage: false
      });
    };

    componentWillMount() {
      this.props.orderItems(this.state.accountId, this.props.match.params.id);
    }

    loadMobileNumber = () => {
      this.props.listMobileno(this.state.billingId);
    };

    componentDidMount() {
      this.props.fetchAccounts();
      this.props.listMobileno(this.state.accountId);
      this.props.indexProducts();
      this.getSingleAccount();
    }

    getDiscountId = (discountID, discountRate) => {
      this.setState({ discountID: discountID, discountRate: discountRate });
    };

    calculateProductPrice = () => {
      if (this.state.orderList.length !== 0 && this.state.product !== {}) {
        var itemPrice = 0;
        //eslint-disable-next-line
        this.state.orderList.map(key => {
          var price = _.findKey(this.state.product, {
            value: key.voucher_type
          });
          itemPrice =
            itemPrice + this.state.product[price].price * key.voucher_quantity;
        });
        this.setState({
          totalPrice: (itemPrice * 1.0) / 100,
          calculateTotalPrice: false
        });
      } else {
        this.setState({
          totalPrice: 0,
          calculateTotalPrice: false
        });
      }
    };

    componentWillReceiveProps(nextProps) {
      if (nextProps.billingDetail.listSingleAccountSuccess) {
        this.setState({
          amount: nextProps.billingDetail.singleAccountData.available_funds,
          credit: nextProps.billingDetail.singleAccountData.available_credit
        });
      }

      if (nextProps.order.updateSuccess === true && this.state.updateOrder) {
        this.setState({
          updateOrder: false,
          saveDraftRequest: false,
          proceedOrderRequest: false
        });
        if (nextProps.order.updateData.order.order_status === "draft") {
          this.props.notification({
            title: "Your Order has been saved",
            type: "success",
            options: { showCloseButton: true, removeOnHover: true }
          });
          this.props.history.push("/orders");
        } else {
          let { history } = this.props;
          history.push({
            pathname: "/order/thankyou",
            state: {
              type: nextProps.order.updateData.order_placed,
              email: this.state.accountEmail
            }
          });
        }
      }
      if (nextProps.order.itemsSuccess === true) {
        let items = nextProps.order.itemsData.data.order.order_items;
        this.setState({
          oloading: false,
          orderList: items
        });
      }

      if (nextProps.showAccount.isSuccess === true) {
        //eslint-disable-next-line
        Object.keys(nextProps.showAccount.data).map(key => {
          let account = nextProps.showAccount.data[key];
          //eslint-disable-next-line
          if (account.id == this.state.accountId) {
            this.setState({
              accountEmail: account.account_email,
              accountLoading: false
            });
          }
        });
      }

      if (nextProps.mobileno.listSuccess === true) {
        var numbers = [];
        //eslint-disable-next-line
        Object.keys(nextProps.mobileno.listData.numbers).map(key => {
          let number = nextProps.mobileno.listData.numbers[key];
          if (number.active === true) {
            numbers.push({
              value: number.mobile_number,
              label: `${number.associated_name} | ${number.mobile_number}`,
              date: `${number.last_recharge_date}`,
              delivery_person: `${number.associated_name}`
            });
          }
        });
        this.setState({ numberList: numbers, mloading: false });
      }

      if (nextProps.products.isSuccess === true) {
        var list = Object.keys(nextProps.products.data).map(key => {
          let data = nextProps.products.data[key];
          return {
            value: data.product_name,
            label: data.product_name,
            price: data.product_price
          };
        });
        this.setState({
          ploading: false,
          product: list
        });
      }
    }

    editOrder = (data, index) => {
      var items = this.state.orderList;
      items.splice(index, 1);
      items.splice(index, 0, data.orderItems);
      this.setState({
        orderList: items,
        calculateTotalPrice: true
      });
    };

    addOrder = data => {
      var items = this.state.orderList;
      items.push(data);
      this.setState({ order: items, calculateTotalPrice: true });
    };

    getBillId = e => {
      let data = document.getElementById(e.target.value);
      window.localStorage.setItem(data.dataset.email, data.dataset.email);
      this.setState({
        aid: e.target.value,
        email: data.dataset.email,
        order: []
      });
      this.props.fetchAccounts();
      this.props.listMobileno(e.target.value);
    };

    saveDraft = e => {
      e.preventDefault();
      if (this.state.orderList.length === 0) {
        this.props.notification({
          title: "Order items canot be blank",
          type: "error",
          options: { showCloseButton: true, removeOnHover: true }
        });
      } else {
        this.setState({ updateOrder: true });
        let order = {
          order: {
            order_items_attributes: this.state.orderList.map(item => {
              delete item.delivery_person;
              return item;
            }),
            order_status: "draft"
          }
        };
        this.props.updateOrder(
          this.state.aid,
          this.props.match.params.id,
          order
        );
      }
    };

    processOrder = e => {
      e.preventDefault();
      if (this.state.orderList.length === 0) {
        this.props.notification({
          title: "Order items canot be blank",
          type: "error",
          options: { showCloseButton: true, removeOnHover: true }
        });
      } else {
        this.setState({ updateOrder: true });
        let order = {
          order: {
            order_items_attributes: this.state.orderList.map(item => {
              delete item.delivery_person;
              return item;
            }),
            order_status: "pending",
            promotion_id: this.state.discountID ? this.state.discountID : null,
            discount_amount: this.state.discountRate
          }
        };
        this.props.updateOrder(
          this.state.aid,
          this.props.match.params.id,
          order
        );
      }
    };

    handleDeleteOrderItems = index => {
      let { orderList } = this.state;
      orderList.splice(index, 1);
      this.setState({ orderList: orderList, calculateTotalPrice: true });
    };
    render() {
      if (this.state.calculateTotalPrice) {
        this.calculateProductPrice();
        return 0;
      }
      return (
        <div>
          {!this.state.accountLoading &&
          !this.state.oloading &&
          !this.state.mloading &&
          !this.state.ploading ? (
            <Component
              bill={this.props.showAccount.data}
              getBillId={this.getBillId}
              getInitialNumber={this.getInitialNumber}
              saveDraft={this.saveDraft}
              processOrder={this.processOrder}
              {...this.state}
              {...this.props}
              addOrder={this.addOrder}
              calculateProductPrice={this.calculateProductPrice}
              editOrder={this.editOrder}
              getDiscountId={this.getDiscountId}
              handleDeleteOrderItems={this.handleDeleteOrderItems}
              loadMobileNumber={this.loadMobileNumber}
            />
          ) : (
            <div className="loader">
              <Loader height={80} width={80} />
            </div>
          )}
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    let notification = toastrActions.add;
    return bindActionCreators(
      {
        fetchAccounts,
        listMobileno,
        addMobileNo,
        notification,
        indexProducts,
        updateOrder,
        orderItems,
        getApi
      },
      dispatch
    );
  }

  function mapStateToProps(state) {
    return {
      showAccount: state.showAccount,
      mobileno: state.mobileno,
      products: state.indexProducts,
      billingDetail: state.UpdateAccount,
      order: state.order
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Container);
}
