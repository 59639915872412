/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
import {actions as toastrActions} from 'react-redux-toastr'
import { REMOVE_MOBILENO_SUCCESS, REMOVE_MOBILENO_FAIL } from '../../action/mobile_no';

export function deleteMobileno(aid, mid) {
  return dispatch => {
    const token = localStorage.getItem('access_token')
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}/${aid}/numbers/${mid}`
    let option={
      method: 'DELETE',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }       
    }
    return fetch(url, option)
    .then(response => response.json().then(deleteMobileno=>({deleteMobileno, response})))
    .then(({deleteMobileno, response})=>{
     if (!response.ok) {
      if(response.status===401){
        window.localStorage.clear();
        window.location.reload();
      }
      deleteMobileno.status = response.status
       return Promise.reject(deleteMobileno)
         } else {
          dispatch(toastrActions.add({
            type:'success',
            title:`Delete successfull.`,
            message:"Recharge number has been successfully deleted.",
            options:{showCloseButton:true, removeOnHover:true},
            timeOut:4000
          }))  
         dispatch(receivedeleteMobileno(deleteMobileno.data))
    }})
    .catch(err => {
      dispatch(deleteMobilenoError(err))
      dispatch(toastrActions.add({
        type:'error',
        title:`Error Deleting Number`,
        message:err.errors.message,
        options:{showCloseButton:true, removeOnHover:true},
        timeOut:4000
      }))
    })
  }
}



function receivedeleteMobileno(data) {
  return {
    type: REMOVE_MOBILENO_SUCCESS,
    deleteData:data
  }
}

function deleteMobilenoError(err) {
  return {
    type: REMOVE_MOBILENO_FAIL,
    deleteErrorData:err
  }
}



