const pickMobileNumber = numberDetail => numberDetail.mobile_number
const pickOrderItemMobileNumber = orderItem => orderItem.deliver_to_number
const pickLastRechargeDate = numberDetail => numberDetail.last_recharge_date

export const getLastRechargeDate = (orderItem, numberDetails) => {
  const orderItemMobileNumber = pickOrderItemMobileNumber(orderItem)
  const numberDetail = numberDetails.find(
    number => pickMobileNumber(number) === orderItemMobileNumber,
  )
  const lastRecharge = numberDetail ? pickLastRechargeDate(numberDetail) : false
  return lastRecharge
}
