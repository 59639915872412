import React, { Component } from "react";
import { Card, CardBlock } from "reactstrap";
import { Link } from "react-router-dom";

class EmptyMessageCard extends Component {
  render() {
    return (
      <div>
        <Card className="text-center">
          <CardBlock className="card-body">
            <blockquote className="card-bodyquote">
              <p>Please add an account before proceeding further.</p>
              <Link to="/account/new" className="btn btn-primary">
                Add Account Here
              </Link>
            </blockquote>
          </CardBlock>
        </Card>
      </div>
    );
  }
}

export default EmptyMessageCard;
