import {UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL} from '../../api/auth/passwordReset'
export function changePassword(state = {
		isForgetSuccess: false

}, action) {
		switch (action.type) {

				case UPDATE_PASSWORD_SUCCESS:
						return Object.assign({}, state, {
								isRequested: false,
								isResetSuccess: true,
								hasError: false,
								data: action.updateData
						})

				case UPDATE_PASSWORD_FAIL:
						return Object.assign({}, state, {
								isRequested: false,
								isResetSuccess: false,
								hasError: true,
								errorMessage: action.updateError
						})

				default:
						return state;

		}
}
