/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import { Tooltip } from 'reactstrap';

export default class Tooltips extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <span>
        <i className={this.props.icon} id={this.props.id}></i>
        <Tooltip placement="top" isOpen={this.props.tooltipOpen} target={this.props.id} toggle={this.toggle}>
          {this.props.message}
        </Tooltip>
      </span>
    );
  }
}