import React from 'react';
import {connect} from 'react-redux';
import {fetchAccounts} from '../../../../api/account_service/api.show.accounts';
import {deleteBill} from '../../../../api/account_service/api.delete.accounts';
import Loader from '../../loading/loader';
import {bindActionCreators} from 'redux';
import {actions as toastrActions} from 'react-redux-toastr'

export function showBillContainer(Component) {
  class RegisterContainer extends React.Component {
    constructor(props) {
      super(props);
      //required state from component.
      this.state = {
        loading: true,
        deleteStart: false,
        modal:false,
        deleteBillId:'',
        accountCount:''
      }
    }

    //fetch the billing address before component mound
    componentWillMount() {
      this.props.fetchAccounts()
    }

    //if success will receive flag and data
    componentWillReceiveProps(nextProps) {
      if (typeof nextProps.showAccountFromStore.isSuccess !== undefined && nextProps.showAccountFromStore.isSuccess === true) {
        this.setState({loading: false, accountCount:nextProps.showAccountFromStore.data.length})
      }
      // if deleted will recive success message
      if(nextProps.delete.deleteSuccess && this.state.deleteStart){
        this.setState({deleteStart:false})
        this.props.notification({
            id: 'Delete565327',
            title: 'Account has been deleted successfully',
            type: 'success',
            options: {
              showCloseButton: true,
              removeOnHover:true
            },
            preventDuplicates: true
          })
          this.props.fetchAccounts() 
      }
      //if delete was unsuccessfull then flash messsage is trigger.
      if (nextProps.delete.deleteError === true && this.state.deleteStart === true) {
        this.setState({deleteStart:false})
        this.props.notification({
            message:nextProps.delete.deleteErrorData.message,
            type: 'error',
            options: {
              showCloseButton: true,
              removeOnHover:true
            },
            preventDuplicates: true
          })
      }

    }

    // enable modal before delete.    
    handleConfirmDelete=(data)=>{
      this.setState({
        modal:true,
        deleteBillId:data
      })
    }

    //close delete confirm model
    closeModal=(e)=>{
      e.preventDefault();
      this.setState({
        modal:false
      })
    }

    // delete api was called.
    handleDelete = (e) => {
      e.preventDefault()
      this.setState({
        modal:false,
        deleteStart: true,
      })
      this.props.deleteBill(this.state.deleteBillId)

    }

    // render view if billing address was loaded from api.
    render() {
      return (
        <div>
          {(this.state.loading)
            ? <div className='loader'><Loader height={80} width={80}/></div>
            : <Component data={this.props.showAccountFromStore.data} 
             deleteConfirm={this.handleConfirmDelete}
             close={this.closeModal}
             delete={this.handleDelete}
             {...this.state}/>}
        </div>
      );
    }

  }

  function mapDispatchToProps(dispatch) {
    let notification = toastrActions.add;
    return bindActionCreators({
      deleteBill,
      notification,
      fetchAccounts
    }, dispatch)
  }

  function mapStateToProps(state) {
    return {showAccountFromStore: state.showAccount, delete: state.UpdateAccount }
  }

  return connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)

}
