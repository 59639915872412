import React from 'react';
import {indexProductsContainer} from './indexContainer';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import AwehGig from './../../../assets/images/packages/AWEH_Gig.jpg';
import Offer from '../../../assets/images/packages/offer.png'
import SelectAccountModal from '../common/modal'
import PromotionSlider from './slider'

function offer(data){
    return data.map(key=>{
      return{
        altText:key.alt_text,
        caption:key.caption_text,
        header:key.caption_header,
        src:key.image.url
      }})
}


class Packages extends React.Component {
  state={
    modal:false,
    data:[]
  }

  toggle=(data)=>{
    this.setState({
      modal:!this.state.modal,
      data:data
    })
    this.props.history.push('/')
  }

  handleClick=(data)=>{
    this.toggle(data)

  }

  handleProducts=()=>{
    return Object.keys(this.props.data).map(key=>{
      let data= this.props.data[key];

      return(
        <Card key={key} className="package_card">
          <CardBody className="package-body">
            <div className="">
              <Row>
          { Object.keys(data.products_list).map(key=>{
            let product = data.products_list[key]
            return(
              <Col md="3" xl="3" sm="3" key={key} xs="12" id="">
                <div className="packages__item mb-4 ">
                  <div className="package__image" >
                    <Link  to={{ pathname:`/package/${product.product_name}`, state:{data:product, options:this.props.billingList }}}>
                      <img src={product.image.url?product.image.url:AwehGig} alt="AwehGig" className="img-fluid "/>
                    </Link>
                  </div>
                  <div className="package__content ">
                    <div className="package__action d-flex justify-content-center">
                      <span onClick={()=>{this.handleClick(product)}} className="d-block">Buy</span>
                      <Link  to={{ pathname:`/package/${product.product_name}`, state:{data:product, options:this.props.billingList }}} className=" d-block">Details</Link>

                    </div>
                  </div>
                </div>
              </Col>
          )})}
              </Row>
            </div>

          </CardBody>
        </Card>
        )
    })
  }
  render() {
    let list = this.handleProducts();
    let Promotion = offer(this.props.offerAndPromotion)
    return (

      <div className="packages" >
      {Promotion.length!==0?
        <div className="promotion mb-4">
        {Promotion.length>1
          ?<PromotionSlider image = {Promotion}/>
          :<div>
          <img src={Promotion[0].src} width="100%"/>
          </div>
        }
        </div>:null}
       <SelectAccountModal
          modal={this.state.modal}
          toggle={this.toggle}
          options={this.props.billingList}
          productName={this.state.data.product_name}
          {...this.props}
        />


      {list}
      </div>
    );
  }
}

export default indexProductsContainer(Packages)

//      {this.props.offerAndPromotion.length===0?null:Promotion}




