import React from 'react';
import Logo from './../../../../assets/images/airtime_logo.png';

class InvoiceTemplate extends React.Component {

  render() {
    return (
      <div className="container l-invoice">
        <div className="invoice">
          <div className="row">
            <div className="col-6 ">
              <div className="invoice__logo">
                <img src={Logo} alt="TimeSlick logo" className="img-fluid "/>
              </div>
              <address>
                <div className="text-uppercase">
                  <strong>DIGITAL TRAVERSE TECHNOLOGIES CC</strong><br/>
                  P.O.BOX 5820<br/>
                  WINDHOEK<br/>
                  NAMIBIA <br/>
                </div>
                brandthdl@digitaltraverse.com
              </address>
              <br/>
              <address>
                <div className="text-muted">Bill To:</div>
                <div className="text-uppercase">
                  <strong>SIMONDEUM INVESTMENTS (PTY) LTD</strong><br/>
                  T/A SIMONDEUM FINE MEATS<br/>
                  P.O. BOX 235<br/>
                  GROOTFONTEIN <br/>
                  NAMIBIA
                </div>
              </address>

            </div>
            <div className="col-6">
              <h1 className="document-type display-4 text-uppercase">Invoice</h1>
              <p className="text-right">#410</p>
              <br/>
              <div className="pull-right">
                <div className="invoice__info">
                  <div className="invoice__label text-muted">
                    Date:
                  </div>
                  <div className="invoice__detail">
                    Jan 9, 2018
                  </div>
                </div>
                <div className="invoice__info">
                  <div className="invoice__label text-muted">
                    Due Date:
                  </div>
                  <div className="invoice__detail">
                    Jan 30, 2018
                  </div>
                </div>
                <div className="invoice__info bg-default">
                  <div className="invoice__label">
                    <strong> Balance Due:</strong>
                  </div>
                  <div className="invoice__detail">
                    <strong>N$555</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong> TANGO VIRTUAL VOUCHER N$295</strong></td>
                <td>3</td>
                <td>N$ 295</td>
                <td className="">N$ 885</td>
              </tr>
              <tr>
                <td><strong> TANGO VIRTUAL VOUCHER N$295</strong></td>
                <td>3</td>
                <td>N$ 295</td>
                <td className="">N$ 885</td>
              </tr>              
              <tr>
                <td><strong> TANGO VIRTUAL VOUCHER N$295</strong></td>
                <td>3</td>
                <td>N$ 295</td>
                <td className="">N$ 885</td>
              </tr>

            </tbody>
          </table>
          <div className="row">
            <div className="col-8">
            </div>
            <div className="col-4">
              <table className=" table-sm text-right">
                <tr>
                  <td><span className="text-muted"> Sub Total:</span></td>
                  <td className="text-right">N$ 885</td>
                </tr>
                <tr>
                  <td><span className="text-muted"> Total</span></td>
                  <td className="text-right">N$ 885</td>
                </tr>
              </table>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <p className="invoice__notes">
            <span className="text-muted"> Notes: </span>
            <br/>
            HB DIGITAL TRAVERSE CC
            <br/>
            BANK WINDHOEK
            <br/>
            481-972
            <br/>
            8004332780
            <br/><br/>
            Please use your Invoice Number as your payment reference.
          </p>
          
          <br/>
          <br/>
          <br/>
          <br/>
          
          <p className="bottom-page">
            <div className="text-muted"> Terms:</div>
             Delivery will take place by means of electronic medium(s). Risk of loss transfers to the buyer upon delivery of
             goods (confirmed or unconfirmed) and irrespective of validity of the voucher(s) (in respect whereof the seller
             provides no guarantees, warranties or assurances of whatsoever nature). No disputes will be entertained under any
             circumstances and the purchaser shall have no claim whatsoever against the seller in respect of the
             aforementioned. The purchaser, by paying this invoice (irrespective of means) agrees to and accepts the terms
             herein embodied, and further agrees to be bound thereby. Strictly EFT Payments only.
          </p>
        </div>
      </div>
    );
  }
}

export default InvoiceTemplate
