import {SHOW_ACCOUNT_REQUEST, SHOW_ACCOUNT_SUCCESS, SHOW_ACCOUNT_FAILURE} from '../../api/account_service/api.show.accounts';



export function showAccount(state = {
		isInit: true

}, action) {
		switch (action.type) {
				case SHOW_ACCOUNT_REQUEST:
				
						return Object.assign({}, state, {
								isRequested: true,
								isRegister: false,
								hasError: false,
								// message:'Requesting'
						})

				case SHOW_ACCOUNT_SUCCESS:
				
						return Object.assign({}, state, {
								isRequested: false,
								isSuccess: true,
								hasError: false,
								data: action.data
						})

				case SHOW_ACCOUNT_FAILURE:
						return Object.assign({}, state, {
								isRequested: false,
								isRegister: false,
								hasError: true,
								errorMessage: action.err
						})

				default:
						return state;

		}
}