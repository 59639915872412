import {SHOW_PROFILE_SUCCESS, SHOW_PROFILE_FAIL} from '../../action/profile';
export function showprofile(state = {
		fetch: true,
		showSuccess: false,
		showError: false
}, action) {
		switch (action.type) {

				case SHOW_PROFILE_SUCCESS:
						{
								return Object.assign({}, state, {
										fetch: false,
										showSuccess: true,
										showError: false,
										showData: action.data.users,
										showErrorData: null
								})
						}

				case SHOW_PROFILE_FAIL:
						{
								return Object.assign({}, state, {
										fetch: false,
										showSuccess: false,
										showError: true,
										showData: null,
										showErrorData: action.showError
								})
						}

				default:
						return state;
		}

}
