/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
export const SHOW_PRODUCT_SUCCESS = 'SHOW_PRODUCT_SUCCESS'
export const SHOW_PRODUCT_FAILURE = 'SHOW_PRODUCT_FAILURE'



export function indexProducts() {
  return dispatch => {
    const token = localStorage.getItem('access_token');
    let url = `${api.apiUrl}${api.version}${api.resource.index_products}`
      return fetch(url, {
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
              },
    })
     .then(response => response.json().then(products=>({products, response})))
     .then(({products, response})=>{
      if (!response.ok) {
        if(response.status===401){
          window.localStorage.clear();
          window.location.reload();
        }
        products.status = response.status
        return Promise.reject(products)
          } else {
          dispatch(receiveProducts(products.data.products))
     }})
     .catch(err => dispatch(indexProductError(err)))
      }
    }

function receiveProducts(data) {
  return {
    type: SHOW_PRODUCT_SUCCESS,
    data
  }
}

function indexProductError(err) {
  return {
    type: SHOW_PRODUCT_FAILURE,
    err
  }
}


