import {combineReducers} from "redux";
import {reducer as toastrReducer} from 'react-redux-toastr'

//list of auth register on the folder auth
import {auth} from './auth/auth.reducer';
import {register} from './auth/register.reducer';
import {apiToken} from './auth/apiToken.reducer';
import {createAccount} from './account_service/create.account.reducer';
import {showAccount} from './account_service/show.account.reducer';
import {createDeposits} from './deposit_service/create.deposit.reducer';
import {showDeposits} from './deposit_service/show.deposit.reducer';
import {deposit} from './deposit_service/index'
import {billing} from './account_service/index';
import {order} from './order_service/index'
import {profile} from './user_update/index'
import {registerConfirm} from './auth/registerConfirm.reducer'
import {forgetPassword} from './auth/forgetpassword.reducer'
import {resetPassword} from './auth/resetPassword'
import {showprofile} from './user_update/show'
import {indexProducts} from './product/index.product.reducer'
import {mobileno} from './mobile_no_service/index'
import {changePassword} from './auth/apiPasswordChange.reducer'
import {changeUserPassword} from './user_update/changePassword'
import {invoice} from './invoice_service/index'
import {deliveries} from './deliveries/index'
import {creditNote} from './creditNote'
import {statement} from './statement'
import {sync} from './syncReducer'
import {promotions} from './creditNote'

/*
*
Contain all the reducer and are combine by following API.
*
*/
const rootReducer = combineReducers({
  /*
* All reducers and there respective name.
*
* All the auth Reducer
*/
  toastr: toastrReducer,
  auth, //reduce login data to the store
  apiToken, // this map the token to the store
  register, //reduce the register data to the store.
  createAccount, //will create bill
  showAccount, //listing billing address
  showDeposits,
  createDeposits,
  UpdateAccount: billing,
  order,
  profile,
  registerConfirm,
  forgetPassword,
  resetPassword,
  showprofile,
  indexProducts,
  mobileno,
  deposit,
  changePassword,
  changeUserPassword,
  invoice,
  deliveries,
  creditNote,
  statement,
  sync,
  promotions

/*
*
*/

});
export default rootReducer;
