import {UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL} from '../../action/profile';

export function profile(state = {
		fetch: true,
		showSuccess: false,
		showError: false,
		updateSuccess: false,
		updateError: false
}, action) {
		switch (action.type) {

				case UPDATE_PROFILE_SUCCESS:
						{
								return Object.assign({
										fetch: false,
										updateSuccess: true,
										updateError: false,
										updateData: action.updateData,
										updateErrorData: null,
										...state
								})
						}

				case UPDATE_PROFILE_FAIL:
						{
								return Object.assign({}, state, {
										fetch: false,
										updateSuccess: false,
										updateError: true,
										updateData: null,
										updateErrorData: action.updateError
								})
						}

				default:
						return state;
		}

}
