/**
 *@description refer src/api/api.js. same as that 
 */
import api from "../../config/api.js";
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

// Calls the API to get a token and
// dispatches actions along the way
export function loginUser(cred) {
  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    let url = `${api.apiUrl}${api.version}${api.resource.login}`
      return fetch(url, {
      method: 'post',
      content:'application/json',
      body: JSON.stringify(cred)})
     .then(response => response.json().then(user => ({ user, response })))
     .then(({ user, response }) =>  {
            if (!response.ok) {
            user.status=response.status;
            return Promise.reject(user)
              } else {
              localStorage.setItem('access_token', user.data.token)
              localStorage.setItem('avatar_url',user.data.user.avatar_url)
              localStorage.setItem('user_id',user.data.user.id)
              localStorage.setItem('user_first_name',user.data.user.first_name)
              localStorage.setItem('user_last_name',user.data.user.last_name)
              localStorage.setItem('need_password_change',user.data.user.need_password_change)
              dispatch(receiveLogin(user.data.user))
            }
          }).catch(err => dispatch(loginError(err)))
      }
    }

function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    user:user
  }
}

function loginError(err) {
  return {
    type: LOGIN_FAILURE,
    err
  }
}
