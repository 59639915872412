//import api configuration.
import api from "../config/api.js";
// tosterActions import that fro toaster package.
import {actions as toastrActions} from 'react-redux-toastr'

/**
 * 
 * @param data must be structured component as follow.
 * @param {url, body, method, successActionName, errorActionName} data 
 * @param url handle api url to call.
 * @param body data to pass in api.
 * @param method set HTTP verbs like post, get delete and put
 * @param successActionName reducer action name to fire when success
 * @param errorActionName reducer action name to fire when error occure.
 * @param showErrorMessage bool- true/ false show flash after api response.
 * @param showSuccessMessage bool - true/false show flash message if failure occure.
 * @param {title, message(overRide api response messsed if passed), type} errorMessage node are displayed inside flash.
 * @param {title, message, type} successMessage are displayed in flash.
 */
export function getApi(data) {
  return dispatch => {
    /**
     * @description setting up api url.
     */
    let url = `${api.apiUrl}${api.version}/${data.url}`
    /**
     * @description setting up option also call header.
     */
    let option = { 
        method: `${data.method}`,
        headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+localStorage.getItem('access_token')
                },
        }
    //determining whether to send body or not.In get request there is no body.    
    if(data.method!=='GET' || data.method!=='get'){
        option.body = JSON.stringify(data.body) 
    }

    /**
     * @description fetch api is used to call api.
     * @param url url to call.
     * @param option header and else in ajax call
     */
    return fetch(url, option)
       .then(response => response.json().then(dataReceived=>({dataReceived, response})))
       .then(({dataReceived, response})=>{
      /**
       * @description if status value in response is 401 reset localStorage and back to login page.
       */ 
        if(response.status===401){
          window.localStorage.clear();
          window.location.reload();
        }
         if(!response.ok){
            dataReceived.status = response.status
            //promise is rejected.
           return Promise.reject(dataReceived)
         } else {
           //success action was dispatched.
           dispatch({type:data.successActionName, response:dataReceived}); 
           if(data.showSuccessMessage){
             // flash toster was fired.
             dispatch(toastrActions.add({
               type:data.successMessage.type,
               message:data.successMessage.message,
               title:data.successMessage.title,
               options:{showCloseButton:true, removeOnHover:true},
               timeOut:4000
              }))
           }  
         }})
         //catch if error occure
       .catch(err => {
         //dispatch redux action as error
          dispatch({type:data.errorActionName, response:err})
          if(data.showErrorMessage){
          //flag check to determine weather to fire flash message UI
            let message = typeof err.errors.message!==undefined?err.errors.message:data.errorMessage.message
          //if true fire flash message.
            dispatch(toastrActions.add({
            type:data.errorMessage.type,
            title:`${data.errorMessage.title}`,
            message:message,
            options:{showCloseButton:true,removeOnHover:true},
            timeOut:4000
          }))
          } 
       })
      }
    }  

