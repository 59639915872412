import {REGISTER_SUCCESS, REGISTER_FAILURE} from '../../api/auth/api.register';

export function register(state = {
		isRequested: false,
		isRegister: false,
		hasError: false
}, action) {
		switch (action.type) {

				case REGISTER_SUCCESS:
						return Object.assign({}, state, {
								isRequested: false,
								isRegister: true,
								hasError: false,
								data: action.res
						})

				case REGISTER_FAILURE:
						return Object.assign({}, state, {
								isRequested: false,
								isRegister: false,
								hasError: true,
								errorMessage: action.err
						})

				default:
						return state;

		}
}