/**
 *@description refer src/api/api.js. same as that. 
 * Toster flash was fire from component will mount hook not here 
 * in this case
 */
import api from "../../config/api.js";
export const SHOW_ACCOUNT_REQUEST = 'SHOW_ACCOUNT_REQUEST'
export const SHOW_ACCOUNT_SUCCESS = 'SHOW_ACCOUNT_SUCCESS'
export const SHOW_ACCOUNT_FAILURE = 'SHOW_ACCOUNT_FAILURE'

export function fetchAccounts() {
  return dispatch => {
    const token = localStorage.getItem('access_token');
    let url = `${api.apiUrl}${api.version}${api.resource.create_account}`
      return fetch(url, {
      method: 'GET',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
              },
    })
     .then(response => response.json().then(bill=>({bill, response})))
     .then(({bill, response})=>{
      if (!response.ok) {
        if(response.status===401){
          window.localStorage.clear();
          window.location.reload();
        }
        bill.status = response.status
        return Promise.reject(bill)
          } else {
          dispatch(receiveShowBilling(bill.data.accounts))
     }})
     .catch(err =>{ 
       dispatch(showBillingError(err))
      })
      }
    }

function receiveShowBilling(data) {
  return {
    type: SHOW_ACCOUNT_SUCCESS,
    data
  }
}

function showBillingError(err) {
  return {
    type: SHOW_ACCOUNT_FAILURE,
    err
  }
}


